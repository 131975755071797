import FormErrorResponse from "../../types/FormErrorResponse";
import { i18next } from "../../utils/i18n";

interface propsType {
  errors: FormErrorResponse | null;
  fieldName: string;
}

const InputValidationError = ({ errors, fieldName }: propsType) => {
  const extractFieldErrorsFromErrorBag = (
    errorBag: FormErrorResponse | null,
    fieldName: string
  ): string[] => {
    if (!errorBag) return [];
    const errorTexts = errorBag?.[fieldName];
    return errorTexts || [];
  };

  return (
    <>
      {extractFieldErrorsFromErrorBag(errors, fieldName).length > 0 && (
        <div className="text-primaryRed pt-2 pl-2 text-sm">
          {extractFieldErrorsFromErrorBag(errors, fieldName).map((text) => (
            <p key={text} className="mb-0">
              {i18next.t(text)}
            </p>
          ))}
        </div>
      )}
    </>
  );
};
export default InputValidationError;
