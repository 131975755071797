import i18next from "i18next";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import InputValidationError from "../UI/InputValidationError";
import FormErrorResponse from "../../types/FormErrorResponse";
import useSave from "../../hooks/use-save";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import TargetGroup from "../../types/models/TargetGroup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import { toast } from "react-hot-toast";

interface Props {
  isTargetsListEdited: boolean;
  isCreate: boolean;
  onShowModalHandler: Dispatch<SetStateAction<boolean>>;
  targetGroupId: string;
}
const extractFieldErrorsFromErrorBag = (
  errorBag: FormErrorResponse | null,
  fieldName: string
): string[] => {
  if (!errorBag) return [];
  const errorTexts = errorBag?.[fieldName];
  return errorTexts || [];
};
const removeFieldErrorsFromErrorBag = (
  errorBag: FormErrorResponse | null,
  fieldName: string
): void => {
  delete errorBag?.[fieldName];
};

const TargetGroupDescription = ({
  isTargetsListEdited,
  isCreate,
  onShowModalHandler,
  targetGroupId,
}: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const navigate = useNavigate();

  const [targetGroupName, setTargetGroupName] = useState<string>("");
  const [errors, setErrors] = useState<FormErrorResponse | null>(null);

  const [savedData, fetchErrors, sendRequest] = useSave(
    targetGroupId ? "/target-groups/" + targetGroupId : "/target-groups/",
    { id: targetGroupId, name: targetGroupName },
    isCreate
  );

  const fetchTargetGroup = () => {
    if (isCreate || !targetGroupId || !currentTenant?.id) return;

    axios
      .get<TargetGroup>(
        "/tenants/" + currentTenant?.id + "/target-groups/" + targetGroupId
      )
      .then((response) => {
        setTargetGroupName(response.data.name);
      })
      .catch(() => {
        toast(i18next.t("Error loading page"));

        navigate("/target-groups");
      });
  };

  useEffect(() => {
    if (!isCreate) fetchTargetGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    isCreate ? setTargetGroupName("") : fetchTargetGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);
  useEffect(() => {
    if (savedData && !fetchErrors) setTargetGroupName(savedData.name);
    if (fetchErrors) setErrors(fetchErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData, fetchErrors]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        sendRequest();
      }}
    >
      {/* <dl>
        <dt>{i18next.t("ID")}</dt>
        <dd>
          {targetGroupId
            ? targetGroupId
            : "(" + i18next.t("Will be set after creation.") + ")"}
        </dd>
      </dl> */}
      <Form.Group className="mb-4 flex items-center gap-6" controlId="formName">
        <Form.Group className="w-1/2">
          <Form.Label className="mb-0 font-bold">
            {i18next.t("Name")}
          </Form.Label>
          <Form.Control
            type="text"
            value={targetGroupName}
            disabled={isTargetsListEdited ? true : false}
            className="flex bg-transparent border-t-0
              border-l-0 border-r-0 rounded-none border-primaryGrey
              dark:text-white
              focus:border-primaryDarkGrey focus:shadow-none focus-reset"
            onChange={(e) => {
              setTargetGroupName(e.target.value);
              removeFieldErrorsFromErrorBag(errors, "name");
            }}
            isInvalid={
              extractFieldErrorsFromErrorBag(errors, "name").length > 0
            }
          />
          <Form.Group>
            <InputValidationError
              fieldName="name"
              errors={errors}
            ></InputValidationError>
            <Form.Text className="text-muted">
              {i18next.t("The value has to be unique.")}
            </Form.Text>
          </Form.Group>
        </Form.Group>

        <ButtonGroup className="ml-auto">
          <Button
            type="submit"
            className="bg-primaryGreen border-primaryGreen px-5"
            disabled={isTargetsListEdited ? true : false}
          >
            <i
              className="fa fa-save text-white text-lg pr-2"
              aria-hidden="true"
            ></i>
            {i18next.t("Save")}
          </Button>
          {!isCreate && (
            <>
              <Button
                className="bg-primaryRed border-primaryRed px-5"
                disabled={isTargetsListEdited ? true : false}
                variant="danger"
                onClick={() => {
                  onShowModalHandler(true);
                }}
              >
                <i
                  className="fa fa-trash text-white text-lg pr-2"
                  aria-hidden="true"
                ></i>
                {i18next.t("Remove")}
              </Button>
            </>
          )}
        </ButtonGroup>
      </Form.Group>
    </Form>
  );
};
export default TargetGroupDescription;
