import i18next from "i18next";
import Button from "../components/UI/Button";
import Search from "../components/UI/Search";
import ListTabs from "../components/UI/ListDisplay/ListTabs";
import ListItemsPerPage from "../components/UI/ListDisplay/ListItemsPerPage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ListItem from "../components/UI/ListDisplay/ListItem";
import Pagination from "../components/UI/Pagination";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import axios from "axios";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import useClone from "../hooks/use-clone";
import Template from "../types/models/Template";
// import { store } from "..";
// import Tenant from "../types/models/Tenant";
import ListSort from "../components/UI/ListDisplay/ListSort";
import { loadStateFromLocalStorage } from "../utils/LocalStorageState";
import ListControll from "../components/UI/ListDisplay/ListControll";
import TemplateListItem from "../components/Templates/TemplateListItem";
import {
  paginationDefault,
  sortingDefault,
  tabsTemplates,
} from "../components/UI/ListDisplay/data";
import { getEnumKey } from "../utils/Enum";

function Templates() {
  const initialLocalStorageState = loadStateFromLocalStorage();
  const currentItemsPerPage = initialLocalStorageState?.templatesPerPage
    ? +initialLocalStorageState.templatesPerPage
    : 10;
  const currentSortValue = initialLocalStorageState?.templatesSorting
    ? initialLocalStorageState.templatesSorting
    : "updated-desc";

  const navigate = useNavigate();
  const [items, setItems] = useState<Template[]>([]);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [sortValue, setSortValue] = useState<string>(currentSortValue);
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(currentItemsPerPage);

  const [tabValues, setTabsValues] = useState<number[]>([0, 0, 0]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  // states for copy/delete
  const [currentTemplateID, setCurrentTemplateID] = useState<string>("");
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [showCopyConfirmationModal, setShowCopyConfirmationModal] =
    useState<boolean>(false);
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  // postpone loading until currentTenant is available
  // TODO: fix bug: refresh browser does not load list
  // store.subscribe(() => {
  //   const currentTenant = store.getState().currentTenant as Tenant | null;

  //   if (!hasLoaded && currentTenant?.id) {
  //     fetchItems();
  //   }
  // });
  const tabs = tabsTemplates.map((item) => ({
    ...item,
    value: tabValues[item.id],
    current: currentTab === item.id ? true : false,
  }));
  const numberOfPagesInPagination = paginationDefault.map((item) => ({
    ...item,
    current: itemsPerPage === item.value ? true : false,
  }));
  const sortElements = sortingDefault.map((item) => ({
    ...item,
    current: sortValue === item.value ? true : false,
  }));

  const resetData = () => {
    setItems([]);
    setCurrentPage(0);
    setLastPage(0);
    setTotalCount(0);
    setHasLoaded(false);
  };
  const fetchItems = (page: number = 1) => {
    if (!currentTenant?.id) return;

    resetData();

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/templates?page=" +
      page +
      "&items-per-page=" +
      itemsPerPage;
    if (searchValue) url += "&search=" + searchValue;
    if (sortValue) url += "&sort=" + sortValue;
    if (currentTab === 1)
      url += "&campaign-type=" + getEnumKey("CampaignType", "message");
    if (currentTab === 2)
      url += "&campaign-type=" + getEnumKey("CampaignType", "file");
    axios
      .get<{
        current_page: number;
        data: Template[];
        last_page: number;
        per_page: number;
        total: number;
        count_all: number;
        count_campaign_type_message: number;
        count_campaign_type_file: number;
      }>(url)
      .then((response) => {
        setItems(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
        setTabsValues([
          response.data.count_all,
          response.data.count_campaign_type_message,
          response.data.count_campaign_type_file,
        ]);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));
      })
      .finally(() => {
        setHasLoaded(true);
      });
  };

  const changeItemsPerPageHandler = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
  };

  const changeTabHandler = (tab: number) => {
    setCurrentTab(tab);
  };

  const changeSortHandler = (value: string) => {
    setSortValue(value);
  };
  const cloneTemplate = useClone(
    currentTenant,
    "/templates/",
    currentTemplateID
  );

  const remove = () => {
    if (!currentTemplateID || !currentTenant?.id) return;

    axios
      .delete(
        "/tenants/" + currentTenant?.id + "/templates/" + currentTemplateID
      )
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));
        fetchItems();
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };
  useEffect(() => {
    if (!currentTenant?.id) return;
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, searchValue, currentTab, sortValue]);
  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          remove();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      <ConfirmationModal
        show={showCopyConfirmationModal}
        closeCallback={() => {
          setShowCopyConfirmationModal(false);
        }}
        confirmCallback={() => {
          cloneTemplate().then(() => {
            fetchItems();
          });
        }}
        title={i18next.t("Copy record")}
        confirmButtonText={i18next.t("Copy")}
        confirmButtonVariant="danger"
      />
      <div className="flex flex-col w-full pt-5">
        <div className="flex gap-6">
          <Button
            onClick={() => {
              navigate("/templates/create");
            }}
          >
            <i className="fa fa-plus pr-2" aria-hidden="true"></i>{" "}
            {i18next.t("Add new template")}
          </Button>

          <Search
            placeholder={i18next.t("Search template...")}
            searchHandler={setSearchValue}
          ></Search>
        </div>
        <ListControll isSearching={searchValue?.length ? true : false}>
          <ListTabs tabs={tabs} changeTabHandler={changeTabHandler}></ListTabs>
        </ListControll>
        <div
          className={`${
            searchValue?.length ? "blur-[2px]" : ""
          } flex justify-between items-end -mt-2 mb-6
            text-sm overflow-x-scroll lg:overflow-x-visible`}
        >
          <ListSort
            sortElements={sortElements}
            changeSortHandler={changeSortHandler}
            sortingName="templatesSorting"
          ></ListSort>
          <ListItemsPerPage
            tabs={numberOfPagesInPagination}
            paginationName="templatesPerPage"
            activeTab={itemsPerPage}
            changeItemsPerPageHandler={changeItemsPerPageHandler}
          ></ListItemsPerPage>
        </div>
        {items.length > 0 ? (
          <>
            {items.map((item) => (
              <ListItem
                key={item.id}
                className="p-6 d-flex justify-between align-items-start overflow-hidden"
                clickHandler={() => {
                  navigate("/templates/" + item.id);
                }}
                copyHandler={() => {
                  setShowCopyConfirmationModal(true);
                  setCurrentTemplateID(item.id ? item.id : "");
                }}
                deleteHandler={() => {
                  setShowDeletionConfirmationModal(true);
                  setCurrentTemplateID(item.id ? item.id : "");
                }}
              >
                <TemplateListItem template={item}></TemplateListItem>
              </ListItem>
            ))}
          </>
        ) : (
          <p>
            {!hasLoaded ? (
              <span className="loading"></span>
            ) : (
              i18next.t("No entries found.")
            )}
          </p>
        )}
        {totalCount ? (
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            totalCount={totalCount}
            nextCallback={() => {
              fetchItems(currentPage + 1);
            }}
            backCallback={() => {
              fetchItems(currentPage - 1);
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default Templates;
