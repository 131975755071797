import i18next from "i18next";

interface Props {
  openedEmails: number;
  sentEmails: number;
}

const OpenedEmails = ({ openedEmails, sentEmails }: Props) => {
  return (
    <div className="flex-1 border-solid border p-6 border-primaryGreen rounded">
      {i18next.t("Open")}
      <div className="text-3xl font-bold">{i18next.t("E-Mail")}</div>
      <div className="ml-auto grow-0 text-primaryGrey">
        <span className="text-black dark:text-white text-lg">
          {openedEmails}
        </span>
        /{sentEmails}
      </div>
      <div className="grow-0 text-5xl mt-10 text-primaryGreen">
        {sentEmails ? (openedEmails * 100) / sentEmails : 0}%
      </div>
      <div className="basis-full mt-6 h-2 bg-primaryLightGrey rounded">
        {sentEmails ? (
          <div
            className="h-2 bg-primaryGreen rounded"
            style={{
              width: (openedEmails * 100) / sentEmails + "%",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default OpenedEmails;
