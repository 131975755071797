import { useState } from "react";
import { i18next } from "../../../utils/i18n";
import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from "../../../utils/LocalStorageState";
import LocalStorageState from "../../../types/LocalStorageState";

interface propsType {
  sortElements: {
    id: number;
    name: string;
    value: string;
    current: boolean;
  }[];
  changeSortHandler: (value: string) => void;
  sortingName: string; // The sorting for each list can be read from local storage
}
const ListSort = ({
  sortElements,
  changeSortHandler,
  sortingName,
}: propsType) => {
  // const sortNameFromProps = sortElements.find(function (o) {
  //   return o.current === true;
  // })?.name;
  const sortValueFromProps = sortElements.find(function (o) {
    return o.current === true;
  })?.value;

  const initialLocalStorageState = loadStateFromLocalStorage();
  const sortFromLocalStorage = initialLocalStorageState
    ? initialLocalStorageState[sortingName as keyof LocalStorageState]
    : null;
  const sortValue = sortFromLocalStorage
    ? sortFromLocalStorage
    : sortValueFromProps;
  const sortName = sortElements.find(function (o) {
    return o.value === sortValue;
  })?.name;

  const [currentSortValue, setCurrentSortValue] = useState(sortName);
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const toggleDropdownHandler = () => {
    setShowSortDropdown((prev) => !prev);
  };

  return (
    <div className="ml-auto relative mr-8 text-primaryGrey">
      <span className="mr-6">{i18next.t("Sort by")}</span>
      <span>
        <span
          onClick={toggleDropdownHandler}
          className="text-primaryDarkGrey cursor-pointer"
        >
          {i18next.t(currentSortValue ? currentSortValue : "")}{" "}
          <i
            className={`fa ${
              showSortDropdown ? "fa-caret-up" : "fa-caret-down"
            }`}
            aria-hidden="true"
          ></i>
        </span>
        <ul
          className={`${
            showSortDropdown ? "block" : "hidden"
          } absolute bg-white dark:bg-darkModeBlue list-none z-10 px-6 py-2 mt-2 right-0 shadow min-w-max`}
        >
          {sortElements.map((element) => {
            return (
              <li
                key={element.id}
                onClick={() => {
                  setCurrentSortValue(element.name);
                  changeSortHandler(element.value);
                  toggleDropdownHandler();
                  saveStateToLocalStorage({
                    ...initialLocalStorageState,
                    [sortingName]: element.value.toString(),
                  });
                }}
                className="cursor-pointer hover:text-primaryDarkGrey py-2"
              >
                {i18next.t(element.name)}
              </li>
            );
          })}
        </ul>
      </span>
    </div>
  );
};
export default ListSort;
