import { ReactNode } from "react";

interface propsType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = ({
  children,
  className,
  disabled,
  onClick,
  ...restProps
}: propsType) => {
  const customBg = className?.includes("bg-");
  const customColor =
    className?.includes("text-black") ||
    className?.includes("text-white") ||
    className?.includes("text-primary") ||
    className?.includes("text-dark") ||
    className?.includes("text-hover") ||
    className?.includes("text-light") ||
    className?.includes("text-secondary");
  const customPaddingX = className?.includes("px-");
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      {...restProps}
      className={`h-fit w-fit
        rounded-[.2rem]  py-2 ease-out duration-300
        shadow-sm border-0
        ${className} ${customBg ? "" : " bg-primaryBlue dark:bg-darkModeBlue"}
        ${customColor ? "" : " text-white"}
        ${!disabled ? " hover:scale-105 cursor-pointer" : ""}
        ${customPaddingX ? "" : " px-6"}`}
    >
      {children}
    </button>
  );
};

export default Button;
