import { useEffect, useRef, useState } from "react";
import Button from "../../UI/Button";
import Modal from "react-bootstrap/Modal";
import Search from "../../UI/Search";
import MediaItem from "./MediaItem";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";
import axios from "axios";
import { default as MediaItemType } from "../../../types/models/MediaItem";
import Pagination from "../../UI/Pagination";
import { toast } from "react-hot-toast";
import i18next from "i18next";
import ListSort from "../../UI/ListDisplay/ListSort";
import { sortingMediaItems } from "../../UI/ListDisplay/data";
import { loadStateFromLocalStorage } from "../../../utils/LocalStorageState";

interface Props {
  onItemSelectHandler: (arg0: string, arg1?: string) => void;
  isAttachments?: boolean;
}

const chunkSize = 1048576; // max upload size = 1MiB
const imageAvailableExtensions = ".jpg,.jpeg,.png,.gif,.svg";
const attachmentAvailableExtensions = ".pdf,.docx,.xlsx";
const imageExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
const attachmentExtensions = ["pdf", "doc", "docx", "xls", "xlsx"];

const MediaLibrary = ({
  onItemSelectHandler,
  isAttachments = false,
}: Props) => {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<any>();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [gallery, setGallery] = useState<MediaItemType[] | []>([]);

  const [hasItemRemoved, setHasItemRemoved] = useState(false);

  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [searchValue, setSearchValue] = useState<string | undefined>();

  const initialLocalStorageState = loadStateFromLocalStorage();
  const currentSortValue = initialLocalStorageState?.mediaItemsSorting
    ? initialLocalStorageState.mediaItemsSorting
    : "updated-desc";
  const [sortValue, setSortValue] = useState<string>(currentSortValue);
  const sortElements = sortingMediaItems.map((item) => ({
    ...item,
    current: sortValue === item.value ? true : false,
  }));

  // tus upload
  const [hasLoaded, setHasLoaded] = useState(false);
  const [uploadOffset, setUploadOffset] = useState<number | null>(null);
  const [uploadingFileId, setUploadingFileId] = useState<string | undefined>();
  useEffect(() => {
    if (!uploadingFileId || !file?.size || !file || uploadOffset === null)
      return;

    if (uploadOffset <= file.size) {
      tusPatch(uploadingFileId, file);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadOffset]);

  const changeSortHandler = (value: string) => {
    setSortValue(value);
  };
  useEffect(() => {
    fetchMediaItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, searchValue]);
  useEffect(() => {
    if (hasItemRemoved) {
      fetchMediaItems();
      setHasItemRemoved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasItemRemoved]);

  const showMediaLibrary = (e: React.MouseEvent) => {
    e.preventDefault();
    setShow(true);
  };
  const toggleAddingNewItem = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsAddingNewItem((prev) => !prev);
    setHasLoaded(false);
  };
  const loadingHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    fileDirectUpload();
  };
  const tusLoadingHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    fileTusUpload();
  };

  const fetchMediaItems = (page: number = 1) => {
    if (!currentTenant?.id) return;

    let url = `/tenants/${currentTenant.id}/media-items/?page=${page}&sort=${sortValue}`;
    if (searchValue) url += `&search=${searchValue}`;
    // url for images
    if (!isAttachments)
      imageExtensions.map((extension, index) => {
        url += `&file-extensions[${index}]=${extension}`;
      });
    // url for files
    if (isAttachments)
      attachmentExtensions.map((extension, index) => {
        url += `&file-extensions[${index}]=${extension}`;
      });

    axios
      .get(url)
      .then((response) => {
        setGallery(response.data.data);
        setTotalCount(response.data.total);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchMediaItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const fileDirectUpload = () => {
    if (!currentTenant?.id || !file) return;

    const url = "/tenants/" + currentTenant.id + "/media-items";

    var formData = new FormData();
    formData.append("file", file);

    const axiosHandler = axios.post<FormData>(url, formData);

    axiosHandler
      .then((response) => {
        fetchMediaItems();
        if (fileInputRef.current) fileInputRef.current.value = "";
        setFile(undefined);
        setIsAddingNewItem(false);
      })
      .catch((error) => {
        toast(i18next.t("Error saving record."));
      });
  };
  const tusHead = (id: any) => {
    if (!currentTenant?.id || !file) return;
    const url = "/tenants/" + currentTenant.id + "/media-items/tus/" + id;

    const axiosHandler = axios.head<FormData>(url, {
      headers: {
        "Tus-Resumable": "1.0.0",
      },
    });

    axiosHandler
      .then((response) => {})
      .catch((error) => {
        toast(i18next.t("Error saving record."));
      });
  };
  const tusPatch = (id: any, file: any) => {
    if (!currentTenant?.id || !file || uploadOffset === null) return;
    // if (uploadOffset === file.size) return;

    const url = "/tenants/" + currentTenant.id + "/media-items/tus/" + id;
    const chunk = file.slice(uploadOffset, uploadOffset + chunkSize);

    const axiosHandler = axios.patch(url, chunk, {
      headers: {
        "Content-Type": "application/offset+octet-stream",
        "Tus-Resumable": "1.0.0",
        "Upload-Offset": uploadOffset,
      },
    });

    axiosHandler
      .then((response) => {
        // setUploadOffset((prev) => {
        //   if (prev === null) return prev;
        //   if (prev + chunkSize > file.size) return null;
        //   return prev + chunkSize;
        // });

        if (!response.headers) return;
        setUploadOffset(
          response.headers["upload-offset"]
            ? +response.headers["upload-offset"]
            : null
        );

        // reset after last patch
        if (uploadOffset + chunkSize > file.size) {
          setUploadOffset(null);
          setHasLoaded(false);
          fetchMediaItems();
          if (fileInputRef.current) fileInputRef.current.value = "";
          setFile(undefined);
          setIsAddingNewItem(false);
        }
      })
      .catch((error) => {
        toast(i18next.t("Error saving record."));
      });
  };

  const fileTusUpload = () => {
    if (!currentTenant?.id || !file) return;
    setHasLoaded(true);

    const url = "/tenants/" + currentTenant.id + "/media-items";
    const data = {
      original_filename: file.name,
      file_size: file.size.toString(),
    };

    const axiosHandler = axios.post(url, data, {
      headers: {
        "Tus-Resumable": "1.0.0",
        "Unload-length": file.size.toString(),
        "Upload-Metadata": `original_filename ${btoa(
          file.name.toString()
        )},file_size ${btoa(file.size.toString())}`,
      },
    });

    axiosHandler
      .then((response: any) => {
        setUploadingFileId(response.data.id);
        setUploadOffset(0);
      })
      .catch((error) => {
        toast(i18next.t("Error saving record."));
        setFile(undefined);
        setIsAddingNewItem(false);
      });
  };

  return (
    <>
      <Button onClick={showMediaLibrary} className="min-w-max">
        <i className="fa fa-plus mr-2"></i>
        {!isAttachments
          ? i18next.t("Media Library")
          : i18next.t("Add new attachment")}
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="w-11/12 max-w-none rounded-md media-library"
      >
        <Modal.Header closeButton className="bg-primaryBlue text-white">
          <Modal.Title>
            {i18next.t("Media Library")} (
            {!isAttachments ? i18next.t("Images") : i18next.t("Attachments")})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-full bg-primaryLightGrey rounded-bl-md rounded-br-md">
          <div className="flex justify-between">
            <div>
              {!isAddingNewItem ? (
                <Button
                  onClick={toggleAddingNewItem}
                  className="flex gap-3 items-center"
                >
                  <span className="fa fa-plus"></span>
                  {i18next.t("Add new media item")}
                </Button>
              ) : (
                <div className="flex gap-4 items-center">
                  <input
                    type="file"
                    id="file"
                    ref={fileInputRef}
                    accept={
                      isAttachments
                        ? attachmentAvailableExtensions
                        : imageAvailableExtensions
                    }
                    onChange={(e) => {
                      if (!e.target.files?.length) return;
                      setFile(e.target.files[0]);
                    }}
                  />
                  {/* Direct Upload */}
                  {/* {file ? (
                    <Button
                      onClick={loadingHandler}
                      className="flex gap-3 items-center"
                    >
                      <span className="fa fa-upload"></span>
                      {i18next.t("Upload")}
                    </Button>
                  ) : null} */}
                  {file ? (
                    <Button
                      onClick={tusLoadingHandler}
                      className="flex gap-3 items-center"
                    >
                      <span className="fa fa-upload"></span>
                      {hasLoaded ? (
                        <span>
                          {uploadOffset
                            ? Math.round((uploadOffset * 100) / file.size)
                            : 0}
                          %
                        </span>
                      ) : (
                        i18next.t("Upload")
                      )}
                    </Button>
                  ) : null}

                  <Button
                    onClick={toggleAddingNewItem}
                    className="flex gap-3 items-center"
                  >
                    <span className="fa fa-times"></span>
                    {i18next.t("Cancel")}
                  </Button>
                </div>
              )}
            </div>
            <Search searchHandler={setSearchValue}></Search>
          </div>
          <div className="flex items-end mt-5">
            <ListSort
              sortElements={sortElements}
              changeSortHandler={changeSortHandler}
              sortingName="mediaItemsSorting"
            ></ListSort>
          </div>
          {gallery.length ? (
            <div
              className={`flex flex-wrap max-w-full ${
                isAttachments ? "gap-3" : "gap-6"
              } justify-start my-8 mx-4`}
            >
              {gallery.map((item: MediaItemType) => (
                <MediaItem
                  key={item.id}
                  item={item}
                  fileNameLiftingUp={isAttachments}
                  onSelect={onItemSelectHandler}
                  onRemove={setHasItemRemoved}
                  isAttachments={isAttachments}
                ></MediaItem>
              ))}
            </div>
          ) : (
            <div className="items-center text-center w-full my-28">
              {i18next.t("No records found")}
            </div>
          )}

          <div className="text-right">
            {totalCount > 20 ? (
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                totalCount={totalCount}
                nextCallback={() => {
                  fetchMediaItems(currentPage + 1);
                }}
                backCallback={() => {
                  fetchMediaItems(currentPage - 1);
                }}
              />
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MediaLibrary;
