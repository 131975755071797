import i18next from "i18next";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { default as ServerType } from "../types/models/Server";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import FormErrorResponse from "../types/FormErrorResponse";
import useSave from "../hooks/use-save";
import InputValidationError from "../components/UI/InputValidationError";

function Server({ isCreate }: { isCreate: boolean }) {
  const { id } = useParams();
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [server, setServer] = useState<ServerType>();
  const [errors, setErrors] = useState<FormErrorResponse | null>(null);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const extractFieldErrorsFromErrorBag = (
    errorBag: FormErrorResponse | null,
    fieldName: string
  ): string[] => {
    if (!errorBag) return [];

    const errorTexts = errorBag?.[fieldName];

    return errorTexts || [];
  };
  const removeFieldErrorsFromErrorBag = (
    errorBag: FormErrorResponse | null,
    fieldName: string
  ): void => {
    delete errorBag?.[fieldName];
  };

  const fetchItem = () => {
    if (isCreate || !id || !currentTenant?.id) return;
    setHasLoaded(false);
    axios
      .get<ServerType>("/tenants/" + currentTenant?.id + "/servers/" + id)
      .then((response) => {
        setServer(response.data);
        setHasLoaded(true);
      })
      .catch(() => {
        toast(i18next.t("Error loading page"));

        navigate("/servers");
      });
  };

  const [savedData, error, sendRequest] = useSave(
    id ? "/servers/" + id : "/servers/",
    server,
    isCreate
  );
  useEffect(() => {
    if (savedData) setServer(savedData);
    if (error) setErrors(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData, error]);

  const remove = () => {
    if (!id || !currentTenant?.id) return;

    axios
      .delete("/tenants/" + currentTenant?.id + "/servers/" + id)
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));

        navigate("/servers");
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  // useEffect(() => {
  //   fetchItem();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    isCreate ? setServer(undefined) : fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setServer((prev) => ({ ...prev, [field]: value }));
    removeFieldErrorsFromErrorBag(errors, field);
  };

  const onChangeSelectHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const field = event.target.id;
    const value = event.target.value;

    setServer((prev) => ({ ...prev, [field]: value }));
  };

  const dateToString = (date: string) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const tmpMonth = dateObj.getMonth() + 1;
    const month = tmpMonth > 9 ? tmpMonth : "0" + tmpMonth;
    const day =
      dateObj.getDate() > 9 ? dateObj.getDate() : "0" + dateObj.getDate();
    const hour =
      dateObj.getHours() > 9 ? dateObj.getHours() : "0" + dateObj.getHours();
    const minute =
      dateObj.getMinutes() > 9
        ? dateObj.getMinutes()
        : "0" + dateObj.getMinutes();
    let string = day + "." + month + "." + year + " " + hour + ":" + minute;
    return string;
  };
  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          remove();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      {hasLoaded || isCreate ? (
        <>
          <h3 className="mb-5 dark:text-white">Server</h3>
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              sendRequest();
            }}
          >
            <ButtonGroup className="ml-auto flex justify-end mb-6 max-w-min">
              <Button
                type="submit"
                className="bg-primaryGreen border-primaryGreen px-5 min-w-max"
              >
                <i
                  className="fa fa-save text-white text-lg pr-2"
                  aria-hidden="true"
                ></i>
                {i18next.t("Save")}
              </Button>
              {!isCreate && (
                <>
                  <Button
                    className="bg-primaryRed border-primaryRed px-5 min-w-max"
                    variant="danger"
                    onClick={() => {
                      setShowDeletionConfirmationModal(true);
                    }}
                  >
                    <i
                      className="fa fa-trash-o text-white text-lg pr-2"
                      aria-hidden="true"
                    ></i>
                    {i18next.t("Remove")}
                  </Button>
                </>
              )}
            </ButtonGroup>
            <Form.Group className="mb-4 flex flex-wrap gap-x-6 bg-white dark:bg-darkModeBlue dark:text-white p-6 rounded shadow-md">
              <Form.Group className="mb-4 flex-1 basis-1/4">
                <Form.Label className="text-sm font-bold" htmlFor="name">
                  {i18next.t("Name")}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="focus-reset border-solid border-0 border-b border-primaryGrey
                    dark:bg-darkModeBlue dark:text-white
                    rounded-none focus:border-primaryDarkGrey"
                  id="name"
                  value={server?.name ? server.name : ""}
                  onChange={onChangeHandler}
                  isInvalid={
                    extractFieldErrorsFromErrorBag(errors, "name").length > 0
                  }
                />
                <InputValidationError
                  errors={errors}
                  fieldName="name"
                ></InputValidationError>
                <Form.Text className="text-muted">
                  {i18next.t("The value has to be unique.")}
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-4 flex-1 basis-1/4">
                <Form.Label className="text-sm font-bold" htmlFor="username">
                  Username
                </Form.Label>
                <Form.Control
                  type="text"
                  className="focus-reset border-solid border-0 border-b border-primaryGrey
                    dark:bg-darkModeBlue dark:text-white
                    rounded-none focus:border-primaryDarkGrey"
                  id="username"
                  value={server?.username ? server.username : ""}
                  onChange={onChangeHandler}
                  isInvalid={
                    extractFieldErrorsFromErrorBag(errors, "username").length >
                    0
                  }
                />
                <InputValidationError
                  errors={errors}
                  fieldName="username"
                ></InputValidationError>
              </Form.Group>
              <Form.Group className="mb-4 flex-1 basis-1/4">
                <Form.Label className="text-sm font-bold" htmlFor="password">
                  Password
                </Form.Label>
                <Form.Control
                  type="password"
                  className="focus-reset border-solid border-0 border-b border-primaryGrey
                    dark:bg-darkModeBlue dark:text-white
                    rounded-none focus:border-primaryDarkGrey"
                  id="password"
                  value={server?.password ? server.password : ""}
                  onChange={onChangeHandler}
                  isInvalid={
                    extractFieldErrorsFromErrorBag(errors, "password").length >
                    0
                  }
                />
                <InputValidationError
                  errors={errors}
                  fieldName="password"
                ></InputValidationError>
              </Form.Group>
              <Form.Group className="mb-4 flex-1 basis-1/4">
                <Form.Label className="text-sm font-bold" htmlFor="type">
                  {i18next.t("Type")}
                </Form.Label>
                <select
                  id="type"
                  className="custom-select server form-control focus-reset searchfield-appearance
                    border-solid border-0 border-b border-primaryGrey
                    dark:bg-darkModeBlue dark:text-white
                    rounded-none focus:border-primaryDarkGrey"
                  value={server?.type ? server.type : ""}
                  onChange={onChangeSelectHandler}
                >
                  <option value="0">smtp</option>
                </select>
                <InputValidationError
                  errors={errors}
                  fieldName="type"
                ></InputValidationError>
              </Form.Group>
              <Form.Group className="mb-4 flex-1 basis-1/4">
                <Form.Label className="text-sm font-bold" htmlFor="host">
                  Host
                </Form.Label>
                <Form.Control
                  type="text"
                  className="focus-reset border-solid border-0 border-b border-primaryGrey
                    dark:bg-darkModeBlue dark:text-white
                    rounded-none focus:border-primaryDarkGrey"
                  id="host"
                  value={server?.host ? server.host : ""}
                  onChange={onChangeHandler}
                  isInvalid={
                    extractFieldErrorsFromErrorBag(errors, "host").length > 0
                  }
                />
                <InputValidationError
                  errors={errors}
                  fieldName="host"
                ></InputValidationError>
              </Form.Group>
              <Form.Group className="mb-4 flex-1 basis-1/4">
                <Form.Label className="text-sm font-bold" htmlFor="port">
                  Port
                </Form.Label>
                <Form.Control
                  type="number"
                  className="focus-reset border-solid border-0 border-b border-primaryGrey
                    dark:bg-darkModeBlue dark:text-white
                    rounded-none focus:border-primaryDarkGrey"
                  id="port"
                  value={server?.port ? server.port : ""}
                  onChange={onChangeHandler}
                  isInvalid={
                    extractFieldErrorsFromErrorBag(errors, "port").length > 0
                  }
                />
                <InputValidationError
                  errors={errors}
                  fieldName="port"
                ></InputValidationError>
              </Form.Group>
              <Form.Group className="mb-4 flex-1 basis-1/4">
                <Form.Label
                  className="text-sm font-bold"
                  htmlFor="encryption_type"
                >
                  {i18next.t("Encryption Type")}
                </Form.Label>
                <select
                  id="encryption_type"
                  className="custom-select server form-control
                    focus-reset border-solid border-0 border-b border-primaryGrey
                    dark:bg-darkModeBlue dark:text-white
                    rounded-none focus:border-primaryDarkGrey"
                  value={server?.encryption_type ? server.encryption_type : ""}
                  onChange={onChangeSelectHandler}
                >
                  <option value="0">none</option>
                  <option value="1">tls</option>
                </select>
                <InputValidationError
                  errors={errors}
                  fieldName="encryption_type"
                ></InputValidationError>
              </Form.Group>
              <Form.Group className="mb-4 flex-1 basis-1/4">
                <Form.Label
                  className="text-sm font-bold"
                  htmlFor="send_timeout"
                >
                  Timeout
                </Form.Label>
                <Form.Control
                  type="number"
                  className="focus-reset border-solid border-0 border-b border-primaryGrey
                    dark:bg-darkModeBlue dark:text-white
                    rounded-none focus:border-primaryDarkGrey"
                  id="send_timeout"
                  value={server?.send_timeout ? server.send_timeout : ""}
                  onChange={onChangeHandler}
                  isInvalid={
                    extractFieldErrorsFromErrorBag(errors, "send_timeout")
                      .length > 0
                  }
                />
                <InputValidationError
                  errors={errors}
                  fieldName="send_timeout"
                ></InputValidationError>
              </Form.Group>
              <Form.Group className="mb-4 flex-1 basis-1/4 items-center">
                <input
                  type="checkbox"
                  className=" mr-2 bg-transparent border-t-0
                    border-l-0 border-r-0 rounded-none border-primaryDarkGrey
                    focus:border-black focus:shadow-none focus-reset"
                  checked={server?.publish_to_sub_tenants ? true : false}
                  id="publish_to_sub_tenants"
                  onChange={onChangeHandler}
                />
                <Form.Label
                  htmlFor="publish_to_sub_tenants"
                  className=" mb-0 text-sm font-bold"
                >
                  {i18next.t("Publish to sub tenants")}
                </Form.Label>
              </Form.Group>
            </Form.Group>
            {!isCreate && server && (
              <Form.Group className="mb-4 flex gap-6 dark:text-white">
                <div className="text-sm font-bold">
                  <span className="text-primaryDarkGrey">
                    {i18next.t("Created at")}:
                  </span>{" "}
                  {server.created_at && dateToString(server.created_at)}
                </div>
                <div className="text-sm font-bold">
                  <span className="text-primaryDarkGrey">
                    {i18next.t("Updated at")}:
                  </span>{" "}
                  {server.updated_at && dateToString(server.updated_at)}{" "}
                </div>
              </Form.Group>
            )}
          </Form>
        </>
      ) : (
        <div className="loading"></div>
      )}
    </>
  );
}

export default Server;
