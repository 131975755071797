import i18next from "i18next";
import Wrapper from "../components/UI/Wrapper";
import { ChangeEvent, FormEvent, useState } from "react";
import Button from "../components/UI/Button";
import axios from "axios";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../actions";
import { toast } from "react-hot-toast";

const getFullValue = (value: number) => {
  let fullValue;
  if (value < 10) {
    fullValue = `0${value}`;
  } else {
    fullValue = value;
  }
  return fullValue;
};
const getDateForInput = (date: string) => {
  const dateTime = new Date(date.slice(0, -1)); // remove Z to prevent time zone conversion
  const year = dateTime.getFullYear();
  const month = getFullValue(dateTime.getMonth() + 1);
  const day = getFullValue(dateTime.getDate());
  // const hours = getFullValue(dateTime.getHours());
  // const minutes = getFullValue(dateTime.getMinutes());

  // return `${hours}:${minutes} ${day}.${month}.${year}`;
  return `${day}.${month}.${year}`;
};

const ProfileRow = ({
  title,
  content,
  isEditable = false,
  name,
  onChange,
}: {
  title: string;
  content: string;
  isEditable?: boolean;
  name?: string;
  onChange?: any;
}) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(name, e.target.value);
  };
  return (
    <div className="mt-4 flex">
      <span className="basis-1/4 text-primaryBlue dark:text-primaryGrey">
        {i18next.t(title)}:
      </span>
      {isEditable ? (
        <input
          value={content}
          onChange={onInputChange}
          name={name}
          className="focus:outline-none border-bottom focus:border-primaryDarkGrey"
        />
      ) : (
        <span>{content}</span>
      )}
    </div>
  );
};

const SectionHeader = ({ title }: { title: string }) => {
  return (
    <h4 className="text-primaryBlue dark:text-primaryGrey">
      {i18next.t(title)}
    </h4>
  );
};

const Profile = () => {
  const currentUser = useSelector((state: ReducerState) => state.currentUser);
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({
    firstName: currentUser ? currentUser.first_name : "",
    lastName: currentUser ? currentUser.last_name : "",
  });

  if (!currentUser) return null;

  const onInputChange = (field: string, value: string) => {
    setUserInfo((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let data = {};
    if (userInfo.firstName !== currentUser.first_name)
      data = { ...data, first_name: userInfo.firstName };
    if (userInfo.lastName !== currentUser.last_name)
      data = { ...data, last_name: userInfo.lastName };

    const url = "/user/";
    axios
      .patch(url, data)
      .then((response) => {
        dispatch(setCurrentUser(response.data));
      })
      .catch(() => {
        toast(i18next.t("Error saving record."));
      });
  };

  return (
    <>
      <h2 className="mb-6">{i18next.t("Profile")}</h2>
      <Wrapper>
        <form onSubmit={onFormSubmit}>
          <div className="border-0 border-b border-solid border-b-primaryLightBlue pb-6 mb-6">
            <SectionHeader title="Personal Data" />
            <ProfileRow
              title="First Name"
              content={userInfo.firstName}
              isEditable={true}
              onChange={onInputChange}
              name="firstName"
            />
            <ProfileRow
              title="Last Name"
              content={userInfo.lastName}
              isEditable={true}
              onChange={onInputChange}
              name="lastName"
            />
            <ProfileRow title="E-mail address" content={currentUser.email} />
            {currentUser.first_name !== userInfo.firstName ||
            currentUser.last_name !== userInfo.lastName ? (
              <Button type="submit" className="mt-4">
                Speichern
              </Button>
            ) : null}
          </div>
          <div>
            <SectionHeader title="Additional Information" />
            <ProfileRow title="ID" content={currentUser.id} />
            <ProfileRow
              title="Last update on"
              content={getDateForInput(currentUser.updated_at)}
            />
            <ProfileRow
              title="Created at"
              content={getDateForInput(currentUser.created_at)}
            />
          </div>
        </form>
      </Wrapper>
    </>
  );
};
export default Profile;
