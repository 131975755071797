import i18next from "i18next";
import { getEnumKey } from "../../../utils/Enum";
import InputValidationError from "../../UI/InputValidationError";
import { ChangeEventHandler, useEffect, useState } from "react";
import FormErrorResponse from "../../../types/FormErrorResponse";
import Campaign from "../../../types/models/Campaign";

interface PropsType {
  isCreate: boolean;
  currentCampaign: Campaign | undefined;
  errors: FormErrorResponse | null;
  onChangeRadioHandler: ChangeEventHandler<HTMLInputElement>;
  onInputChangeHandler(field: string, value: string | boolean): void;
  onChangeSelectHandler: ChangeEventHandler<HTMLSelectElement>;
}
const removeFieldErrorsFromErrorBag = (
  errorBag: FormErrorResponse | null,
  fieldName: string
): void => {
  delete errorBag?.[fieldName];
};
const getFullValue = (value: number) => {
  let fullValue;
  if (value < 10) {
    fullValue = `0${value}`;
  } else {
    fullValue = value;
  }
  return fullValue;
};
const getDateForInput = (date: string) => {
  const dateTime = new Date(date.slice(0, -1)); // remove Z to prevent time zone conversion
  const year = dateTime.getFullYear();
  const month = getFullValue(dateTime.getMonth() + 1);
  const day = getFullValue(dateTime.getDate());
  const hours = getFullValue(dateTime.getHours());
  const minutes = getFullValue(dateTime.getMinutes());

  return [`${year}-${month}-${day}`, `${hours}:${minutes}`];
};
const getDefaultDate = () => {
  var defaultDate: Date | string = new Date();
  var dd: string | number = defaultDate.getDate() + 1; // tomorrow
  var mm: string | number = defaultDate.getMonth() + 1; //January is 0!
  var yyyy = defaultDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  defaultDate = yyyy + "-" + mm + "-" + dd;
  return defaultDate;
};

const SendSchedule = ({
  isCreate,
  currentCampaign,
  errors,
  onInputChangeHandler,
  onChangeRadioHandler,
  onChangeSelectHandler,
}: PropsType) => {
  const [scheduleDate, setScheduleDate] = useState<string>(getDefaultDate());
  const [scheduleTime, setScheduleTime] = useState<string>("00:00");

  useEffect(() => {
    if (currentCampaign?.schedule_datetime) {
      const [date, time] = getDateForInput(currentCampaign.schedule_datetime);
      setScheduleDate(date);
      setScheduleTime(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onInputChangeHandler(
      "schedule_datetime",
      scheduleDate + " " + scheduleTime
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDate, scheduleTime]);

  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleDate(event.target.value);
    removeFieldErrorsFromErrorBag(errors, "schedule_datetime");
  };
  const onChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleTime(event.target.value);
    removeFieldErrorsFromErrorBag(errors, "schedule_datetime");
  };
  return (
    <div className="flex gap-x-6 px-4">
      <div>
        <input
          type="radio"
          checked={
            isCreate && currentCampaign?.schedule_send_immediately === undefined
              ? true
              : currentCampaign?.schedule_send_immediately
              ? true
              : false
          }
          name="schedule_send"
          id="schedule_send_immediately"
          onChange={onChangeRadioHandler}
          className={`hidden schedule-send`}
        />
        <label
          htmlFor="schedule_send_immediately"
          className="flex items-center"
        >
          <span className="radio h-4 w-4 mr-2 inline-block border rounded-full"></span>
          {i18next.t("Send immediately")}
        </label>
      </div>
      <div>
        <input
          type="radio"
          checked={
            currentCampaign?.schedule_send_immediately !== undefined
              ? !currentCampaign.schedule_send_immediately
              : false
          }
          name="schedule_send"
          id="schedule_send_not_immediately"
          onChange={onChangeRadioHandler}
          className={`hidden schedule-send`}
        />
        <label
          htmlFor="schedule_send_not_immediately"
          className="flex items-center"
        >
          <span className="radio h-4 w-4 mr-2 inline-block border rounded-full"></span>
          {i18next.t("Send at a scheduled time")}
        </label>
        <div
          className={`${
            currentCampaign?.schedule_send_immediately === false
              ? "block"
              : "hidden"
          }`}
        >
          <div className="my-4">
            <div>
              <label htmlFor="">{i18next.t("Shipping time")}</label>
            </div>
            <div className="flex gap-x-6">
              <input
                className="border border-primaryDarkGrey rounded flex-grow p-2
                  dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
                type="date"
                id="schedule_date"
                value={scheduleDate}
                onChange={onChangeDate}
              ></input>
              <input
                className="border border-primaryDarkGrey rounded flex-grow p-2
                  dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
                type="time"
                id="schedule_time"
                value={scheduleTime}
                onChange={onChangeTime}
              ></input>
            </div>

            <InputValidationError
              errors={errors}
              fieldName="schedule_datetime"
            ></InputValidationError>
          </div>
          <div className="mb-4">
            <div>
              <label htmlFor="">{i18next.t("Shipping interval")}</label>
            </div>
            <div className="flex gap-x-6">
              <select
                name="schedule_percentage"
                id="schedule_percentage"
                className="custom-select border border-primaryDarkGrey rounded flex-grow p-2
                  dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
                value={
                  currentCampaign?.schedule_percentage
                    ? currentCampaign.schedule_percentage
                    : undefined
                }
                onChange={onChangeSelectHandler}
              >
                <option
                  value={getEnumKey("CampaignSendPercentage", "send5Percent")}
                >
                  {i18next.t("To 5% recipients")}
                </option>
                <option
                  value={getEnumKey("CampaignSendPercentage", "send20Percent")}
                >
                  {i18next.t("To 20% recipients")}
                </option>
                <option
                  value={getEnumKey("CampaignSendPercentage", "send33Percent")}
                >
                  {i18next.t("To 33% recipients")}
                </option>
                <option
                  value={getEnumKey("CampaignSendPercentage", "send50Percent")}
                >
                  {i18next.t("To 50% recipients")}
                </option>
                <option
                  value={getEnumKey("CampaignSendPercentage", "send100Percent")}
                >
                  {i18next.t("To 100% recipients")}
                </option>
              </select>
              <select
                name="schedule_timeout"
                id="schedule_timeout"
                className="custom-select border border-primarykGrey rounded flex-grow p-2
                  dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
                onChange={onChangeSelectHandler}
                value={
                  currentCampaign?.schedule_timeout
                    ? currentCampaign.schedule_timeout
                    : undefined
                }
              >
                <option value={getEnumKey("CampaignSendDelay", "none")}>
                  {i18next.t("At the same time")}
                </option>
                <option
                  value={getEnumKey("CampaignSendDelay", "after15Minutes")}
                >
                  {i18next.t("After 15 Minutes")}
                </option>
                <option
                  value={getEnumKey("CampaignSendDelay", "after30Minutes")}
                >
                  {i18next.t("After 30 Minutes")}
                </option>
                <option
                  value={getEnumKey("CampaignSendDelay", "after45Minutes")}
                >
                  {i18next.t("After 45 Minutes")}
                </option>
                <option value={getEnumKey("CampaignSendDelay", "after1Hour")}>
                  {i18next.t("After 1 Hour")}
                </option>
                <option value={getEnumKey("CampaignSendDelay", "after2Hours")}>
                  {i18next.t("After 2 Hours")}
                </option>
                <option value={getEnumKey("CampaignSendDelay", "after5Hours")}>
                  {i18next.t("After 5 Hours")}
                </option>
              </select>
            </div>
          </div>
          {/* can't calculated due to the possibility of errors */}
          {/*
            <div>
              {i18next.t("Sending is completed on")} XXXXXX{" "}
              {i18next.t("at")} XXXXXX {i18next.t(".")}
            </div>
           */}
        </div>
      </div>
    </div>
  );
};
export default SendSchedule;
