import i18next from "i18next";
import ConfirmationModal from "../UI/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";

interface Props {
  isModalVisible: boolean;
  onCloseHandler: Dispatch<SetStateAction<boolean>>;
  targetGroupId: string;
}

const TargetGroupDeletingConfirmationModal = ({
  isModalVisible,
  onCloseHandler,
  targetGroupId,
}: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const currentTenantId = currentTenant?.id;
  const navigate = useNavigate();
  const removeTargetGroup = () => {
    if (!targetGroupId || !currentTenantId) return;

    axios
      .delete("/tenants/" + currentTenantId + "/target-groups/" + targetGroupId)
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));
        navigate("/target-groups");
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  return (
    <ConfirmationModal
      show={isModalVisible}
      closeCallback={() => {
        onCloseHandler(false);
      }}
      confirmCallback={() => {
        removeTargetGroup();
      }}
      title={i18next.t("Remove record")}
      text={i18next.t("This action cannot be undone.")}
      confirmButtonText={i18next.t("Remove")}
      confirmButtonVariant="danger"
    />
  );
};
export default TargetGroupDeletingConfirmationModal;
