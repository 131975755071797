import React from "react";
import ReactDOM from "react-dom/client";
import "./css/fork-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from "./utils/LocalStorageState";
import throttle from "lodash/throttle";
import { setCurrentTenantID, setToken } from "./actions";
import allReducer from "./reducers";
import { initAxios } from "./utils/axios";
import { init as i18nInit } from "./utils/i18n";

i18nInit();

// redux
const initialLocalStorageState = loadStateFromLocalStorage();
export const store = configureStore({
  reducer: allReducer,
});
// TODO: refactor subscribe() to more efficient method targeting only relevant state properties
// throttled state persistence to LocalStorage
store.subscribe(
  throttle(() => {
    const token = store.getState().token;
    const currentTenantID = store.getState().currentTenantID;

    // refresh LocalStorage state
    if (
      initialLocalStorageState?.token !== token ||
      (currentTenantID &&
        initialLocalStorageState?.currentTenantID !== currentTenantID)
    ) {
      saveStateToLocalStorage({
        token,
        currentTenantID,
      });
    }
  }, 1000)
);

// set axios Bearer token
store.subscribe(() => {
  const token = store.getState().token;

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
});

// initialize store from LocalStorage state
if (initialLocalStorageState?.token) {
  store.dispatch(setToken(initialLocalStorageState.token));
}
if (initialLocalStorageState?.currentTenantID) {
  store.dispatch(setCurrentTenantID(initialLocalStorageState.currentTenantID));
}

initAxios();

const root = ReactDOM.createRoot(
  (document.getElementById("root") as HTMLElement) ||
    document.createElement("div") // created for tests: https://stackoverflow.com/questions/39986178/testing-react-target-container-is-not-a-dom-element
);
root.render(
  // <React.StrictMode> // prevent double rendering
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
