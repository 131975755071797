import i18next from "i18next";
import Campaign from "../../types/models/Campaign";

const DesignCreatedForUSB = ({ campaign }: { campaign: Campaign }) => {
  return (
    <div
      className="flex text-base gap-2 flex-wrap items-center
        lg:flex-nowrap lg:text-xs max-w-[400px] xl:max-w-none"
    >
      <div className="text-black dark:text-white w-full lg:w-auto">
        {i18next.t("Design created for")}
      </div>
      <div
        className={`${
          campaign?.template?.attachments?.length
            ? "text-primaryBlue dark:text-white font-bold"
            : ""
        }`}
      >
        <i className="fa fa-files-o" aria-hidden="true"></i> {i18next.t("File")}
        {campaign?.template?.attachments?.length ? (
          <>
            {" "}
            <i className="fa fa-check text-primaryGreen" aria-hidden="true"></i>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default DesignCreatedForUSB;
