import i18next from "i18next";
import { default as CampaignType } from "../../types/models/Campaign";
import { getEnumKey } from "../../utils/Enum";

const Report = ({ campaign }: { campaign?: CampaignType }) => {
  if (!campaign) return null;

  const sentCount = campaign.sent_count ? campaign.sent_count : 0;
  const undeliverableCount = campaign.undeliverable_count
    ? campaign.undeliverable_count
    : 0;
  const undeliverablePercent = campaign.sent_count
    ? undeliverableCount / sentCount
    : 0;
  const recievedCount = campaign.received_count ? campaign.received_count : 0;
  const recievedPercent = campaign.sent_count ? recievedCount / sentCount : 0;
  const openedCount = campaign.message_opened_count
    ? campaign.message_opened_count
    : 0;
  const openedPercent = campaign.sent_count ? openedCount / sentCount : 0;
  const clickedCount = campaign.clicked_count ? campaign.clicked_count : 0;
  const clickedPercent = campaign.sent_count ? clickedCount / sentCount : 0;
  const formSubmittedCount = campaign.form_submitted_count
    ? campaign.form_submitted_count
    : 0;
  const formSubmittedPercent = campaign.sent_count
    ? formSubmittedCount / sentCount
    : 0;

  return (
    <div
      className="flex flex-col self-stretch lg:self-auto lg:flex-row flex-auto
        justify-between"
    >
      <div className="text-sm pr-5">
        <h4 className="text-black dark:text-white text-lg">{campaign.name}</h4>
      </div>
      <div className="self-stretch lg:self-end lg:ml-8">
        {campaign.type === getEnumKey("CampaignType", "message") && (
          <div
            className="flex text-base gap-5 mr-5 lg:text-xs flex-wrap
            xl:flex-nowrap max-w-[400px] xl:max-w-none"
          >
            <div>
              <div>
                <span className="font-bold">{i18next.t("Undeliverable")}</span>{" "}
                <span>({undeliverableCount}</span>/<span>{sentCount})</span>
              </div>
              <div>
                <span className="font-bold">{undeliverablePercent}%</span>
              </div>
            </div>
            <div>
              <div>
                <span className="font-bold text-primaryBlue dark:text-primaryLightBlue">
                  {i18next.t("Recieved")}
                </span>{" "}
                <span>({recievedCount}</span>/<span>{sentCount})</span>
              </div>
              <div>
                <span className="font-bold text-primaryBlue dark:text-primaryLightBlue">
                  {recievedPercent}%
                </span>
              </div>
            </div>
            <div>
              <div>
                <span className="font-bold text-primaryGreen">
                  {i18next.t("Opened")}
                </span>{" "}
                <span>({openedCount}</span>/<span>{sentCount})</span>
              </div>
              <div>
                <span className="font-bold text-primaryGreen">
                  {openedPercent}%
                </span>
              </div>
            </div>
            <div>
              <div>
                <span className="font-bold text-primaryOrange">
                  {i18next.t("Clicked")}
                </span>{" "}
                <span>({clickedCount}</span>/<span>{sentCount})</span>
              </div>
              <div>
                <span className="font-bold text-primaryOrange">
                  {clickedPercent}%
                </span>
              </div>
            </div>
            <div>
              <div>
                <span className="font-bold text-primaryRed">
                  {i18next.t("Form submitted")}
                </span>{" "}
                <span>({formSubmittedCount}</span>/<span>{sentCount})</span>
              </div>
              <div>
                <span className="font-bold text-primaryRed">
                  {formSubmittedPercent}%
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Report;
