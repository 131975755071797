import i18next from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import Campaign from "../../types/models/Campaign";
import ReducerState from "../../types/ReducerState";
import FormErrorResponse from "../../types/FormErrorResponse";
import SendAsEmail from "./Rules/SendAsEmail";
import SendAsSms from "./Rules/SendAsSms";
import SendAsWhatsapp from "./Rules/SendAsWhatsapp";
import TrackingPixel from "./Rules/TrackingPixel";
import SendSchedule from "./Rules/SendSchedule";
import Interactions from "./Rules/Interactions";
import Domain from "../../types/models/Domain";

interface propsType {
  isCreate: boolean;
  currentState?: Campaign;
  errors: FormErrorResponse | null;
  onInputChangeHandler(field: string, value: string | boolean): void;
}

const removeFieldErrorsFromErrorBag = (
  errorBag: FormErrorResponse | null,
  fieldName: string
): void => {
  delete errorBag?.[fieldName];
};

const StepRules = ({
  isCreate,
  currentState,
  errors,
  onInputChangeHandler,
}: propsType) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [phishingDomains, setPhishingDomains] = useState<Domain[]>();

  const currentTenantId = useSelector(
    (state: ReducerState) => state.currentTenant
  )?.id;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    onInputChangeHandler(field, value);
    removeFieldErrorsFromErrorBag(errors, field);
  };
  const onChangeRadioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputChangeHandler(
      "schedule_send_immediately",
      event.target.id === "schedule_send_immediately" ? true : false
    );
  };
  const onChangeSelectHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const field = event.target.name;
    const value = event.target.value;

    onInputChangeHandler(field, value);
  };

  const onSetLoadingHandler = (loadingStatus: boolean) => {
    setHasLoaded(loadingStatus);
  };

  return (
    <div
      className="bg-white dark:bg-darkModeLighterBlue
        text-black dark:text-primaryLightGrey rounded-md overflow-hidden"
    >
      <div
        className="bg-primaryBlue dark:bg-darkModeBlue py-4 px-8 text-white
          text-sm"
      >
        <h1 className="text-lg m-0">{i18next.t("Send")}</h1>
      </div>

      <div className={`${hasLoaded ? "block" : "hidden"} py-4 px-8`}>
        <p>{i18next.t("Send as")}</p>
        <form action="">
          <SendAsEmail
            currentTenantId={currentTenantId}
            currentCampaign={currentState}
            onChangeHandler={onChangeHandler}
            onChangeSelectHandler={onChangeSelectHandler}
            setLoadingHandler={onSetLoadingHandler}
            phishingDomains={phishingDomains}
            setPhishingDomains={setPhishingDomains}
          ></SendAsEmail>
          <SendAsSms
            currentTenantId={currentTenantId}
            currentCampaign={currentState}
            onChangeHandler={onChangeHandler}
            onChangeSelectHandler={onChangeSelectHandler}
            phishingDomains={phishingDomains}
            errors={errors}
          ></SendAsSms>
          <SendAsWhatsapp
            currentTenantId={currentTenantId}
            currentCampaign={currentState}
            onChangeHandler={onChangeHandler}
            onChangeSelectHandler={onChangeSelectHandler}
            phishingDomains={phishingDomains}
            errors={errors}
          ></SendAsWhatsapp>
          <TrackingPixel
            isCreate={isCreate}
            currentState={currentState?.use_tracking_pixel}
            onChangeHandler={onChangeHandler}
          ></TrackingPixel>
          <SendSchedule
            isCreate={isCreate}
            currentCampaign={currentState}
            errors={errors}
            onChangeRadioHandler={onChangeRadioHandler}
            onInputChangeHandler={onInputChangeHandler}
            onChangeSelectHandler={onChangeSelectHandler}
          ></SendSchedule>
          {(currentState?.are_interactions_possible || isCreate) && (
            <Interactions
              errors={errors}
              interaktionsPossibleUntil={
                currentState?.interactions_possible_until
              }
              onInputChangeHandler={onInputChangeHandler}
            ></Interactions>
          )}
        </form>
      </div>

      <div className={`${!hasLoaded ? "block" : "hidden"} loading mb-8`}></div>
    </div>
  );
};
export default StepRules;
