import { ReactNode } from "react";

interface propsType {
  children: ReactNode;
  className?: string;
}

const Wrapper = ({ children, className }: propsType) => {
  return (
    <div
      className={`${
        className ? className : ""
      } bg-white dark:bg-darkModeLighterBlue shadow-sm rounded-md p-6`}
    >
      {children}
    </div>
  );
};

export default Wrapper;
