import i18next from "i18next";
import Template from "../../types/models/Template";

const DesignCreatedForUSB = ({ template }: { template: Template }) => {
  return (
    <div
      className={`${
        template.attachments?.length && "text-black dark:text-white"
      }`}
    >
      <i className="fa fa-files-o" aria-hidden="true"></i> {i18next.t("File")}
      {template.attachments?.length ? (
        <>
          {" "}
          <i className="fa fa-check text-primaryGreen" aria-hidden="true"></i>
        </>
      ) : null}
    </div>
  );
};
export default DesignCreatedForUSB;
