import Template from "../../types/models/Template";
import { useNavigate } from "react-router-dom";
import { i18next } from "../../utils/i18n";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import { getEnumKey } from "../../utils/Enum";
import DesignCreatedForUSB from "./DesignCreatedForUSB";
import DesignCreatedForMessage from "./DesignCreatedForMessage";

const TemplateListItem = ({ template }: { template: Template }) => {
  const navigate = useNavigate();

  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const [imgURL, setImgURL] = useState("");
  useEffect(() => {
    fetchImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const fetchImg = () => {
    if (!currentTenant?.id || !template?.id) return;
    setHasLoaded(false);

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/templates/" +
      template.id +
      "/template-preview.jpg";
    axios
      .get<Blob>(url, { responseType: "blob" })
      .then((response) => {
        let imageURL = URL.createObjectURL(response.data);
        setImgURL(imageURL);
      })
      .catch(() => {
        toast(i18next.t("Error loading image for template"));
        // resetData();
      })
      .finally(() => {
        if (!hasLoaded) setHasLoaded(true);
      });
  };
  return (
    <div className="flex flex-1">
      <div className="flex w-1/6 bg-white mt-[-1rem] ml-[-1rem] mr-[-1rem] mb-[-2rem]">
        <img
          src={imgURL}
          alt=""
          className="self-stretch max-w-full object-contain"
        />
      </div>
      <div
        className=" js-interaction pl-8"
        onClick={() => {
          navigate("/templates/" + template.id);
        }}
      >
        <div className="fw-bold text-lg text-black dark:text-white">
          {template.name}
        </div>
        <div className="flex flex-wrap gap-x-2 text-sm">
          <div className="text-black dark:text-white">
            {i18next.t("Design created for")}
          </div>
          {template.campaign_type === getEnumKey("CampaignType", "message") ? (
            <DesignCreatedForMessage template={template} />
          ) : (
            <DesignCreatedForUSB template={template} />
          )}
        </div>
      </div>
    </div>
  );
};
export default TemplateListItem;
