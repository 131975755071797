import i18next from "i18next";
import { Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import Template from "../../types/models/Template";

interface Props {
  currentTab: number;
  setCurrentTab: Dispatch<SetStateAction<number>>;
  template: Template | undefined;
}

const TemplateTabs = ({ currentTab, setCurrentTab, template }: Props) => {
  return (
    <Form.Group className="flex gap-3">
      <Form.Label
        htmlFor="email_content"
        className={`${
          currentTab === 0 ? "bg-white" : "bg-primaryLightGrey"
        } mt-4 mb-[-1px] text-black px-6 py-6 font-bold flex-inline items-center
          shadow-tabs cursor-pointer rounded-tl rounded-tr`}
        onClick={() => {
          setCurrentTab(0);
        }}
      >
        <i className="fa fa-envelope-o font-bold pr-2" aria-hidden="true"></i>
        {i18next.t("E-mail content")}
        <i
          className={`${
            template?.email_content ? "opacity-100" : "opacity-0"
          } fa fa-check font-bold pl-2 text-primaryGreen opacity-1`}
          aria-hidden="true"
        ></i>
      </Form.Label>

      <Form.Label
        htmlFor="sms_content"
        className={`${
          currentTab === 1 ? "bg-white" : "bg-primaryLightGrey"
        } mt-4 mb-[-1px] text-black px-6 py-6 font-bold flex-inline items-center
          shadow-tabs cursor-pointer rounded-tl rounded-tr`}
        onClick={() => {
          setCurrentTab(1);
        }}
      >
        <i className="fa fa-mobile font-bold pr-2" aria-hidden="true"></i>
        {i18next.t("SMS content")}
        <i
          className={`${
            template?.sms_content ? "opacity-100" : "opacity-0"
          } fa fa-check font-bold pl-2 text-primaryGreen opacity-1`}
          aria-hidden="true"
        ></i>
      </Form.Label>
      <Form.Label
        htmlFor="whatsapp_content"
        className={`${
          currentTab === 2 ? "bg-white" : "bg-primaryLightGrey"
        } mt-4 mb-[-1px] text-black px-6 py-6 font-bold flex-inline items-center
          shadow-tabs cursor-pointer rounded-tl rounded-tr`}
        onClick={() => {
          setCurrentTab(2);
        }}
      >
        <i className="fa fa-whatsapp font-bold pr-2" aria-hidden="true"></i>
        {i18next.t("WhatsApp content")}
        <i
          className={`${
            template?.whatsapp_content ? "opacity-100" : "opacity-0"
          } fa fa-check font-bold pl-2 text-primaryGreen opacity-1`}
          aria-hidden="true"
        ></i>
      </Form.Label>

      <Form.Label
        htmlFor="phishing_page_content"
        className={`${
          currentTab === 3 ? "bg-white" : "bg-primaryLightGrey"
        } mt-4 mb-[-1px] text-black px-6 py-6 font-bold flex-inline items-center
          shadow-tabs cursor-pointer rounded-tl rounded-tr`}
        onClick={() => {
          setCurrentTab(3);
        }}
      >
        <i className="fa fa-desktop font-bold pr-2" aria-hidden="true"></i>
        {i18next.t("Phishing page")}
        <i
          className={`${
            template?.phishing_page_content ? "opacity-100" : "opacity-0"
          } fa fa-check font-bold pl-2 text-primaryGreen opacity-1`}
          aria-hidden="true"
        ></i>
      </Form.Label>

      <Form.Label
        htmlFor="reproof_page_content"
        className={`${
          currentTab === 4 ? "bg-white" : "bg-primaryLightGrey"
        } mt-4 mb-[-1px] text-black px-6 py-6 font-bold flex-inline items-center
          shadow-tabs cursor-pointer rounded-tl rounded-tr`}
        onClick={() => {
          setCurrentTab(4);
        }}
      >
        <i
          className="fa fa-graduation-cap font-bold pr-2"
          aria-hidden="true"
        ></i>
        {i18next.t("Instructions page")}
        <i
          className={`${
            template?.reproof_page_content ? "opacity-100" : "opacity-0"
          } fa fa-check font-bold pl-2 text-primaryGreen opacity-1`}
          aria-hidden="true"
        ></i>
      </Form.Label>
    </Form.Group>
  );
};
export default TemplateTabs;
