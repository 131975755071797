import { Form } from "react-bootstrap";
import EmailSubject from "./EmailSubject";
import Button from "../UI/Button";
import { toast } from "react-hot-toast";
import axios from "axios";
import i18next from "i18next";
import { MouseEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import MediaItem from "../../types/models/MediaItem";
import Attachments from "./Attachments/Attachments";
import Template from "../../types/models/Template";

interface Props {
  isVisible: boolean;
  isLocked: boolean;
  template: Template | undefined;
  fetchTemplate: () => void;
  emailFormat: string;
  setTemplate: (field: string, value: string | number) => void;
  addImageToTemplate: (arg0: string) => void;
}

const EmailContent = ({
  isVisible,
  isLocked,
  template,
  fetchTemplate,
  emailFormat,
  setTemplate,
  addImageToTemplate,
}: Props) => {
  const [testEmail, setTestEmail] = useState("");
  const { id } = useParams();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const currentTenantID = currentTenant?.id ? currentTenant.id : null;
  const [currentTab, setCurrentTab] = useState(0);
  const [isMediaLibraryAvailable, setIsMediaLibraryAvailable] = useState(true);
  const [isTemplateRaw, setIsTemplateRaw] = useState(true);
  const [htmlForRender, setHtmlForRender] = useState<string | undefined>();
  const [hasLoaded, setHasLoaded] = useState(true);

  const onEmailFormatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === "html") {
      setTemplate("email_format", 1); // html
      setTemplate("email_content_input_mode", 1); // raw
      setIsMediaLibraryAvailable(true);
    }
    if (e.target.id === "mjml") {
      setTemplate("email_format", 1); // html
      setTemplate("email_content_input_mode", 0); // editor
      setIsMediaLibraryAvailable(true);
    }
    if (e.target.id === "text") {
      setTemplate("email_format", 0); // text
      setTemplate("email_content_input_mode", 1); // raw
      setIsMediaLibraryAvailable(false);
    }
  };
  const testSenden = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!id || !currentTenantID) return;

    const url =
      "/tenants/" + currentTenantID + "/templates/" + id + "/send-test";
    const data = {
      test_email: testEmail,
      channels: [0], // email channel = 0
    };
    axios
      .post(url, data)
      .then((response) => {
        toast(i18next.t("Test sent"));
      })
      .catch(() => {
        toast(i18next.t("Fehler bei der Testsendung"));
      });
  };

  // replace placeholders with URLs
  const setHtmlForRenderWithCorrectUrls = (html: string) => {
    const parser = new DOMParser();
    const emailContentHTML = parser.parseFromString(html, "text/html");
    const numberOfImg = emailContentHTML.getElementsByTagName("img").length;

    // if there are no images
    if (!numberOfImg) {
      setHtmlForRender(html);
      return;
    }

    // if there are images
    for (let i = 0; i < numberOfImg; i++) {
      const imgSrc = emailContentHTML
        .getElementsByTagName("img")
        [i].src.toString();
      const imgUrlFromPlaceholder = imgSrc.match(/@media-item-url\(([^)]+)\)/); // between @media-item-url()
      const imgId =
        imgUrlFromPlaceholder && imgUrlFromPlaceholder[1]
          ? imgUrlFromPlaceholder[1]
          : null;
      axios
        .get<MediaItem>(
          "/tenants/" + currentTenant?.id + "/media-items/" + imgId
        )
        .then((response) => {
          if (!response.data.url) return;
          emailContentHTML.getElementsByTagName("img")[i].src =
            response.data.url;

          if (i === numberOfImg - 1) {
            const result = new XMLSerializer().serializeToString(
              emailContentHTML
            );
            setHtmlForRender(result);
          }
        })
        .catch(() => {
          toast(i18next.t("Error"));
        });
    }
  };

  const renderHTML = () => {
    if (template?.email_content && emailFormat === "html") {
      setHtmlForRenderWithCorrectUrls(template.email_content);
    }
    if (template?.email_content && emailFormat === "mjml") {
      parseMjmlToHtml(template.email_content);
    }
  };

  useEffect(() => {
    renderHTML();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showResultTemplateHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsTemplateRaw(false);
    renderHTML();
  };
  const showRawTemplateHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsTemplateRaw(true);
  };

  const parseMjmlToHtml = (mjml: string) => {
    if (!currentTenant?.id) return;
    setHasLoaded(false);
    axios
      .post(
        "/tenants/" +
          currentTenant?.id +
          "/templates/mjml-to-html/?mjml=" +
          encodeURIComponent(mjml)
      )
      .then((response) => {
        setHasLoaded(true);
        setHtmlForRenderWithCorrectUrls(response.data.html);
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };

  return (
    <div className="flex">
      <div className="grow">
        <EmailSubject
          isVisible={isVisible}
          emailSubject={template?.email_subject ? template.email_subject : ""}
          setTemplate={setTemplate}
          addImageToTemplate={addImageToTemplate}
        ></EmailSubject>
        {isLocked ? (
          <div className="text-white text-center bg-primaryRed opacity-80 -mt-4 schadow-md">
            {i18next.t("Content changes are blocked by another user")}
          </div>
        ) : null}
        {isVisible ? (
          isTemplateRaw ? (
            <Form.Control
              as="textarea"
              placeholder={i18next.t("E-mail content")}
              className={`${
                isVisible ? "block" : "hidden"
              } mb-4 focus-reset focus:outline-none focus:shadow-none
                border-white focus:border-white
                rounded-tl-none rounded-tr-none shadow-md pt-0`}
              rows={15}
              id="email_content"
              value={template?.email_content ? template.email_content : ""}
              onChange={(e) => {
                setTemplate("email_content", e.target.value);
                // renderHTML();
              }}
            />
          ) : hasLoaded ? (
            <div className="min-h-full bg-white email-content">
              {template?.email_content && htmlForRender ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlForRender,
                  }}
                />
              ) : null}
            </div>
          ) : (
            <div className="loading"></div>
          )
        ) : null}
      </div>
      <div
        className={`${
          isVisible ? "block" : "hidden"
        }  shadow-email-content-tabs flex w-1/4 flex-col min-h-[400px]
          border-solid border-0 border-l
          border-primaryGrey bg-white dark:bg-darkModeDarkBlue rounded-r-md mb-4`}
      >
        <div className="flex items-stretch">
          <div
            className={`${
              currentTab === 0
                ? "bg-white dark:bg-darkModeDarkBlue"
                : "bg-primaryLightGrey dark:bg-darkModeBlue"
            } flex-1 px-4 py-4 cursor-pointer text-center
              font-bold text-primaryDarkGrey text-sm flex items-center justify-center`}
            onClick={() => {
              setCurrentTab(0);
            }}
            title={i18next.t("Settings")}
          >
            <span className="fa fa-gear"></span>
          </div>
          <div
            className={`${
              currentTab === 1
                ? "bg-white dark:bg-darkModeDarkBlue"
                : "bg-primaryLightGrey dark:bg-darkModeBlue"
            } flex-1 px-4 py-4 cursor-pointer text-center
              font-bold text-primaryDarkGrey  text-sm flex items-center justify-center`}
            onClick={() => {
              setCurrentTab(1);
            }}
            title={i18next.t("Test send")}
          >
            <span className="fa fa-bug"></span>
          </div>
          <div
            className={`${
              isMediaLibraryAvailable ? "cursor-pointer" : "opacity-20"
            }
            ${
              currentTab === 2
                ? "bg-white dark:bg-darkModeDarkBlue"
                : "bg-primaryLightGrey dark:bg-darkModeBlue"
            } flex-1 px-4 py-4 flex text-center rounded-tr-md
              font-bold text-primaryDarkGrey text-sm items-center justify-center`}
            onClick={() => {
              if (isMediaLibraryAvailable) setCurrentTab(2);
            }}
            title={i18next.t("Attachments")}
          >
            <span className="fa fa-paperclip"></span>
          </div>
        </div>

        <div className={`${currentTab === 0 ? "flex" : "hidden"} flex-col p-4`}>
          <span>{i18next.t("Email Format")}</span>

          <div className="flex gap-4 mb-6">
            <input
              type="radio"
              className="email-format hidden"
              id="html"
              name="format_description"
              checked={emailFormat === "html" ? true : false}
              onChange={onEmailFormatChange}
            />
            <label htmlFor="html" className="flex items-center cursor-pointer">
              <span className="radio h-4 w-4 mr-2 inline-block border rounded-full"></span>
              HTML
            </label>

            <input
              type="radio"
              className="email-format hidden"
              id="mjml"
              name="format_description"
              checked={emailFormat === "mjml" ? true : false}
              onChange={onEmailFormatChange}
            />
            <label htmlFor="mjml" className="flex items-center cursor-pointer">
              <span className="radio h-4 w-4 mr-2 inline-block border rounded-full"></span>{" "}
              MJML
            </label>

            <input
              type="radio"
              id="text"
              className="email-format hidden"
              name="format_description"
              checked={emailFormat === "text" ? true : false}
              onChange={onEmailFormatChange}
            />
            <label htmlFor="text" className="flex items-center cursor-pointer">
              <span className="radio h-4 w-4 mr-2 inline-block border rounded-full"></span>
              Text
            </label>
          </div>
          <div className="mb-4">
            <label htmlFor="trap_behavior">{i18next.t("Trap Behavior")}</label>
            <select
              name="trap_behavior"
              id="trap_behavior"
              className="custom-select trap flex focus:outline-none dark:text-white"
              value={template?.trap_behavior}
              onChange={(e) => {
                setTemplate("trap_behavior", +e.target.value);
              }}
            >
              <option value="0">{i18next.t("Instructions page")}</option>
              <option value="1">{i18next.t("Redirect")}</option>
              <option value="2">{i18next.t("None")}</option>
            </select>
          </div>
          {template?.trap_behavior === 1 ? (
            <>
              <label htmlFor="redirect_url">{i18next.t("Redirect URL")}</label>
              <input
                type="text"
                id="redirect_url"
                placeholder="https://..."
                className="border-solid border-0 border-b border-primaryDarkGrey mb-4
                  focus:outline-none focus:border-black"
                value={template?.redirect_url}
                onChange={(e) => {
                  setTemplate("redirect_url", e.target.value);
                }}
              />
            </>
          ) : null}
          {isTemplateRaw ? (
            <Button onClick={showResultTemplateHandler}>
              {i18next.t("Show result")}
            </Button>
          ) : (
            <Button onClick={showRawTemplateHandler}>
              {i18next.t("Show raw version")}
            </Button>
          )}
        </div>
        <div
          className={`${currentTab === 1 ? "flex" : "hidden"} flex-col p-4 `}
        >
          <label htmlFor="test_email">{i18next.t("Test email to")}</label>
          <input
            id="test_email"
            type="text"
            placeholder="test@email.com"
            className="border-solid border-0 border-b border-primaryDarkGrey mb-4
              focus:outline-none focus:border-black"
            value={testEmail}
            onChange={(e) => {
              setTestEmail(e.target.value);
              setTemplate("test_email", e.target.value);
            }}
          />
          <Button onClick={testSenden}>{i18next.t("Send")}</Button>
        </div>
        <div
          className={`${currentTab === 2 ? "flex" : "hidden"} flex-col p-4 `}
        >
          {template && template.id ? (
            <Attachments
              template={template}
              // onChange={() => {}} // fetch attachments
            ></Attachments>
          ) : (
            <>{i18next.t("You have to create a template")}</>
          )}
        </div>
      </div>
    </div>
  );
};
export default EmailContent;
