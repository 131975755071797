import { useState } from "react";
import { default as MediaItemType } from "../../../types/models/MediaItem";
import i18next from "i18next";
import Button from "../../UI/Button";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";
import axios from "axios";

interface Props {
  item: MediaItemType;
  fileNameLiftingUp: boolean;
  onSelect: (arg0: string, arg1?: string) => void;
  onRemove: (arg0: boolean) => void;
  isAttachments: boolean;
}
const getIconForFile = (filename: string) => {
  const itemExtensionMatch = filename.match(/\.[0-9a-z]+$/i);
  const itemExtension = itemExtensionMatch ? itemExtensionMatch[0] : null;
  let itemExtensionIcon;
  if (itemExtension === ".pdf") {
    itemExtensionIcon = "pdf";
  } else if (itemExtension === ".doc" || itemExtension === ".docx") {
    itemExtensionIcon = "word";
  } else if (itemExtension === ".xls" || itemExtension === ".xlsx") {
    itemExtensionIcon = "excel";
  } else if (itemExtension === ".ppt" || itemExtension === ".pptx") {
    itemExtensionIcon = "exel";
  } else if (itemExtension === ".txt") {
    itemExtensionIcon = "text";
  } else if (
    itemExtension === ".zip" ||
    itemExtension === ".rar" ||
    itemExtension === ".7z"
  ) {
    itemExtensionIcon = "arcive";
  } else {
    itemExtensionIcon = "file";
  }
  return `fa-file-${itemExtensionIcon}-o`;
};
const dateToString = (date: string | undefined) => {
  if (!date) return;
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const tmpMonth = dateObj.getMonth() + 1;
  const month = tmpMonth > 9 ? tmpMonth : "0" + tmpMonth;
  const day =
    dateObj.getDate() > 9 ? dateObj.getDate() : "0" + dateObj.getDate();
  const hour =
    dateObj.getHours() > 9 ? dateObj.getHours() : "0" + dateObj.getHours();
  const minute =
    dateObj.getMinutes() > 9
      ? dateObj.getMinutes()
      : "0" + dateObj.getMinutes();
  let string = `${hour}:${minute} ${day}.${month}.${year}`;
  return string;
};
const MediaItem = ({
  item,
  fileNameLiftingUp,
  onSelect,
  onRemove,
  isAttachments,
}: Props) => {
  const [addingStatus, setAddingStatus] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  if (!item.id || !item) return null;

  const removeMediaItem = (id: string | undefined) => {
    if (!currentTenant?.id || !id) return;

    setDeleteStatus(true);

    const url = "/tenants/" + currentTenant.id + "/media-items/" + id;

    const axiosHandler = axios.delete(url);

    axiosHandler
      .then((response) => {
        onRemove(true);
      })
      .catch((error) => {
        toast(i18next.t("Error deleting record."));
        onRemove(false);
      })
      .finally(() => {
        setDeleteStatus(false);
        setDeleteConfirmation(false);
      });
  };

  return (
    <>
      {isAttachments ? (
        // for attachments
        <div
          onClick={() => {
            if (!item.id) return;
            // when an item is added to the template content, passing the file name is not required
            if (!fileNameLiftingUp) onSelect(item.id);
            // when an item is added to attached files, the file name must be passed
            if (fileNameLiftingUp) onSelect(item.id, item.original_filename);
            setAddingStatus(true);
          }}
          className={`${
            addingStatus
              ? "shadow border-primaryBlue"
              : "shadow-sm border-white"
          } flex basis-[49%] rounded-md relative overflow-hidden cursor-pointer
            max-w-full bg-white p-4 gap-4 items-center border`}
        >
          <div className="text-3xl">
            <span
              className={`fa ${item.filename && getIconForFile(item.filename)}`}
            ></span>
          </div>
          <div>
            <div className="w-full max-w-full truncate font-bold">
              {item.original_filename}
              <span
                className={`fa fa-check ml-3
                  ${
                    addingStatus ? "opacity-1" : "opacity-0"
                  } text-primaryGreen`}
              ></span>
            </div>
            <div className="w-full max-w-full text-sm">
              {dateToString(item.created_at)}
            </div>
          </div>
          <div className="flex ml-auto justify-between items-center">
            <div className="flex gap-4">
              <span
                className="fa fa-trash-o cursor-pointer text-lg"
                onClick={() => {
                  setDeleteConfirmation(true);
                }}
              ></span>
            </div>
          </div>
          {deleteConfirmation ? (
            <div
              className="absolute z-10 bg-black/80 top-0 left-0 right-0 bottom-0
                flex justify-center items-center gap-4 px-4"
            >
              {!deleteStatus ? (
                <>
                  <div className="text-white text-center">
                    {i18next.t("This action cannot be undone.")}
                  </div>
                  <Button
                    onClick={() => {
                      if (!item.id) return;
                      removeMediaItem(item.id);
                    }}
                  >
                    {i18next.t("Confirm")}
                  </Button>
                  <Button
                    onClick={() => {
                      setDeleteConfirmation(false);
                    }}
                  >
                    {i18next.t("Close")}
                  </Button>
                </>
              ) : (
                <div className="loading mt-auto border-t-white/80"></div>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        // for images
        <>
          {item.url ? ( // if tus upload was finished
            <div
              className={`${
                addingStatus
                  ? "shadow border-primaryBlue"
                  : "shadow-sm border-white"
              } basis-[12.2%] shrink-0 grow-0 overflow-hidden
              self-stretch items-end flex flex-col border
              rounded-md bg-white p-4 relative cursor-pointer`}
              onClick={() => {
                if (!item.id) return;
                // when an item is added to the template content, passing the file name is not required
                if (!fileNameLiftingUp) onSelect(item.id);
                // when an item is added to attached files, the file name must be passed
                if (fileNameLiftingUp)
                  onSelect(item.id, item.original_filename);
                setAddingStatus(true);
              }}
            >
              <div className="flex justify-between w-full items-center text-primaryBlue">
                <div className="flex gap-2">
                  <span
                    className="fa fa-trash-o cursor-pointer"
                    onClick={() => {
                      setDeleteConfirmation(true);
                    }}
                  ></span>
                </div>
                <div
                  className={`${
                    addingStatus ? "opacity-1" : "opacity-0"
                  } text-primaryGreen`}
                >
                  <span className="fa fa-check"></span>
                </div>
              </div>

              <div className="w-full pb-[100%] relative mb-4">
                <img
                  src={item.url}
                  alt={item.alt_text}
                  className="w-full max-w-full max-h-full object-contain absolute right-0 left-0 top-0 bottom-0 m-auto"
                />
              </div>

              <div className="w-full max-w-full truncate font-bold">
                {item.original_filename}
              </div>
              <div className="w-full max-w-full text-sm">
                {dateToString(item.created_at)}
              </div>
              {deleteConfirmation ? (
                <div
                  className="absolute z-10 bg-black/80 top-0 left-0 right-0 bottom-0
                    flex flex-col justify-between items-center"
                >
                  {!deleteStatus ? (
                    <>
                      <div
                        className="text-white text-right self-end px-3 pt-2 cursor-pointer"
                        onClick={() => setDeleteConfirmation(false)}
                      >
                        <span className="fa fa-times"></span>
                      </div>
                      <div className="text-white px-3 text-center">
                        {i18next.t("This action cannot be undone.")}
                      </div>
                      <Button
                        className="mb-4"
                        onClick={() => {
                          if (!item.id) return;
                          removeMediaItem(item.id);
                        }}
                      >
                        {i18next.t("Confirm")}
                      </Button>
                    </>
                  ) : (
                    <div className="loading mt-auto border-t-white/80"></div>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
export default MediaItem;
