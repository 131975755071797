import i18next from "i18next";
import Button from "react-bootstrap/Button";
import { default as CustomButton } from "../components/UI/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { default as DomainType } from "../types/models/Domain";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import FormErrorResponse from "../types/FormErrorResponse";
import useSave from "../hooks/use-save";
import InputValidationError from "../components/UI/InputValidationError";

const dateToString = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const tmpMonth = dateObj.getMonth() + 1;
  const month = tmpMonth > 9 ? tmpMonth : "0" + tmpMonth;
  const day =
    dateObj.getDate() > 9 ? dateObj.getDate() : "0" + dateObj.getDate();
  const hour =
    dateObj.getHours() > 9 ? dateObj.getHours() : "0" + dateObj.getHours();
  const minute =
    dateObj.getMinutes() > 9
      ? dateObj.getMinutes()
      : "0" + dateObj.getMinutes();
  let string = day + "." + month + "." + year + " " + hour + ":" + minute;
  return string;
};

function Domain({ isCreate }: { isCreate: boolean }) {
  const { id } = useParams();
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [newDomain, setNewDomain] = useState<DomainType>();
  const [isAvailable, setIsAvailable] = useState<boolean | undefined>(
    undefined
  );
  const [errors, setErrors] = useState<FormErrorResponse | null>(null);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const extractFieldErrorsFromErrorBag = (
    errorBag: FormErrorResponse | null,
    fieldName: string
  ): string[] => {
    if (!errorBag) return [];

    const errorTexts = errorBag?.[fieldName];

    return errorTexts || [];
  };
  const removeFieldErrorsFromErrorBag = (
    errorBag: FormErrorResponse | null,
    fieldName: string
  ): void => {
    delete errorBag?.[fieldName];
  };

  const fetchItem = () => {
    if (isCreate || !id || !currentTenant?.id) return;
    setHasLoaded(false);
    axios
      .get<DomainType>("/tenants/" + currentTenant?.id + "/domains/" + id)
      .then((response) => {
        setNewDomain(response.data);
        setHasLoaded(true);
      })
      .catch(() => {
        toast(i18next.t("Error loading page"));

        navigate("/domains");
      });
  };

  const [savedData, error, sendRequest] = useSave(
    id ? "/domains/" + id : "/domains/",
    newDomain,
    isCreate
  );
  useEffect(() => {
    if (savedData) fetchItem();
    if (error) setErrors(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData, error]);

  const remove = () => {
    if (!id || !currentTenant?.id) return;

    axios
      .delete("/tenants/" + currentTenant?.id + "/domains/" + id)
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));

        navigate("/domains");
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = () => {
    setNewDomain({ domain_name: "" });
    setIsAvailable(undefined);
  };

  useEffect(() => {
    isCreate ? reset() : fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id;
    if (field === "domain_name") setIsAvailable(undefined);
    // set set_hsts_header to false, if has_phishing_support is false
    if (field === "has_phishing_support" && !event.target.checked)
      setNewDomain((prev) => ({ ...prev, set_hsts_header: false }));
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setNewDomain((prev) => ({ ...prev, [field]: value }));
    removeFieldErrorsFromErrorBag(errors, field);
  };

  const checkAvailability = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!currentTenant?.id) return;

    const url =
      "/tenants/" +
      currentTenant.id +
      "/domains/check-availability/?domain_name=" +
      newDomain?.domain_name;

    axios
      .get(url)
      .then((response) => {
        setIsAvailable(response.data.is_available);
      })
      .catch(() => {
        setIsAvailable(false);
      });
  };

  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          remove();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      {hasLoaded || isCreate ? (
        <>
          <h3 className="mb-5 dark:text-white">Domain</h3>
          <Form
            className="dark:text-white"
            onSubmit={(e) => {
              e.preventDefault();
              sendRequest();
            }}
          >
            <ButtonGroup className="max-w-min flex justify-end mb-6 ml-auto">
              <Button
                type="submit"
                className="bg-primaryGreen border-primaryGreen px-5 min-w-max"
              >
                <i
                  className="fa fa-save text-white text-lg pr-2"
                  aria-hidden="true"
                ></i>
                {i18next.t("Save")}
              </Button>
              {!isCreate && (
                <>
                  <Button
                    className="bg-primaryRed border-primaryRed px-5 min-w-max"
                    variant="danger"
                    onClick={() => {
                      setShowDeletionConfirmationModal(true);
                    }}
                  >
                    <i
                      className="fa fa-trash-o text-white text-lg pr-2"
                      aria-hidden="true"
                    ></i>
                    {i18next.t("Remove")}
                  </Button>
                </>
              )}
            </ButtonGroup>
            <Form.Group className="mb-3 flex gap-6 bg-white dark:bg-darkModeBlue p-6 rounded shadow-md items-start">
              <Form.Group className="mb-3 flex-1">
                <Form.Label className="text-sm font-bold">
                  {i18next.t("Name")}
                </Form.Label>
                <div className="flex">
                  <Form.Control
                    type="text"
                    id="domain_name"
                    value={newDomain?.domain_name}
                    onChange={onChangeHandler}
                    className="focus-reset border-solid border-0 border-b rounded-none
                      border-primaryGrey focus:border-primaryDarkGrey dark:bg-darkModeBlue dark:text-white"
                    isInvalid={
                      extractFieldErrorsFromErrorBag(errors, "domain_name")
                        .length > 0
                    }
                  />
                  <CustomButton
                    className="min-w-max"
                    onClick={checkAvailability}
                  >
                    {i18next.t("Check Availability")}
                  </CustomButton>
                </div>
                <InputValidationError
                  errors={errors}
                  fieldName="domain_name"
                ></InputValidationError>
                <Form.Text className="text-muted">
                  {i18next.t("The value has to be unique.")}
                </Form.Text>

                <div
                  className={`${
                    isAvailable ? "text-primaryGreen" : "text-primaryRed"
                  } flex-1`}
                >
                  {isAvailable
                    ? i18next.t("Domain is available")
                    : isAvailable === false
                    ? "Domain is not available"
                    : ""}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Group className="mb-3 flex items-center">
                  <input
                    type="checkbox"
                    className="domain-type hidden mr-2 bg-transparent border-0 border-b
                      rounded-none border-primaryGrey focus:border-primaryDarkGrey
                      focus:shadow-none focus-reset"
                    checked={newDomain?.publish_to_sub_tenants ? true : false}
                    id="publish_to_sub_tenants"
                    onChange={onChangeHandler}
                  />
                  <Form.Label
                    htmlFor="publish_to_sub_tenants"
                    className="cursor-pointer mb-0 text-sm font-bold flex items-center"
                  >
                    <span
                      className="checkbox h-4 w-4 mr-2 inline-block border
                      rounded-sm"
                    ></span>
                    {i18next.t("Publish to sub tenants")}
                  </Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 flex items-center">
                  <input
                    type="checkbox"
                    className="domain-type hidden mr-2 bg-transparent
                      border-solid border-0 border-b border-primaryDarkGrey rounded-none
                      focus:border-black focus:shadow-none focus-reset"
                    checked={newDomain?.is_archived ? true : false}
                    id="is_archived"
                    onChange={onChangeHandler}
                  />
                  <Form.Label
                    htmlFor="is_archived"
                    className="cursor-pointer flex items-center mb-0 text-sm font-bold"
                  >
                    <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
                    {i18next.t("Is archived")}
                  </Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 flex items-center">
                  <input
                    type="checkbox"
                    className="domain-type hidden mr-2 bg-transparent border-t-0
                      border-solid border-0 border-b border-primaryDarkGrey rounded-none
                      focus:border-black focus:shadow-none focus-reset"
                    checked={newDomain?.has_email_support ? true : false}
                    id="has_email_support"
                    onChange={onChangeHandler}
                  />
                  <Form.Label
                    htmlFor="has_email_support"
                    className="cursor-pointer flex items-center mb-0 text-sm font-bold"
                  >
                    <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
                    {i18next.t("Email support")}
                  </Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 flex items-center">
                  <input
                    type="checkbox"
                    className="domain-type hidden mr-2 bg-transparent border-t-0
                      border-solid border-0 border-b border-primaryDarkGrey rounded-none
                      focus:border-black focus:shadow-none focus-reset"
                    checked={newDomain?.has_phishing_support ? true : false}
                    id="has_phishing_support"
                    onChange={onChangeHandler}
                  />
                  <Form.Label
                    htmlFor="has_phishing_support"
                    className="cursor-pointer flex items-center mb-0 text-sm font-bold"
                  >
                    <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
                    {i18next.t("Phishing support")}
                  </Form.Label>
                </Form.Group>

                {newDomain?.has_phishing_support ? (
                  <Form.Group className="mb-3 flex items-center">
                    <input
                      type="checkbox"
                      className="domain-type hidden mr-2 bg-transparent border-t-0
                        border-solid border-0 border-b border-primaryDarkGrey rounded-none
                        focus:border-black focus:shadow-none focus-reset"
                      checked={newDomain?.set_hsts_header ? true : false}
                      id="set_hsts_header"
                      onChange={onChangeHandler}
                    />
                    <Form.Label
                      htmlFor="set_hsts_header"
                      className="cursor-pointer flex items-center mb-0 text-sm font-bold"
                    >
                      <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
                      {i18next.t("HSTS Header")}
                    </Form.Label>
                  </Form.Group>
                ) : null}
              </Form.Group>
            </Form.Group>
            {!isCreate && newDomain && (
              <Form.Group className="mb-3 flex gap-6">
                <div className="text-sm font-bold">
                  <span className="text-primaryDarkGrey">
                    {i18next.t("Created at")}:
                  </span>{" "}
                  {newDomain.created_at && dateToString(newDomain.created_at)}
                </div>
                <div className="text-sm font-bold">
                  <span className="text-primaryDarkGrey">
                    {i18next.t("Updated at")}:
                  </span>{" "}
                  {newDomain.updated_at && dateToString(newDomain.updated_at)}{" "}
                </div>
              </Form.Group>
            )}
          </Form>
        </>
      ) : (
        <div className="loading"></div>
      )}
    </>
  );
}

export default Domain;
