import i18next from "i18next";

interface propsType {
  tabs: {
    id: number;
    name: string;
    value: number;
    current: boolean;
    targetCountMin?: number;
    targetCountMax?: number;
  }[];
  changeTabHandler: (arg0: number) => void;
}

const ListTabs = ({ tabs, changeTabHandler }: propsType) => {
  return (
    <div className="flex">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          onClick={() => {
            changeTabHandler(tab.id);
          }}
          className={`py-2 px-6 cursor-pointer ease-out duration-300 ${
            tab.current
              ? "font-bold text-primaryBlue dark:text-white border-0 border-b-2 border-solid border-primaryBlue mb-[-1px]"
              : "text-primaryDarkGrey hover:text-primaryBlue dark:hover:text-primaryLightGrey"
          }`}
        >
          {i18next.t(tab.name)} ({tab.value})
        </div>
      ))}
    </div>
  );
};
export default ListTabs;
