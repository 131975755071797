import { useState } from "react";
import AttachmentItem from "./AttachmentItem";
import i18next from "i18next";
import MediaLibrary from "../MediaLibrary/MediaLibrary";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";
import axios from "axios";
import Template from "../../../types/models/Template";

const Attachments = ({
  template,
  isTemplateForFileCampaign = false,
}: {
  template: Template;
  isTemplateForFileCampaign?: boolean;
}) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const [attachments, setAttachments] = useState(template.attachments);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const reloadAttachmentsList = () => {
    if (!template.id || !currentTenant?.id) return;
    setHasLoaded(true);
    // const url = "/tenants/" + currentTenant?.id + "/templates/" + template.id;
    const url = `/tenants/${currentTenant?.id}/attachments?template_id=${template.id}`;
    axios
      .get(url)
      .then((response) => {
        setHasLoaded(false);
        setAttachments(response.data.data);
        setTotalCount(response.data.total);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
      })
      .catch(() => {});
  };

  const addAttachment = (
    mediaItemId: string,
    mediaItemFileName: string | undefined
  ) => {
    if (!currentTenant?.id) return;

    const url = `/tenants/${currentTenant.id}/attachments/`;
    const data = {
      channel: template.campaign_type,
      template_id: template.id,
      media_item_id: mediaItemId,
      filename: mediaItemFileName,
    };

    axios
      .post(url, data)
      .then((response) => {
        reloadAttachmentsList();
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="flex justify-start w-full">
        <MediaLibrary
          onItemSelectHandler={addAttachment}
          isAttachments={true}
        ></MediaLibrary>
      </div>
      {!hasLoaded ? (
        <>
          {attachments && attachments.length ? (
            <div
              className={`${
                isTemplateForFileCampaign ? "mt-3" : ""
              } flex flex-wrap justify-between`}
            >
              {attachments.map((item) => (
                <AttachmentItem
                  key={item.id}
                  attachment={item}
                  onChangeHandler={reloadAttachmentsList}
                  isTemplateForFileCampaign={isTemplateForFileCampaign}
                ></AttachmentItem>
              ))}
            </div>
          ) : (
            <div className="mt-4 w-full text-center">
              {i18next.t("No records found")}
            </div>
          )}
        </>
      ) : (
        <div className="loading"></div>
      )}
    </>
  );
};
export default Attachments;
