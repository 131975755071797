import Tenant from "../types/models/Tenant";
import User from "../types/models/User";
import UserCapability from "../types/models/UserCapability";

export const logout = () => ({
  type: "LOGOUT",
});

export const setToken = (token: string | null) => ({
  type: "SET_TOKEN",
  payload: token,
});

export const setCurrentUser = (user: User | null) => ({
  type: "SET_CURRENT_USER",
  payload: user,
});

export const setCurrentCapabilities = (
  capabilities: UserCapability[] | null
) => ({
  type: "SET_CURRENT_CAPABILITIES",
  payload: capabilities,
});

export const setCurrentTenant = (tenant: Tenant | null) => ({
  type: "SET_CURRENT_TENANT",
  payload: tenant,
});

export const setCurrentTenantID = (tenantID: string | null) => ({
  type: "SET_CURRENT_TENANT_ID",
  payload: tenantID,
});

export const setConnectedTenants = (tenants: Tenant[]) => ({
  type: "SET_CONNECTED_TENANTS",
  payload: tenants,
});
