import ListItem from "../components/UI/ListDisplay/ListItem";
import Button from "../components/UI/Button";
import Search from "../components/UI/Search";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import axios from "axios";
import { toast } from "react-hot-toast";
import { default as CampaignType } from "../types/models/Campaign";
import Pagination from "../components/UI/Pagination";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import ListTabs from "../components/UI/ListDisplay/ListTabs";
import ListItemsPerPage from "../components/UI/ListDisplay/ListItemsPerPage";
import Report from "../components/Reports/Report";
import ListSort from "../components/UI/ListDisplay/ListSort";
import { loadStateFromLocalStorage } from "../utils/LocalStorageState";
import ListControll from "../components/UI/ListDisplay/ListControll";
import {
  paginationDefault,
  sortingDefault,
  tabsReports,
} from "../components/UI/ListDisplay/data";

function Reports() {
  const navigate = useNavigate();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const initialLocalStorageState = loadStateFromLocalStorage();
  const currentItemsPerPage = initialLocalStorageState?.campaignsPerPage
    ? +initialLocalStorageState.campaignsPerPage
    : 10;
  const currentSortValue = initialLocalStorageState?.campaignsSorting
    ? initialLocalStorageState.campaignsSorting
    : "updated-desc";
  const [campaignsList, setCampaignList] = useState<CampaignType[]>([]);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [tabValues, setTabsValues] = useState<number[]>([0, 0]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(currentItemsPerPage);
  const [sortValue, setSortValue] = useState<string>(currentSortValue);
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentCampaignID, setCurrentCampaignID] = useState<string>("");

  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [showCopyConfirmationModal, setShowCopyConfirmationModal] =
    useState<boolean>(false);

  const tabs = tabsReports.map((item) => ({
    ...item,
    value: tabValues[item.id],
    current: currentTab === item.id ? true : false,
  }));
  const numberOfPagesInPagination = paginationDefault.map((item) => ({
    ...item,
    current: itemsPerPage === item.value ? true : false,
  }));
  const sortElements = sortingDefault.map((item) => ({
    ...item,
    current: sortValue === item.value ? true : false,
  }));

  const resetData = () => {
    setCampaignList([]);
    setCurrentPage(0);
    setLastPage(0);
    setTotalCount(0);
    setHasLoaded(false);
  };

  const remove = () => {
    if (!currentCampaignID || !currentTenant?.id) return;

    axios
      .delete(
        "/tenants/" + currentTenant?.id + "/campaigns/" + currentCampaignID
      )
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));

        fetchItems();
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  const copy = () => {
    if (!currentCampaignID || !currentTenant?.id) return;

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/campaigns/" +
      currentCampaignID +
      "/clone";
    axios
      .post(url)
      .then((response) => {
        toast(i18next.t("Record copy successfully."));

        fetchItems();
      })
      .catch(() => {
        toast(i18next.t("Record copy error."));
      });
  };
  const fetchItems = (page: number = 1) => {
    if (!currentTenant?.id) return;
    resetData();

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/campaigns?page=" +
      page +
      "&items-per-page=" +
      itemsPerPage;
    if (searchValue) url += "&search=" + searchValue;
    if (sortValue) url += "&sort=" + sortValue;
    if (currentTab === 1) url += "&archived=1";
    axios
      .get<{
        current_page: number;
        data: CampaignType[];
        last_page: number;
        per_page: number;
        total: number;
        count_all: number;
        count_archived: number;
      }>(url)
      .then((response) => {
        setCampaignList(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
        setTabsValues([response.data.count_all, response.data.count_archived]);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));

        resetData();
      })
      .finally(() => {
        if (!hasLoaded) setHasLoaded(true);
      });
  };
  const changeItemsPerPageHandler = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
  };
  const changeTabHandler = (tab: number) => {
    setCurrentTab(tab);
  };
  const changeSortHandler = (value: string) => {
    setSortValue(value);
  };
  useEffect(() => {
    if (!currentTenant?.id) return;
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, searchValue, currentTab, sortValue]);
  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          remove();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      <ConfirmationModal
        show={showCopyConfirmationModal}
        closeCallback={() => {
          setShowCopyConfirmationModal(false);
        }}
        confirmCallback={() => {
          copy();
        }}
        title={i18next.t("Copy record")}
        text={i18next.t("This action cannot be done.")}
        confirmButtonText={i18next.t("Copy")}
        confirmButtonVariant="danger"
      />

      <div className="flex flex-col w-full pt-5">
        <div className="flex gap-6">
          <Button
            onClick={() => {
              navigate("/campaigns/create");
            }}
          >
            <i className="fa fa-plus pr-2" aria-hidden="true"></i>{" "}
            {i18next.t("Add new campaign")}
          </Button>

          <Search
            placeholder={i18next.t("Search...")}
            searchHandler={setSearchValue}
          ></Search>
        </div>
        <ListControll isSearching={searchValue?.length ? true : false}>
          <ListTabs tabs={tabs} changeTabHandler={changeTabHandler}></ListTabs>
          <ListSort
            sortElements={sortElements}
            changeSortHandler={changeSortHandler}
            sortingName="campaignsSorting"
          ></ListSort>
          <ListItemsPerPage
            tabs={numberOfPagesInPagination}
            paginationName="campaignsPerPage"
            activeTab={itemsPerPage}
            changeItemsPerPageHandler={changeItemsPerPageHandler}
          ></ListItemsPerPage>
        </ListControll>

        {campaignsList.length > 0 &&
          campaignsList.map((campaign, index) => (
            <ListItem className="p-6" key={campaign.id}>
              <Report campaign={campaign}></Report>
              <Button
                onClick={() => {
                  navigate("/reports/" + campaign.id);
                }}
              >
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </Button>
            </ListItem>
          ))}
        <p>
          {!campaignsList.length && hasLoaded && i18next.t("No entries found.")}
          {!campaignsList.length && !hasLoaded && (
            <span className="loading"></span>
          )}
        </p>
        <Pagination
          currentPage={currentPage}
          lastPage={lastPage}
          totalCount={totalCount}
          nextCallback={() => {
            fetchItems(currentPage + 1);
          }}
          backCallback={() => {
            fetchItems(currentPage - 1);
          }}
        />
      </div>
    </>
  );
}

export default Reports;
