import i18next from "i18next";
import Button from "../UI/Button";

interface Props {
  isEditedAvailable: boolean;
  isEdited: boolean;
  onEditedHandler: () => void;
}
const tdClass = "w-full px-6 relative";

const TargetsListHeader = ({
  isEdited,
  isEditedAvailable,
  onEditedHandler,
}: Props) => {
  return (
    <div className="flex items-center border-b border-primaryGrey pb-4 mb-4">
      <div className={tdClass + " md:w-[10%] text-base"}>
        {i18next.t("Salutation")}
      </div>
      <div className={tdClass + " md:w-[10%] text-base"}>
        {i18next.t("Firstname")}
      </div>
      <div className={tdClass + " md:w-[10%] text-base"}>
        {i18next.t("Lastname")}
      </div>
      <div className={tdClass + " md:w-[25%] text-base"}>
        {i18next.t("Company")}
      </div>
      <div className={tdClass + " md:w-[20%] text-base"}>
        {i18next.t("E-Mail")}
      </div>
      <div className={tdClass + "  md:w-[10%] text-base"}>
        {i18next.t("Phone")}
      </div>
      <div className={tdClass + " md:w-[15%] text-base text-right"}>
        {/* targets?.length */}
        {isEditedAvailable ? (
          <Button
            className={`${
              isEdited
                ? "whitespace-nowrap ml-[-.5rem] bg-primaryGreen"
                : "dark:bg-darkModeDarkBlue bg-primaryBlue"
            } px-2 text-xs`}
            //setIsTargetsListEdited((prev) => !prev)
            onClick={() => onEditedHandler()}
          >
            {isEdited ? i18next.t("Finish editing") : i18next.t("Edit list")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export default TargetsListHeader;
