import i18next from "i18next";

interface Props {
  recievedEmails: number;
  sentEmails: number;
}

const SentEmails = ({ recievedEmails, sentEmails }: Props) => {
  return (
    <div className="basis-full flex flex-wrap p-6 border-solid border border-primaryDarkGrey rounded gap-6 items-end">
      <div>
        {i18next.t("Campaigns")}{" "}
        <div className="text-3xl font-bold">{i18next.t("Recipient")}</div>
      </div>
      <div className="ml-auto grow-0 text-primaryGrey">
        <span className="text-black dark:text-white text-lg">
          {recievedEmails}
        </span>
        /{sentEmails}
      </div>
      <div className="grow-0 text-5xl text-primaryDarkGrey">
        {sentEmails ? (recievedEmails * 100) / sentEmails : 0}%
      </div>
      <div className="basis-full h-2 bg-primaryLightGrey rounded">
        {sentEmails ? (
          <div
            className="h-2 bg-primaryDarkGrey rounded"
            style={{
              width: (recievedEmails * 100) / sentEmails + "%",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default SentEmails;
