import i18next from "i18next";
import { ChangeEventHandler } from "react";

interface PropsType {
  isCreate: boolean;
  currentState: boolean | undefined;
  onChangeHandler: ChangeEventHandler<HTMLInputElement>;
}
const TrackingPixel = ({
  isCreate,
  currentState,
  onChangeHandler,
}: PropsType) => {
  return (
    <div className="flex mb-6 align-center justify-between">
      <div>
        <input
          type="checkbox"
          checked={isCreate ? true : currentState ? currentState : false}
          name="use_tracking_pixel"
          id="use_tracking_pixel"
          onChange={onChangeHandler}
          className={`hidden target-pixel`}
        />
        <label htmlFor="use_tracking_pixel" className="flex items-center px-4">
          <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
          {i18next.t("Tracking pixel")}
        </label>
      </div>
    </div>
  );
};
export default TrackingPixel;
