import i18next from "i18next";
import ListItemsPerPage from "../UI/ListDisplay/ListItemsPerPage";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { default as TargetType } from "../../types/models/Target";
import axios from "axios";
import TargetGroup from "../../types/models/TargetGroup";
import { toast } from "react-hot-toast";
import FormErrorResponse from "../../types/FormErrorResponse";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import Pagination from "../UI/Pagination";
import TargetsListHeader from "./TargetsListHeader";
import TargetEditing from "./TargetEditing";
import Target from "./Target";
interface Props {
  itemsPerPage: number;
  setErrors: Dispatch<SetStateAction<FormErrorResponse | null>>;
  onListReloadHandler: (arg0?: number) => void;
  targets: TargetType[] | null | undefined;
  setItemsForRemoving: Dispatch<SetStateAction<string[]>>;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  totalCount: number;
  currentPage: number;
  lastPage: number;
  isCreate: boolean;
  onChangeTargetsEditStatus: (arg0: boolean) => void;
  hasLoaded: boolean;
  itemsForRemoving: string[];
  onTargetDeleteHandler: Dispatch<SetStateAction<boolean>>;
  setTargets: Dispatch<SetStateAction<TargetType[] | null | undefined>>;
  targetGroupId: string;
}
const TargetsList = ({
  itemsPerPage,
  setItemsForRemoving,
  targets,
  setErrors,
  onListReloadHandler,
  setItemsPerPage,
  totalCount,
  currentPage,
  lastPage,
  isCreate,
  onChangeTargetsEditStatus,
  itemsForRemoving,
  hasLoaded,
  onTargetDeleteHandler,
  setTargets,
  targetGroupId,
}: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const currentTenantId = currentTenant?.id;
  const [newTarget, setNewTarget] = useState<TargetType>({});
  const [isAddingNewTarget, setIsAddingNewTarget] = useState<boolean>(false);
  const [isTargetsListEdited, setIsTargetsListEdited] =
    useState<boolean>(false);
  const [targetToEdit, setTargetToEdit] = useState(false);
  const numberOfPagesInPagination = [
    { value: 10, current: itemsPerPage === 10 ? true : false },
    { value: 30, current: itemsPerPage === 30 ? true : false },
    { value: 50, current: itemsPerPage === 50 ? true : false },
  ];
  const saveTarget = () => {
    if (!currentTenantId || !targetGroupId) return;

    const url = "/tenants/" + currentTenantId + "/targets/";

    const data = {
      ...newTarget,
      target_group_ids: [targetGroupId],
    };

    let axiosHandler;
    if (targetToEdit) {
      axiosHandler = axios.patch<TargetGroup>(url + newTarget.id, data);
    } else {
      axiosHandler = axios.post<TargetGroup>(url, data);
    }

    setErrors(null);
    axiosHandler
      .then((response) => {
        toast(i18next.t("Record saved."));
        //getTargets();
        onListReloadHandler();
        resetSaveTarget();
        if (targetToEdit) setIsAddingNewTarget(false);
      })
      .catch((error) => {
        toast(i18next.t("Error saving record."));
        if (error?.response?.data) setErrors(error.response.data);
      });
  };
  const resetSaveTarget = () => {
    setNewTarget({});
  };
  const targetEditHandler = (id: string) => {
    const currTarget = targets?.find((x) => x.id === id);
    if (!currTarget) return;
    setIsAddingNewTarget(true);
    setNewTarget(currTarget);
    setTargetToEdit(true);
  };

  const selectForRemovingHandler = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setItemsForRemoving((prev) => [...prev, id]);
    } else {
      setItemsForRemoving((prev) => prev.filter((item) => item !== id));
    }
  };
  const selectingAllTargetsHandler = () => {
    const allTargetsIDs = targets?.map((target) =>
      target.id ? target.id : ""
    );
    if (allTargetsIDs) setItemsForRemoving(allTargetsIDs);
  };

  const changeItemsPerPageHandler = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
  };
  const setNewTargetData = (field: string, value: string | number) => {
    setNewTarget((prev) => ({ ...prev, [field]: value }));
  };
  const resetData = () => {
    setTargets([]);
    // setCurrentPage(0);
    // setLastPage(0);
    // setTotalCount(0);
  };

  useEffect(() => {
    resetData();
    if (isCreate) {
      setTargets([]);
    } else {
      onListReloadHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isCreate) {
      setTargets([]);
    } else {
      onListReloadHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);
  useEffect(() => {
    onListReloadHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);
  useEffect(() => {
    if (!isTargetsListEdited) setItemsForRemoving([]);
    onChangeTargetsEditStatus(isTargetsListEdited);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTargetsListEdited]);

  return (
    <>
      <div
        className={`${!totalCount ? "hidden" : ""} mt-6 mb-2
          text-primaryDarkGrey text-sm
          flex items-center justify-end blur-none`}
      >
        <ListItemsPerPage
          tabs={numberOfPagesInPagination}
          paginationName="targetsPerPage"
          activeTab={itemsPerPage}
          changeItemsPerPageHandler={changeItemsPerPageHandler}
        ></ListItemsPerPage>
        <i className="fa fa-users pl-8 pr-2"></i>
        {i18next.t("Targets")} {totalCount}
      </div>

      <div
        className={`${isCreate && "opacity-30"}
          ${totalCount ? "mb-6" : "my-6"}
          bg-white shadow-sm py-4 text-sm text-black
          dark:bg-darkModeBlue dark:text-white
          border-collapse w-full max-w-full blur-none
        `}
      >
        <TargetsListHeader
          isEdited={isTargetsListEdited}
          isEditedAvailable={targets?.length ? true : false}
          onEditedHandler={() => setIsTargetsListEdited((prev) => !prev)}
        ></TargetsListHeader>

        {isTargetsListEdited || !targets?.length ? (
          <TargetEditing
            isEdited={isAddingNewTarget}
            setIsEdited={setIsAddingNewTarget}
            editedTarget={newTarget}
            setEditedTarget={setNewTargetData}
            onSaveTarget={saveTarget}
          ></TargetEditing>
        ) : null}

        {targets?.length && !hasLoaded ? (
          <>
            {targets.map((target) => {
              return (
                <Target
                  key={target.id}
                  target={target}
                  isListEdited={isTargetsListEdited}
                  itemsForRemoving={itemsForRemoving}
                  setItemsForRemoving={setItemsForRemoving}
                  selectForRemovingHandler={selectForRemovingHandler}
                  targetEditHandler={targetEditHandler}
                  onTargetDeleteHandler={onTargetDeleteHandler}
                ></Target>
              );
            })}
            {itemsForRemoving.length ? (
              <div
                className="sticky flex items-center gap-4
                  bottom-4 left-0 right-0 m-auto
                  mt-2 p-3 px-6 max-w-fit rounded-full
                  shadow bg-white dark:bg-darkModeDarkBlue"
              >
                <div className="text-primaryDarkGrey">
                  {itemsForRemoving.length}{" "}
                  {itemsForRemoving.length > 1
                    ? i18next.t(" elements selected")
                    : i18next.t(" element selected")}
                </div>
                <div
                  className="cursor-pointer"
                  onClick={selectingAllTargetsHandler}
                >
                  {i18next.t("select all")}
                </div>
                <i
                  className="fa fa-trash-o text-xl cursor-pointer"
                  onClick={() => {
                    onTargetDeleteHandler(true);
                  }}
                ></i>
                <i
                  className="fa fa-close text-xl cursor-pointer"
                  onClick={() => {
                    setItemsForRemoving([]);
                  }}
                ></i>
              </div>
            ) : null}
          </>
        ) : (
          <div className="pb-8 pt-2 px-6">
            {hasLoaded ? (
              <div className="loading"></div>
            ) : (
              i18next.t("No recipients available")
            )}
          </div>
        )}
      </div>
      {targets?.length && targets.length > 0 && !hasLoaded ? (
        <Pagination
          className="blur-none"
          currentPage={currentPage}
          lastPage={lastPage}
          totalCount={totalCount}
          nextCallback={() => {
            onListReloadHandler(currentPage + 1);
          }}
          backCallback={() => {
            onListReloadHandler(currentPage - 1);
          }}
        />
      ) : null}
    </>
  );
};
export default TargetsList;
