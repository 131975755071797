import toast, { useToaster } from "react-hot-toast";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import i18next from "i18next";

const Toaster = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  return (
    <ToastContainer position="top-end" className="p-4">
      {toasts
        .filter((toastObj) => toastObj.visible)
        .map((toastObj) => (
          <Toast
            onMouseEnter={startPause}
            onMouseLeave={endPause}
            onClose={() => {
              toast.dismiss(toastObj.id);
            }}
            key={toastObj.id}
            {...toastObj.ariaProps}
          >
            <Toast.Header>
              <strong className="me-auto">{i18next.t("Notification")}</strong>
              {/* <small className="text-muted">just now</small> */}
            </Toast.Header>
            <Toast.Body>
              {typeof toastObj.message === "function"
                ? toastObj.message(toastObj)
                : toastObj.message}
            </Toast.Body>
          </Toast>
        ))}
    </ToastContainer>
  );
};

export default Toaster;
