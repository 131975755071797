import { useSelector } from "react-redux";
import Attachment from "../../types/models/Attachment";
import ReducerState from "../../types/ReducerState";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import Button from "../UI/Button";

interface Props {
  attachment: Attachment;
}
interface TestStatusType {
  responseFound: boolean;
  lastReceived: null | string;
}
interface ResponseType {
  response_found: boolean;
  last_received: null | string;
}
const defaultTestStatus: TestStatusType = {
  responseFound: false,
  lastReceived: null,
};
const dateToString = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const tmpMonth = dateObj.getMonth() + 1;
  const month = tmpMonth > 9 ? tmpMonth : "0" + tmpMonth;
  const day =
    dateObj.getDate() > 9 ? dateObj.getDate() : "0" + dateObj.getDate();

  let string = day + "." + month + "." + year;
  return string;
};
const File = ({ attachment }: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const { id } = useParams();

  const [testStatus, setTestStatus] = useState(defaultTestStatus);
  const checkTestReceived = () => {
    if (!currentTenant?.id || id) return;

    var url = `/tenants/${currentTenant?.id}/campaigns/${id}/attachments/${attachment.id}/check-test-received`;
    axios
      .get<ResponseType>(url)
      .then((response) => {
        setTestStatus({
          responseFound: response.data.response_found,
          lastReceived: response.data.last_received,
        });
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };
  useEffect(() => {
    checkTestReceived();

    const intervalId = setInterval(() => {
      checkTestReceived();
    }, 10000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const download = (withTest = false) => {
    if (!currentTenant?.id) return;
    const path = withTest ? "-test" : "";
    var url = `/tenants/${currentTenant?.id}/campaigns/${id}/attachments/${attachment.id}/download${path}`;
    axios
      .get(url)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          attachment.filename ? attachment.filename : "Filename"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };
  return (
    <div className="flex items-center justify-between mb-3 px-6">
      <div className="w-1/2 text-wrap">
        <span className="font-bold ">{attachment.filename}</span>

        {testStatus.responseFound ? (
          testStatus.lastReceived ? (
            <span>
              ({i18next.t("the last test was on")}{" "}
              {dateToString(testStatus.lastReceived)}
              <span className="fa fa-check text-primaryGreen ml-2"></span>)
            </span>
          ) : (
            <span>({i18next.t("the last test was not processed")})</span>
          )
        ) : null}
        <span></span>
      </div>
      <div className="flex w-1/2 justify-end gap-2 items-stretch">
        <Button
          className="cursor-pointer bg-transparent text-primaryBlue border
            px-10 border-primaryBlue border-solid"
          onClick={() => {
            download(true);
          }}
        >
          Test
        </Button>
        <Button
          className="cursor-pointer"
          onClick={() => {
            download();
          }}
        >
          Download
        </Button>
      </div>
    </div>
  );
};
export default File;
