import i18next from "i18next";
import { default as CampaignType } from "../../types/models/Campaign";
import { getEnumKey, getEnumValue } from "../../utils/Enum";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import axios from "axios";
import DesignCreatedForMessage from "./DesignCreatedForMessage";
import DesignCreatedForUSB from "./DesignCreatedForUSB";

const Campaign = ({ campaign }: { campaign?: CampaignType }) => {
  const [imgURL, setImgURL] = useState("");
  useEffect(() => {
    fetchImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  if (!campaign) return null;
  const currCampaignStatus = getEnumValue(
    "CampaignStatus",
    campaign.status !== undefined ? campaign.status : ""
  );
  const campaignStyle =
    currCampaignStatus === "draft"
      ? "bg-primaryBlue dark:bg-darkModeBlue"
      : currCampaignStatus === "scheduled"
      ? "bg-primaryDarkGrey"
      : currCampaignStatus === "sending"
      ? "bg-primaryGreen"
      : currCampaignStatus === "paused"
      ? "bg-primaryOrange"
      : currCampaignStatus === "awaitingVictimInteraction"
      ? "bg-primaryLightBlue"
      : currCampaignStatus === "aborted"
      ? "bg-primaryRed"
      : "bg-black";

  const round = (n: number | undefined) => {
    return Math.round(n ? n / 10 : 0) * 10;
  };
  const campaignPercentage =
    currCampaignStatus === "draft"
      ? round(campaign.edit_completion_percent)
      : round(campaign.send_completion_percent);

  const dateToString = (date: string) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const tmpMonth = dateObj.getMonth() + 1;
    const month = tmpMonth > 9 ? tmpMonth : "0" + tmpMonth;
    const day =
      dateObj.getDate() > 9 ? dateObj.getDate() : "0" + dateObj.getDate();

    let string = day + "." + month + "." + year;
    return string;
  };

  const fetchImg = () => {
    if (!currentTenant?.id || !campaign?.template?.id) return;
    setHasLoaded(false);

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/templates/" +
      campaign?.template.id +
      "/template-preview.jpg";
    axios
      .get<Blob>(url, { responseType: "blob" })
      .then((response) => {
        let imageURL = URL.createObjectURL(response.data);
        setImgURL(imageURL);
      })
      .catch(() => {})
      .finally(() => {
        if (!hasLoaded) setHasLoaded(true);
      });
  };
  return (
    <div
      className={`${
        campaign.is_archived && "opacity-70"
      }  flex flex-col self-stretch lg:self-auto lg:flex-row lg:w-[75%]`}
    >
      <img
        src={imgURL ? imgURL : "#"}
        alt=""
        className="w-full object-contain lg:w-[20%] self-stretch"
      />

      <div className="text-sm px-4 lg:p-6 lg:w-[30%]">
        <h4 className="text-black pt-4 lg:pt-0 dark:text-white text-3xl lg:text-lg">
          {campaign.name}
        </h4>
        {campaign.updated_at && (
          <div className="inline lg:block lg:text-xs">
            {i18next.t("Last edited:")} {dateToString(campaign.updated_at)}
          </div>
        )}
        <div className="inline pl-2 lg:pl-0 lg:block lg:text-xs">
          {i18next.t("By")}{" "}
          {campaign.creator ? campaign.creator.name : "Max Mustermann"}
        </div>
        {campaign.type !== getEnumKey("CampaignType", "file") ? (
          <div className="font-bold mt-1 lg:mt-4 lg:mb-0">
            <i className="fa fa-users text-lg pr-1" aria-hidden="true"></i>{" "}
            {i18next.t("Recipient")} {campaign.target_count}
          </div>
        ) : null}

        <span
          className={`
            text-xl lg:text-sm text-white
            px-4 py-1 rounded-xl
            absolute top-0 mt-[-0.8rem]
            ${campaignStyle}
            [&>i]:pr-4`}
        >
          {currCampaignStatus === "draft" && (
            <>
              <i className="fa fa-flag-o" aria-hidden="true"></i>
              {i18next.t("Draft")}
            </>
          )}
          {currCampaignStatus === "scheduled" && (
            <>
              <i className="fa fa-clock-o" aria-hidden="true"></i>
              {i18next.t("Scheduled")}
            </>
          )}
          {currCampaignStatus === "sending" && (
            <>
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
              {i18next.t("Sending")}
            </>
          )}
          {currCampaignStatus === "paused" && (
            <>
              <i className="fa fa-pause" aria-hidden="true"></i>
              {i18next.t("Paused")}
            </>
          )}
          {currCampaignStatus === "awaitingVictimInteraction" && (
            <>
              <i className="fa fa-check" aria-hidden="true"></i>
              {i18next.t("Sent")}
            </>
          )}
          {currCampaignStatus === "aborted" && (
            <>
              <i className="fa fa-ban" aria-hidden="true"></i>
              {i18next.t("Aborted")}
            </>
          )}
        </span>
      </div>
      {campaign.type !== getEnumKey("CampaignType", "file") ? (
        <div className="self-stretch p-6 pt-0 lg:px-0 lg:py-4 lg:self-end lg:ml-6 lg:w-[50%]">
          <div className="flex justify-between items-center lg:w-[350px] ">
            {currCampaignStatus === "draft" ? (
              <div className="font-bold">{i18next.t("Processing status")}</div>
            ) : (
              <div className="font-bold">{i18next.t("Shipping status")}</div>
            )}
            {campaign.schedule_datetime &&
            campaign.interactions_possible_until &&
            currCampaignStatus !== "draft" ? (
              <div className="text-xs font-bold">
                {dateToString(campaign.schedule_datetime)} -{" "}
                {dateToString(campaign.interactions_possible_until)}
              </div>
            ) : null}
          </div>
          <div className="mb-6 text-white text-center">
            <div className="bg-primaryLightGrey rounded h-10 lg:h-5 w-full lg:w-[350px] relative block">
              <div
                className={`${campaignStyle} block rounded-sm h-10 lg:h-5 w-[${
                  campaignPercentage !== null ? campaignPercentage : 0
                }%]`}
              ></div>
              <span
                className={`${
                  campaignPercentage !== undefined && campaignPercentage < 46
                    ? "text-primaryBlue"
                    : ""
                }
                absolute top-2 lg:top-0 left-0 m-auto right-0
              `}
              >
                {campaignPercentage !== null ? campaignPercentage : 0}%
              </span>
            </div>
          </div>
          <DesignCreatedForMessage campaign={campaign} />
        </div>
      ) : (
        <div className="self-stretch p-6 pt-0 lg:px-0 lg:py-4 lg:self-center lg:ml-6 lg:w-[50%]">
          <DesignCreatedForUSB campaign={campaign} />
        </div>
      )}
    </div>
  );
};
export default Campaign;
