import i18next from "i18next";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import { default as UserType } from "../types/models/User";
import UserCapability from "../types/models/UserCapability";
import { getEnumKey, getEnumValue } from "../utils/Enum";
import Button from "../components/UI/Button";
import Campaign from "../types/models/Campaign";
import Wrapper from "../components/UI/Wrapper";
import Pagination from "../components/UI/Pagination";
import Search from "../components/UI/Search";
import ListSort from "../components/UI/ListDisplay/ListSort";
import { sortingDefault } from "../components/UI/ListDisplay/data";
import { loadStateFromLocalStorage } from "../utils/LocalStorageState";
import { Link } from "react-router-dom";

const dateToString = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const tmpMonth = dateObj.getMonth() + 1;
  const month = tmpMonth > 9 ? tmpMonth : "0" + tmpMonth;
  const day =
    dateObj.getDate() > 9 ? dateObj.getDate() : "0" + dateObj.getDate();
  // const hour =
  //   dateObj.getHours() > 9 ? dateObj.getHours() : "0" + dateObj.getHours();
  // const minute =
  //   dateObj.getMinutes() > 9
  //     ? dateObj.getMinutes()
  //     : "0" + dateObj.getMinutes();
  let string = day + "." + month + "." + year;
  return string;
};

const initialLocalStorageState = loadStateFromLocalStorage();
const currentSortValue = initialLocalStorageState?.campaignsSorting
  ? initialLocalStorageState.campaignsSorting
  : "updated-desc";

function User({ isCreate }: { isCreate: boolean }) {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [user, setUser] = useState<UserType>();
  const [userCapabilities, setUserCapabilities] = useState<any>([]);
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortValue, setSortValue] = useState<string>(currentSortValue);
  const [isAddingCapability, setIsAddingCapability] = useState<boolean>(false);
  const [isNewCapabilityFilled, setIsNewCapabilityFilled] =
    useState<boolean>(false);
  const [newCapability, setNewCapability] = useState<{
    campaign: string | null;
    capabilityType: number | null;
  }>({ campaign: null, capabilityType: null });
  const [capabilityForRemoving, setCapabilityForRemoving] = useState<
    string | null
  >(null);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const [campaignsLastPage, setCampaignsLastPage] = useState<number>(0);
  const [campaignsCurrentPage, setCampaignsCurrentPage] = useState<number>(0);
  const [campaignsTotalCount, setCampaignsTotalCount] = useState<number>(0);

  const sortElements = sortingDefault.map((item) => ({
    ...item,
    current: sortValue === item.value ? true : false,
  }));

  const nonCampaignCapabilities = [
    getEnumKey("UserCapabilityType", "all"),
    getEnumKey("UserCapabilityType", "manageTenant"),
    getEnumKey("UserCapabilityType", "viewTenantReports"),
    getEnumKey("UserCapabilityType", "viewDeanonymizedData"),
    getEnumKey("UserCapabilityType", "accessTenantPlatform"),
  ];

  const fetchUserCapabilities = (page: number = 1) => {
    if (isCreate || !id || !currentTenant?.id) return;
    setHasLoaded(false);
    axios
      .get<{
        user: UserType;
        user_capabilities: UserCapability[];
      }>(
        `/tenants/${currentTenant?.id}/users/${id}/user-capabilities/?page=${page}`
      )
      .then((response) => {
        setUserCapabilities(response.data.user_capabilities);
        setUser(response.data.user);
        setHasLoaded(true);
      })
      .catch(() => {
        toast(i18next.t("Error loading page"));
        navigate("/admin-dashboard");
      });
  };

  const fetchCampaignsList = (page: number = 1) => {
    if (isCreate || !id || !currentTenant?.id) return;
    let url = `/tenants/${currentTenant?.id}/campaigns/?page=${page}`;
    if (searchValue) url += `&search=${searchValue}`;
    if (sortValue) url += `&sort=${sortValue}`;

    axios
      .get<{
        current_page: number;
        data: Campaign[];
        last_page: number;
        per_page: number;
        total: number;
      }>(url)
      .then((response) => {
        setCampaignsList(response.data.data);
        setCampaignsCurrentPage(response.data.current_page);
        setCampaignsLastPage(response.data.last_page);
        setCampaignsTotalCount(response.data.total);
        // fetchUser(response.data.data[0].user_id);
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };

  useEffect(() => {
    fetchUserCapabilities();
    fetchCampaignsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSortHandler = (value: string) => {
    setSortValue(value);
  };

  useEffect(() => {
    fetchCampaignsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, sortValue]);

  useEffect(() => {
    if (
      newCapability.capabilityType !== null &&
      nonCampaignCapabilities.includes(newCapability.capabilityType)
    ) {
      setIsNewCapabilityFilled(true);
    } else if (
      newCapability.capabilityType !== null &&
      newCapability.campaign !== null
    ) {
      setIsNewCapabilityFilled(true);
    } else {
      setIsNewCapabilityFilled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCapability]);

  const onCampaignSelect = (id: string | undefined) => {
    if (!id) return;
    setNewCapability((prev) => {
      return { ...prev, campaign: id };
    });
  };
  const onCapabilitySelect = (type: string) => {
    setNewCapability((prev) => {
      if (prev.campaign !== null && nonCampaignCapabilities.includes(+type)) {
        return { campaign: null, capabilityType: +type };
      } else if (type !== undefined) {
        return { ...prev, capabilityType: +type };
      } else {
        return { campaign: null, capabilityType: null };
      }
    });
    if (
      type !== null &&
      type !== undefined &&
      nonCampaignCapabilities.includes(+type)
    ) {
      setIsNewCapabilityFilled(true);
    } else {
      setIsNewCapabilityFilled(false);
    }
  };

  const createCapability = () => {
    let url = `/tenants/${currentTenant?.id}/users/${id}/user-capabilities`;
    axios
      .post(url, {
        campaign_id: newCapability.campaign,
        capability_type: newCapability.capabilityType,
      })
      .then((response) => {
        setIsAddingCapability(false);
        setNewCapability({ campaign: null, capabilityType: null });
        fetchUserCapabilities();
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };

  const deleteCapability = () => {
    let url = `/tenants/${currentTenant?.id}/users/${id}/user-capabilities/${capabilityForRemoving}`;
    axios
      .delete(url)
      .then((response) => {
        fetchUserCapabilities();
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  const onRemoveClick = (id: string) => {
    setCapabilityForRemoving(id);
    setShowDeletionConfirmationModal(true);
  };

  const onCancelAddingCapability = () => {
    setIsAddingCapability(false);
    setNewCapability({ campaign: null, capabilityType: null });
  };

  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          deleteCapability();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      {hasLoaded || isCreate ? (
        <>
          <h3 className="mb-5 dark:text-white">
            {i18next.t("User")}: {user && user.name} ({user && user.email})
            {/* {userCapabilities[0].user_id}  */}
          </h3>
          {isAddingCapability ? (
            <>
              <div className="flex gap-4 my-4">
                {isNewCapabilityFilled && (
                  <Button
                    onClick={createCapability}
                    className="bg-primaryGreen"
                  >
                    {i18next.t("Add new capability")}
                  </Button>
                )}

                <Button
                  onClick={onCancelAddingCapability}
                  className="bg-primaryRed"
                >
                  {i18next.t("Cancel")}
                </Button>
              </div>

              <Wrapper className="flex gap-6 items-center mb-4">
                <h5 className="m-0">{i18next.t("Select capability type")}</h5>
                <select
                  className="text-right border-solid border-0 border-primaryGrey focus:border-primaryDarkGrey border-b focus:outline-none"
                  name=""
                  id=""
                  onChange={(e) => onCapabilitySelect(e.target.value)}
                >
                  <option>{i18next.t("Capability type")}</option>
                  {/* <option value={getEnumKey("UserCapabilityType", "all")}>
                    {i18next.t("All Access")}
                  </option> */}
                  <option
                    value={getEnumKey("UserCapabilityType", "manageTenant")}
                  >
                    {i18next.t("Manage Tenant")}
                  </option>
                  <option
                    value={getEnumKey(
                      "UserCapabilityType",
                      "viewTenantReports"
                    )}
                  >
                    {i18next.t("View Tenant Reports")}
                  </option>
                  <option
                    value={getEnumKey("UserCapabilityType", "manageCampaign")}
                  >
                    {i18next.t("Manage Campaign")}
                  </option>
                  {/* <option
                    value={getEnumKey("UserCapabilityType", "sendCampaign")}
                  >
                    {i18next.t("Send Campaign")}
                  </option> */}
                  <option
                    value={getEnumKey(
                      "UserCapabilityType",
                      "viewCampaignReports"
                    )}
                  >
                    {i18next.t("View Campaign Reports")}
                  </option>
                  <option
                    value={getEnumKey(
                      "UserCapabilityType",
                      "viewDeanonymizedData"
                    )}
                  >
                    {i18next.t("View Deanonymized Data")}{" "}
                  </option>
                  {/* <option
                    value={getEnumKey(
                      "UserCapabilityType",
                      "accessTenantPlatform"
                    )}
                  >
                    {i18next.t("Access Tenant Platform")}
                  </option> */}
                </select>
              </Wrapper>
              {newCapability.capabilityType &&
              !nonCampaignCapabilities.includes(
                newCapability.capabilityType
              ) ? (
                <>
                  <div className="mt-2 mb-4 flex justify-between items-center">
                    <h5>{i18next.t("Choose campaign")}</h5>
                    <div className="flex items-center">
                      <ListSort
                        sortElements={sortElements}
                        changeSortHandler={changeSortHandler}
                        sortingName="campaignsSorting"
                      ></ListSort>
                      <Search
                        placeholder={i18next.t("Search...")}
                        searchHandler={setSearchValue}
                      ></Search>
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-4 items-stretch">
                    {campaignsList &&
                      campaignsList.length &&
                      campaignsList.map((campaign) => {
                        return (
                          <div
                            key={campaign.id}
                            className="flex basis-[calc(20%-.8rem)] self-stretch"
                          >
                            <input
                              type="radio"
                              id={campaign.id}
                              name="templates"
                              className={`hidden radio-input templates-list`}
                              data-groupname={campaign.name}
                              onChange={() => onCampaignSelect(campaign.id)}
                              checked={
                                newCapability.campaign === campaign.id
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor={campaign.id}
                              className="w-full cursor-pointer
                                border border-white dark:border-darkModeLighterBlue
                                rounded"
                            >
                              <Wrapper className="flex text-primaryDarkGrey items-start gap-3 px-3 py-4">
                                <div className="radio h-4 w-4 border rounded-full flex mt-2"></div>
                                <div className="flex flex-col">
                                  <h4 className="text-black pt-4 lg:pt-0 dark:text-white text-3xl lg:text-lg">
                                    {campaign.name}
                                  </h4>
                                  {campaign.updated_at && (
                                    <div className="inline lg:block lg:text-xs">
                                      {i18next.t("Last edited:")}{" "}
                                      {dateToString(campaign.updated_at)}
                                    </div>
                                  )}
                                  <div className="inline pl-2 lg:pl-0 lg:block lg:text-xs">
                                    {i18next.t("By")}{" "}
                                    {campaign.creator
                                      ? campaign.creator.name
                                      : "Max Mustermann"}
                                  </div>
                                  {campaign.type !==
                                  getEnumKey("CampaignType", "file") ? (
                                    <div className="font-bold mt-1 text-sm lg:mt-2 lg:mb-0">
                                      <i
                                        className="fa fa-users text pr-1"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {i18next.t("Recipient")}{" "}
                                      {campaign.target_count}
                                    </div>
                                  ) : null}
                                </div>
                              </Wrapper>
                            </label>
                          </div>
                        );
                      })}
                  </div>
                  {campaignsList && campaignsList.length ? (
                    <Pagination
                      currentPage={campaignsCurrentPage}
                      lastPage={campaignsLastPage}
                      totalCount={campaignsTotalCount}
                      nextCallback={() => {
                        fetchCampaignsList(campaignsCurrentPage + 1);
                      }}
                      backCallback={() => {
                        fetchCampaignsList(campaignsCurrentPage - 1);
                      }}
                    />
                  ) : null}
                </>
              ) : null}
            </>
          ) : (
            <>
              <Button onClick={() => setIsAddingCapability(true)}>
                <span className="fa fa-plus mr-2"></span>
                {i18next.t("Add new capability")}
              </Button>
              <div className="mt-4">
                <div className="mt-4 flex flex-wrap gap-4">
                  {userCapabilities &&
                    userCapabilities.length > 0 &&
                    userCapabilities.map((userCapability: any) => (
                      <Wrapper
                        key={userCapability.id}
                        className="basis-[calc(25%-1rem)] gap-1 items-left flex flex-col"
                      >
                        <div className="flex justify-between items-center">
                          <span className="font-bold">
                            {getEnumValue(
                              "UserCapabilityType",
                              userCapability.capability_type
                            ) === "all" && i18next.t("All Access")}
                            {getEnumValue(
                              "UserCapabilityType",
                              userCapability.capability_type
                            ) === "manageTenant" && i18next.t("Manage Tenant")}
                            {getEnumValue(
                              "UserCapabilityType",
                              userCapability.capability_type
                            ) === "viewTenantReports" &&
                              i18next.t("View Tenant Reports")}
                            {getEnumValue(
                              "UserCapabilityType",
                              userCapability.capability_type
                            ) === "manageCampaign" &&
                              i18next.t("Manage Campaign")}
                            {getEnumValue(
                              "UserCapabilityType",
                              userCapability.capability_type
                            ) === "sendCampaign" && i18next.t("Send Campaign")}
                            {getEnumValue(
                              "UserCapabilityType",
                              userCapability.capability_type
                            ) === "viewCampaignReports" &&
                              i18next.t("View Campaign Reports")}
                            {getEnumValue(
                              "UserCapabilityType",
                              userCapability.capability_type
                            ) === "viewDeanonymizedData" &&
                              i18next.t("View Deanonymized Data")}
                            {getEnumValue(
                              "UserCapabilityType",
                              userCapability.capability_type
                            ) === "accessTenantPlatform" &&
                              i18next.t("Access Tenant Platform")}
                          </span>
                          <span
                            className="fa fa-trash-o cursor-pointer"
                            onClick={() => onRemoveClick(userCapability.id)}
                          ></span>
                        </div>
                        {userCapability.campaign && (
                          <span className="flex gap-2">
                            {i18next.t("For")}:
                            <Link
                              to={`/campaigns/${userCapability.campaign.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-black font-bold no-underline hover:underline"
                            >
                              {userCapability.campaign.name}
                            </Link>
                          </span>
                        )}
                        <div className="text-sm text-primaryDarkGrey mt-auto">
                          {i18next.t("Created at")}:{" "}
                          {dateToString(userCapability.created_at)}
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="loading"></div>
      )}
    </>
  );
}

export default User;
