import i18next from "i18next";
import Template from "../../types/models/Template";

const DesignCreatedForMessage = ({ template }: { template: Template }) => {
  return (
    <>
      <div
        className={`${template.email_content && "text-black dark:text-white"}`}
      >
        <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
        {i18next.t("E-mail")}
        {template.email_content ? (
          <>
            {" "}
            <i className="fa fa-check text-primaryGreen" aria-hidden="true"></i>
          </>
        ) : null}
      </div>
      <div
        className={`${template.sms_content && "text-black dark:text-white"}`}
      >
        <i className="fa fa-mobile" aria-hidden="true"></i> {i18next.t("SMS")}
        {template.sms_content ? (
          <>
            {" "}
            <i className="fa fa-check text-primaryGreen" aria-hidden="true"></i>
          </>
        ) : null}
      </div>
      <div
        className={`${
          template.phishing_page_content && "text-black dark:text-white"
        }`}
      >
        <i className="fa fa-television" aria-hidden="true"></i>{" "}
        {i18next.t("Phishing page with form")}
        {template.phishing_page_content ? (
          <>
            {" "}
            <i className="fa fa-check text-primaryGreen" aria-hidden="true"></i>
          </>
        ) : null}
      </div>
      <div
        className={`${
          template.reproof_page_content && "text-black dark:text-white"
        }`}
      >
        <i className="fa fa-television" aria-hidden="true"></i>{" "}
        {i18next.t("Instructions after page")}
        {template.reproof_page_content ? (
          <>
            {" "}
            <i className="fa fa-check text-primaryGreen" aria-hidden="true"></i>
          </>
        ) : null}
      </div>
    </>
  );
};
export default DesignCreatedForMessage;
