import i18next from "i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import ReducerState from "../types/ReducerState";
import FormErrorResponse from "../types/FormErrorResponse";
import { default as CampaignType } from "../types/models/Campaign";
import StepCommon from "../components/Campaign/StepCommon";
import StepTargetGroups from "../components/Campaign/StepTargetGroups";
import StepTemplate from "../components/Campaign/StepTemplate";
import StepRules from "../components/Campaign/StepRules";
import { default as NotificationCampaignNavigation } from "../components/Campaign/Navigation";
import { default as FileCampaignNavigation } from "../components/Campaign/DateiCampaign/Navigation";
import Pagination from "../components/Campaign/Pagination";
import Summary from "../components/Campaign/Summary";
import { getEnumKey, getEnumValue } from "../utils/Enum";
import SentCampaignInfo from "../components/Campaign/SentCampaignInfo";
import TestCampaign from "../components/Campaign/TestCampaign";
import ChoosingCampaignType from "../components/Campaign/ChoosingCampaignType";
import StepSettingsForFileCampaign from "../components/Campaign/StepSettingsForFileCampaign";

const Campaign = ({ isCreate }: { isCreate: boolean }) => {
  const { id } = useParams();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [hasLoaded, setHasLoaded] = useState<boolean>(isCreate ? true : false);
  const [errors, setErrors] = useState<FormErrorResponse | null>(null);
  const [campaign, setCampaign] = useState<CampaignType>({
    name: "",
  });
  const [campaignType, setCampaignType] = useState<number | undefined>(
    undefined
  );
  // hide the side column if the template or target group is edited inside the campaign
  const [isAsideRightVisible, setIsAsideRightVisible] = useState(true);

  const currCampaignStatus = getEnumValue(
    "CampaignStatus",
    campaign.status !== undefined ? campaign.status : ""
  );
  const isEdited = currCampaignStatus === "draft" ? true : false;

  const setNewCampaignData = (field: string, value: string | boolean) => {
    setCampaign((prev) => ({ ...prev, [field]: value }));
  };
  const setFetchErrors = (errors: FormErrorResponse) => {
    setErrors(errors);
  };
  const goToNextStepHandler = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const goToPrevStepHandler = () => {
    setCurrentStep((prev) => prev - 1);
  };
  const resetData = () => {
    setCampaign({
      name: "",
    });
    setHasLoaded(false);
  };
  const fetchCampagne = () => {
    if (isCreate || !id || !currentTenant?.id) return;

    resetData();
    axios
      .get<CampaignType>("/tenants/" + currentTenant?.id + "/campaigns/" + id)
      .then((response) => {
        setCampaign(response.data);
        setHasLoaded(true);
        setCampaignType(response.data.type);
      })
      .catch(() => {
        toast(i18next.t("Error loading page"));

        navigate("/campaigns/");
      })
      .finally(() => {
        if (!hasLoaded) setHasLoaded(true);
      });
  };
  // useEffect(() => {
  //   fetchCampagne();
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    if (isCreate) {
      setCampaign({ name: "" });
      setCampaignType(undefined);
      setCurrentStep(1);
    } else {
      fetchCampagne();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);

  // if errors is not empty, go to step 1 (error in name)
  // or to step 3 or 4 (all other errors)
  useEffect(() => {
    document.body.scrollTop = 0;

    const errorInName = errors?.["name"];
    if (errorInName && errorInName.length) setCurrentStep(1);
    if (
      !errorInName &&
      !isCreate &&
      campaignType === getEnumKey("CampaignType", "message")
    )
      setCurrentStep(4);
    if (
      !errorInName &&
      !isCreate &&
      campaignType === getEnumKey("CampaignType", "file")
    )
      setCurrentStep(3);
    if (isCreate) setCurrentStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isCreate]);
  useEffect(() => {
    setCampaign((prev) => {
      return { ...prev, type: campaignType };
    });
  }, [campaignType]);
  return (
    <>
      {hasLoaded || isCreate ? (
        campaignType !== undefined ? (
          <>
            {isEdited || isCreate ? (
              <div className="flex mt-12 justify-between w-[60%] min-w-[600px] items-center">
                {campaignType ? (
                  <FileCampaignNavigation
                    currentStep={currentStep}
                    campaignInfo={campaign}
                    setCurrentStepHandler={setCurrentStep}
                  />
                ) : (
                  <NotificationCampaignNavigation
                    currentStep={currentStep}
                    campaignInfo={campaign}
                    setCurrentStepHandler={setCurrentStep}
                  />
                )}
              </div>
            ) : null}

            <div className="flex">
              <div
                className={`${
                  isAsideRightVisible ? " w-[60%] min-w-[600px]" : "w-full"
                } mt-10`}
              >
                {isEdited || isCreate ? (
                  <>
                    {currentStep === 1 && (
                      <StepCommon
                        currentCampaignName={campaign.name}
                        currentCampaignDescription={
                          campaign.description ? campaign.description : ""
                        }
                        onInputChangeHandler={setNewCampaignData}
                        errors={errors}
                      ></StepCommon>
                    )}
                    {currentStep === 2 ? (
                      campaignType === getEnumKey("CampaignType", "file") ? (
                        <StepTemplate
                          currentTemplate={
                            campaign.template?.id ? campaign.template.id : ""
                          }
                          onInputChangeHandler={setNewCampaignData}
                          asideVisabilityHandler={setIsAsideRightVisible}
                          isCampaignTypeMessage={false}
                        ></StepTemplate>
                      ) : (
                        <StepTargetGroups
                          currentTargetGroup={
                            campaign.target_group?.id
                              ? campaign.target_group.id
                              : ""
                          }
                          onInputChangeHandler={setNewCampaignData}
                          asideVisabilityHandler={setIsAsideRightVisible}
                        ></StepTargetGroups>
                      )
                    ) : null}
                    {currentStep === 3 ? (
                      campaignType === getEnumKey("CampaignType", "file") ? (
                        <StepSettingsForFileCampaign
                          isCreate={isCreate}
                          currentState={campaign}
                          onInputChangeHandler={setNewCampaignData}
                          errors={errors}
                        />
                      ) : (
                        <StepTemplate
                          currentTemplate={
                            campaign.template?.id ? campaign.template.id : ""
                          }
                          onInputChangeHandler={setNewCampaignData}
                          asideVisabilityHandler={setIsAsideRightVisible}
                        ></StepTemplate>
                      )
                    ) : null}
                    {currentStep === 4 && (
                      <StepRules
                        isCreate={isCreate}
                        currentState={campaign}
                        onInputChangeHandler={setNewCampaignData}
                        errors={errors}
                      ></StepRules>
                    )}
                  </>
                ) : (
                  <>
                    <SentCampaignInfo campaign={campaign}></SentCampaignInfo>
                  </>
                )}

                {isEdited || isCreate ? (
                  <Pagination
                    currentStep={currentStep}
                    lastStep={campaignType ? 3 : 4}
                    goToNextStep={goToNextStepHandler}
                    goToPrevStep={goToPrevStepHandler}
                  ></Pagination>
                ) : null}
              </div>

              {isAsideRightVisible ? (
                <div className="flex flex-col mb-6">
                  <Summary
                    summary={campaign}
                    isCreate={isCreate}
                    setCampaign={setCampaign}
                    setErrors={setFetchErrors}
                    isNotificationCampaign={
                      campaignType === getEnumKey("CampaignType", "message")
                    }
                  ></Summary>

                  {campaign.can_be_started &&
                  campaignType === getEnumKey("CampaignType", "message") ? (
                    <TestCampaign
                      currentCampaignID={campaign.id}
                      enabledChannels={{
                        email: campaign.target_channel_email_enabled,
                        sms: campaign.target_channel_sms_enabled,
                        whatsapp: campaign.target_channel_whatsapp_enabled,
                      }}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <ChoosingCampaignType setCampaignType={setCampaignType} />
        )
      ) : null}
      {!hasLoaded ? <div className="loading max-w-[660px]"></div> : null}
    </>
  );
};
export default Campaign;
