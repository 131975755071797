import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import i18next from "i18next";
import GenericCallback from "../../types/GenericCallback";

interface propsType {
  className?: string;
  currentPage: number;
  lastPage: number;
  totalCount: number;
  backCallback: GenericCallback;
  nextCallback: GenericCallback;
}

const Pagination = ({
  className,
  currentPage,
  lastPage,
  totalCount,
  backCallback,
  nextCallback,
}: propsType) => {
  return (
    <>
      {currentPage > 0 && lastPage > 0 && (
        <div
          className={`mt-4 dark:text-primaryGrey ${className ? className : ""}`}
        >
          <p>
            {i18next.t("Page")} {currentPage} {i18next.t("of")} {lastPage}.{" "}
            {i18next.t("Total entries:")} {totalCount}
          </p>
          {lastPage > 1 && (
            <nav className="mb-4">
              <ButtonGroup aria-label="Basic example">
                <Button
                  onClick={() => {
                    backCallback();
                  }}
                  className={
                    currentPage < 2
                      ? "disabled bg-primaryLightBlue border-primaryLightBlue"
                      : "bg-primaryBlue dark:bg-darkModeBlue border-primaryLightBlue hover:bg-white hover:text-primaryBlue"
                  }
                >
                  {i18next.t("Back")}
                </Button>
                <Button
                  onClick={() => {
                    nextCallback();
                  }}
                  className={
                    currentPage >= lastPage
                      ? "disabled bg-primaryLightBlue border-primaryLightBlue"
                      : "bg-primaryBlue border-primaryLightBlue hover:bg-white hover:text-primaryBlue"
                  }
                >
                  {i18next.t("Next")}
                </Button>
              </ButtonGroup>
            </nav>
          )}
        </div>
      )}
    </>
  );
};

export default Pagination;
