import i18next from "i18next";
import ConfirmationModal from "../UI/ConfirmationModal";
import { Dispatch, KeyboardEvent, SetStateAction, useState } from "react";

interface Props {
  isModalVisible: boolean;
  onCloseHandler: Dispatch<SetStateAction<boolean>>;
  setSearchByDomainValue: Dispatch<SetStateAction<string | undefined>>;
}

const SearchByDomainConfirmationModal = ({
  isModalVisible,
  onCloseHandler,
  setSearchByDomainValue,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>();
  const keyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchValue(value);
    if (e.key === "Enter" || !value.length) {
      e.preventDefault();
      setSearchByDomainValue(value);
    }
  };

  return (
    <ConfirmationModal
      show={isModalVisible}
      closeCallback={() => {
        onCloseHandler(false);
      }}
      confirmCallback={() => {
        setSearchByDomainValue(searchValue);
      }}
      title={i18next.t("Search")}
      confirmButtonText={i18next.t("Search")}
      confirmButtonVariant="danger"
    >
      <form className="relative">
        <i
          className={`fa fa-search flex items-center absolute top-0 bottom-0 left-3 my-auto
          text-primaryDarkGrey dark:text-white`}
          aria-hidden="true"
        ></i>
        <input
          type="text"
          placeholder={i18next.t("Search...")}
          className="pr-6 pl-10 py-2 border-0 focus:outline-none rounded-[.2rem] focus:shadow-sm
          dark:bg-darkModeLighterBlue dark:text-white"
          onKeyUp={keyUpHandler}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onCloseHandler(false);
            }
          }}
        />
      </form>
    </ConfirmationModal>
  );
};
export default SearchByDomainConfirmationModal;
