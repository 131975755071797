import i18next from "i18next";

import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import Campaign from "../types/models/Campaign";
import ClickedLinks from "../components/Report/ClickedLinks";
import OpenedEmails from "../components/Report/OpenedEmails";
import SentEmails from "../components/Report/SentEmails";
import SubmittedForms from "../components/Report/SubmittedForms";
import Button from "../components/UI/Button";
import { getEnumKey } from "../utils/Enum";

const Report = () => {
  const { id } = useParams();
  const [report, setReport] = useState<Campaign>();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const fetchItem = () => {
    if (!id || !currentTenant?.id) return;

    axios
      .get<Campaign>("/tenants/" + currentTenant?.id + "/campaigns/" + id)
      .then((response) => {
        setReport(response.data);
        setHasLoaded(true);
      })
      .catch(() => {
        toast(i18next.t("Error loading page"));

        navigate("/domains");
      });
  };

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportDownload = (name: string) => {
    if (!id || !currentTenant?.id) return;

    axios
      .get(
        "/tenants/" +
          currentTenant?.id +
          "/campaigns/" +
          id +
          "/actions/?export=csv"
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report-${name}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        toast(i18next.t("Loading error"));
      });
  };

  return (
    <>
      {hasLoaded && report ? (
        <div className="dark:text-white">
          <h4>
            {i18next.t("Report for")} {report.name}
          </h4>
          <div className="bg-white dark:bg-darkModeBlue shadow flex p-6 mt-8 rounded">
            {report.type === getEnumKey("CampaignType", "message") ? (
              <div className="flex flex-wrap basis-1/2 justify-between gap-2">
                <SentEmails
                  recievedEmails={
                    report.received_count ? report.received_count : 0
                  }
                  sentEmails={report.sent_count ? report.sent_count : 0}
                />

                <OpenedEmails
                  openedEmails={
                    report.message_opened_count
                      ? report.message_opened_count
                      : 0
                  }
                  sentEmails={report.sent_count ? report.sent_count : 0}
                />

                <ClickedLinks
                  clickedLinks={report.clicked_count ? report.clicked_count : 0}
                  sentEmails={report.sent_count ? report.sent_count : 0}
                />

                <SubmittedForms
                  submittedForms={
                    report.form_submitted_count
                      ? report.form_submitted_count
                      : 0
                  }
                  sentEmails={report.sent_count ? report.sent_count : 0}
                />
              </div>
            ) : (
              <div className="flex flex-col basis-1/2 justify-between gap-2">
                <div className="basis-full flex flex-wrap p-6 border-solid border border-primaryRed rounded gap-6 items-end">
                  <div>
                    Geöffnete
                    <div className="text-3xl font-bold">Dateien</div>
                  </div>
                  <div className="ml-auto grow-0 text-primaryGrey"></div>
                  <div className="grow-0 flex gap-2 items-end">
                    <span className="text-6xl text-primaryRed bold">
                      {report?.file_opened_count}
                    </span>
                    {/* <span className="text-black dark:text-white text-lg">
                      (Dateien ingesamt: {report.template?.attachments?.length})
                    </span> */}
                  </div>
                </div>
                <div>
                  {report?.template?.attachments?.length ? (
                    <div className="font-bold mb-2 mt-6">
                      {i18next.t("File statistics")}:
                    </div>
                  ) : null}
                  <ul>
                    {report?.template?.attachments?.map((item) => {
                      const matchingAttachment =
                        report?.file_opened_counts_by_file?.find(
                          (attachment) => attachment.attachment_id === item.id
                        );
                      const matchingAttachmentStatistik =
                        report?.file_opened_count && matchingAttachment?.count
                          ? matchingAttachment.count / report.file_opened_count
                          : 0;

                      return (
                        <li key={item.id} className="mb-2">
                          <span>{item.filename} </span>
                          {matchingAttachment && (
                            <span className="font-bold">
                              (
                              <span
                                className={`${
                                  matchingAttachmentStatistik
                                    ? "text-primaryRed"
                                    : ""
                                }`}
                              >
                                {matchingAttachmentStatistik}%
                              </span>
                              )
                            </span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}

            <div className="flex flex-wrap basis-1/2 justify-end">
              <Button
                onClick={() => {
                  reportDownload(report.name);
                }}
              >
                {i18next.t("Download report")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading"></div>
      )}
    </>
  );
};
export default Report;
