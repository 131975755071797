import i18next from "i18next";
import Button from "../UI/Button";
import Search from "../UI/Search";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import axios from "axios";
import { toast } from "react-hot-toast";

interface Props {
  isCreate: boolean;
  targetGroupId: string;
  isTargetsListEdited: boolean;
  onShowModalHandler: Dispatch<SetStateAction<boolean>>;
  onShowSearchModalHandler: Dispatch<SetStateAction<boolean>>;
  targetsListUpdatingHandler: (arg0: string | undefined) => void;
}

const Controls = ({
  isCreate,
  isTargetsListEdited,
  onShowModalHandler,
  onShowSearchModalHandler,
  targetsListUpdatingHandler,
  targetGroupId,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  useEffect(() => {
    targetsListUpdatingHandler(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const targetsExport = () => {
    if (!currentTenant?.id || isCreate || !currentTenant) return;

    let url = `/tenants/${currentTenant.id}/targets/?target-group=${targetGroupId}&export=csv`;

    const axiosHandler = axios.get(url);
    axiosHandler
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `target-group-${targetGroupId}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        toast(i18next.t("Loading error"));
      });
  };

  return (
    <div
      className={`flex mt-6 blur-none gap-4
        ${isCreate && " opacity-30"}
        ${isTargetsListEdited ? " blur-on-edit" : " blur-none"}
      `}
    >
      <Button
        disabled={isTargetsListEdited ? true : false}
        onClick={() => onShowModalHandler(true)}
      >
        <i
          className="fa fa-cloud-upload text-white text-lg pr-6"
          aria-hidden="true"
        ></i>
        {i18next.t("CSV Import")}
      </Button>
      <Button
        disabled={isTargetsListEdited ? true : false}
        className=""
        onClick={targetsExport}
      >
        <i
          className="fa fa-cloud-download text-white text-lg pr-6"
          aria-hidden="true"
        ></i>
        {i18next.t("CSV Export")}
      </Button>
      <Button
        disabled={isTargetsListEdited ? true : false}
        onClick={() => onShowSearchModalHandler(true)}
      >
        <i
          className="fa fa-search text-white text-lg pr-6"
          aria-hidden="true"
        ></i>
        {i18next.t("Domain E-Mail Search")}
      </Button>
      <div className="ml-auto blur-none">
        <Search
          placeholder={i18next.t("Search contact...")}
          searchHandler={setSearchValue}
        ></Search>
      </div>
    </div>
  );
};
export default Controls;
