import axios from "axios";
import i18next from "i18next";
import { toast } from "react-hot-toast";
import Tenant from "../types/models/Tenant";

const useClone = (tenant: Tenant | null, urlPart: string, itemID: string) => {
  const url = "/tenants/" + tenant?.id + urlPart + itemID + "/clone";
  const copyFn = () => {
    const promise = axios
      .post(url)
      .then((response) => {
        toast(i18next.t("Record copy successfully."));
      })
      .catch(() => {
        toast(i18next.t("Record copy error."));
      });
    return promise;
  };
  return copyFn;
};
export default useClone;
