import i18next from "i18next";
import InputValidationError from "../../UI/InputValidationError";
import { ChangeEventHandler } from "react";
// now we don't fetch servers for sms
// import { ChangeEventHandler, useEffect, useState } from "react";
// import axios from "axios";
// import Server from "../../../types/models/Server";
// import { toast } from "react-hot-toast";
import FormErrorResponse from "../../../types/FormErrorResponse";
import Campaign from "../../../types/models/Campaign";
import Domain from "../../../types/models/Domain";

interface PropsType {
  currentTenantId: string | undefined;
  currentCampaign: Campaign | undefined;
  onChangeHandler: ChangeEventHandler<HTMLInputElement>;
  onChangeSelectHandler: ChangeEventHandler<HTMLSelectElement>;
  errors: FormErrorResponse | null;
  phishingDomains: Domain[] | undefined;
}

const SendAsSms = ({
  currentTenantId,
  currentCampaign,
  onChangeHandler,
  onChangeSelectHandler,
  phishingDomains,
  errors,
}: PropsType) => {
  return (
    <div
      className={`${
        currentCampaign?.target_channel_sms_enabled
          ? "border-primaryBlue mb-4"
          : "border-white"
      } border shadow-md p-4 rounded-md`}
    >
      <div className="flex justify-between">
        <div>
          <input
            type="checkbox"
            checked={
              currentCampaign?.target_channel_sms_enabled
                ? currentCampaign.target_channel_sms_enabled
                : false
            }
            id="target_channel_sms_enabled"
            onChange={onChangeHandler}
            className={`hidden template-send`}
          />
          <label
            htmlFor="target_channel_sms_enabled"
            className="flex items-center"
          >
            <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
            <i className="fa fa-mobile px-2" aria-hidden="true"></i>
            {i18next.t("SMS")}
          </label>
        </div>
        <div
          className={`${
            currentCampaign?.target_channel_sms_enabled
              ? "flex flex-col ml-auto text-right"
              : "hidden"
          } text-sm text-primaryDarkGrey`}
        >
          <label
            htmlFor="phishing_domain_id"
            className="text-black dark:text-primaryLightGrey"
          >
            {i18next.t("Phishing domain")}
            <sup>*</sup>
          </label>
          {/* fetch Phishing domain ID */}
          <select
            name="phishing_domain_id"
            id="phishing_domain_id"
            onChange={onChangeSelectHandler}
            value={
              currentCampaign?.phishing_domain_id
                ? currentCampaign.phishing_domain_id
                : "null"
            }
            className="custom-select text-right outline-none dark:bg-darkModeLighterBlue
              dark:text-primaryLightGrey"
          >
            <option disabled value="null">
              -- {i18next.t("select")} --
            </option>
            {phishingDomains?.length &&
              phishingDomains.map((domain, index) => {
                return (
                  <option
                    key={domain.id ? domain.id.concat("_phishing") : index}
                    value={domain.id}
                  >
                    {domain.domain_name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div
        className={`${
          currentCampaign?.target_channel_sms_enabled ? "flex" : "hidden"
        } mt-6`}
      >
        <div className="flex flex-col w-full">
          <label htmlFor="" className="font-bold">
            {i18next.t("Sender's number or name")}
          </label>
          <input
            type="text"
            className="border-solid border-0 border-b border-primaryGrey
              w-1/2 outline-none focus:border-primaryDarkGrey
              dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
            name="sms_sender_name"
            id="sms_sender_name"
            value={
              currentCampaign?.sms_sender_name
                ? currentCampaign.sms_sender_name
                : ""
            }
            onChange={onChangeHandler}
          />
          <InputValidationError
            errors={errors}
            fieldName="sms_sender_name"
          ></InputValidationError>
        </div>
      </div>
    </div>
  );
};
export default SendAsSms;
