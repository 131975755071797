import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import axios from "axios";
import { toast } from "react-hot-toast";
import i18next from "i18next";
import { useEffect, useState } from "react";
import User from "../types/models/User";
import { loadStateFromLocalStorage } from "../utils/LocalStorageState";
import {
  paginationDefault,
  sortingUsers,
} from "../components/UI/ListDisplay/data";
import UserInvitation from "../components/AdminDashboard/UsersManager/UserInvitation";
import UserRemoving from "../components/AdminDashboard/UsersManager/UserRemoving";
import Button from "../components/UI/Button";
import Search from "../components/UI/Search";
import ListControll from "../components/UI/ListDisplay/ListControll";
import ListSort from "../components/UI/ListDisplay/ListSort";
import ListItemsPerPage from "../components/UI/ListDisplay/ListItemsPerPage";
import Pagination from "../components/UI/Pagination";
import { default as UsersList } from "../components/AdminDashboard/UsersManager/Users";

const Users = () => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const initialLocalStorageState = loadStateFromLocalStorage();
  const currentItemsPerPage = initialLocalStorageState?.usersPerPage
    ? +initialLocalStorageState.usersPerPage
    : 10;
  const currentSortValue = initialLocalStorageState?.usersSorting
    ? initialLocalStorageState.usersSorting
    : "field-email-asc";

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const [itemsPerPage, setItemsPerPage] = useState<number>(currentItemsPerPage);
  const [sortValue, setSortValue] = useState<string>(currentSortValue);

  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [showAddNewUserModal, setShowAddNewUserModal] =
    useState<boolean>(false);
  const [currentUserID, setCurrentUserID] = useState<string>("");
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);

  const sortElements = sortingUsers.map((item) => ({
    ...item,
    current: sortValue === item.value ? true : false,
  }));
  const numberOfPagesInPagination = paginationDefault.map((item) => ({
    ...item,
    current: itemsPerPage === item.value ? true : false,
  }));

  const createUrl = (page: number) => {
    let url = "/tenants/" + currentTenant?.id + "/users?page=" + page;

    if (itemsPerPage) url += "&items-per-page=" + itemsPerPage;
    if (searchValue) url += "&search=" + searchValue;
    if (sortValue) url += "&sort=" + sortValue;
    return url;
  };

  const fetchItems = (page: number = 1) => {
    if (!currentTenant?.id) return;
    const url = createUrl(page);
    axios
      .get<{
        current_page: number;
        data: User[];
        last_page: number;
        per_page: number;
        total: number;
      }>(url)

      .then((response) => {
        setUsersList(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
        setHasLoaded(true);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));

        // resetData();
      })
      .finally(() => {
        if (!hasLoaded) setHasLoaded(true);
      });
  };

  useEffect(() => {
    if (!currentTenant?.id) return;
    fetchItems();
    // fetchCapability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, itemsPerPage, searchValue]);

  const changeSortHandler = (value: string) => {
    setSortValue(value);
  };
  const changeItemsPerPageHandler = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
  };

  return (
    <>
      <UserInvitation
        getUsers={fetchItems}
        isVisible={showAddNewUserModal}
        setIsVisible={setShowAddNewUserModal}
      />
      <UserRemoving
        getUsers={fetchItems}
        isVisible={showDeletionConfirmationModal}
        setIsVisible={setShowDeletionConfirmationModal}
        currentUserID={currentUserID}
      />
      <div className="flex gap-6">
        <Button
          onClick={() => {
            setShowAddNewUserModal(true);
          }}
        >
          <i className="fa fa-plus pr-2" aria-hidden="true"></i>{" "}
          {i18next.t("Add new User")}
        </Button>

        <Search
          placeholder={i18next.t("Search...")}
          searchHandler={setSearchValue}
        ></Search>
      </div>
      <ListControll isSearching={searchValue?.length ? true : false}>
        <ListSort
          sortElements={sortElements}
          changeSortHandler={changeSortHandler}
          sortingName="usersSorting"
        ></ListSort>
        <ListItemsPerPage
          tabs={numberOfPagesInPagination}
          paginationName="serversPerPage"
          activeTab={itemsPerPage}
          changeItemsPerPageHandler={changeItemsPerPageHandler}
        ></ListItemsPerPage>
      </ListControll>
      <div className="flex flex-col w-full">
        {usersList.length ? (
          <>
            <UsersList
              getUsers={fetchItems}
              users={usersList}
              setShowDeletionConfirmationModal={
                setShowDeletionConfirmationModal
              }
              setUserForDelete={setCurrentUserID}
            />
            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              totalCount={totalCount}
              nextCallback={() => {
                fetchItems(currentPage + 1);
              }}
              backCallback={() => {
                fetchItems(currentPage - 1);
              }}
            />
          </>
        ) : hasLoaded ? (
          i18next.t("No entries found.")
        ) : (
          <div className="loading"></div>
        )}
      </div>
    </>
  );
};
export default Users;
