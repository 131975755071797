import { Dispatch, SetStateAction, useEffect } from "react";
import Template from "../../types/models/Template";
import TemplateDescription from "./TemplateDescription";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import FormErrorResponse from "../../types/FormErrorResponse";
import Attachments from "./Attachments/Attachments";
import Wrapper from "../UI/Wrapper";

interface Props {
  isCreate: boolean;
  setHasLoaded: Dispatch<SetStateAction<boolean>>;
  template: Template | undefined;
  setTemplate: Dispatch<SetStateAction<Template | undefined>>;
  setIsContentBlocked: Dispatch<SetStateAction<boolean>>;
  contentStatus: string;
  fetchingTemplateHandler: () => void;
  savingTemplateHandler: () => void;
  removingTemplateHandler: () => void;
  errors: FormErrorResponse | null;
  templateToEditId?: string;
}
const TemplateForFileCampaign = ({
  isCreate,
  template,
  setTemplate,
  setHasLoaded,
  setIsContentBlocked,
  contentStatus,
  fetchingTemplateHandler,
  savingTemplateHandler,
  removingTemplateHandler,
  errors,
  templateToEditId,
}: Props) => {
  const { id } = useParams();

  // const [newTemplate, setNewTemplate] = useState<Template | undefined>();

  // currentId = templateToEditId, if templateToEditId exist
  // currentId = id, if !isCreate
  const currentId = templateToEditId ? templateToEditId : !isCreate ? id : "";

  const setNewTemplateData = (
    field: string,
    value: string | boolean | number
  ) => {
    setTemplate((prev) => {
      setIsContentBlocked(true);
      return { ...prev, [field]: value };
    });
  };
  useEffect(() => {
    setNewTemplateData("campaign_type", 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (isCreate) setHasLoaded(true);
        savingTemplateHandler();
        setIsContentBlocked(false);
      }}
    >
      <TemplateDescription
        isCreate={isCreate}
        name={template?.name}
        currentId={currentId}
        errors={errors}
        onDeleteHandler={removingTemplateHandler}
        setTemplate={setNewTemplateData}
      ></TemplateDescription>

      {template && template.id ? (
        <Wrapper className="mt-10 flex flex-col">
          <Attachments
            template={template}
            isTemplateForFileCampaign={true}
          ></Attachments>
        </Wrapper>
      ) : null}
    </Form>
  );
};
export default TemplateForFileCampaign;
