import React from "react";
import { Link } from "react-router-dom";
import MenuItem from "../../../types/models/MenuItem";
import GenericCallback from "../../../types/GenericCallback";

const SidebarMenuItem: React.FC<{
  key: number;
  item: MenuItem;
  className?: string;
  closeHandler: GenericCallback;
}> = ({ item, className, closeHandler }) => {
  const hasChildren = item.children && item.children.length > 0;
  const showMenuDropdoun =
    hasChildren && window.location.pathname.includes(item.link);
  const dropDownLinkCSS = className ? className : "";

  return (
    <li className={`list-none ${dropDownLinkCSS}`}>
      <Link
        id={item.link}
        to={item.link}
        onClick={() => closeHandler()}
        className={`
          w-[100%] flex items-center
          py-2 my-1 rounded-[.2rem]
          cursor-pointer no-underline
          ease-out duration-300 ${
            (item.link !== "/" &&
              window.location.pathname.includes(item.link)) ||
            (item.link === "/" && window.location.pathname === item.link)
              ? "bg-white text-primaryBlue"
              : "text-white bg-transparent hover:bg-hoverBlue hover:w-[95%]"
          }`}
      >
        {item.icon && (
          <i className={`${item.icon} px-4`} aria-hidden="true"></i>
        )}
        {item.label}
        {item.icon && (
          <i className="fa fa-angle-right mr-2 ml-auto" aria-hidden="true"></i>
        )}
      </Link>
      {showMenuDropdoun && (
        <ul
          className="pl-12
            [&>li>a]:text-sm [&>li>a]:p-0 [&>li>a]:bg-transparent [&>li>ul]:pl-6
            hover:[&>li>a]:bg-transparent hover:[&>li>a]:text-white"
        >
          {item.children &&
            item.children.map((child, index) => (
              <SidebarMenuItem
                key={index}
                item={child}
                className={`${
                  window.location.pathname === child.link
                    ? "[&>a]:text-white"
                    : "[&>a]:text-primaryDarkGrey"
                } `}
                closeHandler={closeHandler}
              ></SidebarMenuItem>
            ))}
        </ul>
      )}
    </li>
  );
};

export default SidebarMenuItem;
