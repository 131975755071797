import i18next from "i18next";
import Campaign from "../../types/models/Campaign";
import { getEnumKey, getEnumValue } from "../../utils/Enum";
import { useEffect, useState } from "react";
import axios from "axios";
import Domain from "../../types/models/Domain";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";

interface Props {
  campaign: Campaign;
}

const getFullValue = (value: number) => {
  let fullValue;
  if (value < 10) {
    fullValue = `0${value}`;
  } else {
    fullValue = value;
  }
  return fullValue;
};
const getDateForInput = (date: string) => {
  const dateTime = new Date(date.slice(0, -1)); // remove Z to prevent time zone conversion
  const year = dateTime.getFullYear();
  const month = getFullValue(dateTime.getMonth() + 1);
  const day = getFullValue(dateTime.getDate());
  const hours = getFullValue(dateTime.getHours());
  const minutes = getFullValue(dateTime.getMinutes());

  return [`${year}-${month}-${day}`, `${hours}:${minutes}`];
};

const SentCampaignInfo = ({ campaign }: Props) => {
  const [scheduleDate, setScheduleDate] = useState<string>();
  const [scheduleTime, setScheduleTime] = useState<string>();

  const [interactionDate, setInteractionDate] = useState<string>();
  const [interactionTime, setInteractionTime] = useState<string>();

  const [phishingDomainName, setPhishingDomainName] = useState<
    string | undefined
  >(i18next.t("not selected"));
  const [emailDomainName, setEmailDomainName] = useState<string | undefined>(
    i18next.t("not selected")
  );

  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const fetchPhishingDomainName = () => {
    if (!currentTenant?.id || !campaign.phishing_domain_id) return;
    // setHasLoaded(false);
    axios
      .get<Domain>(
        "/tenants/" +
          currentTenant?.id +
          "/domains/" +
          campaign.phishing_domain_id
      )
      .then((response) => {
        setPhishingDomainName(response.data.domain_name);
        // setHasLoaded(true);
      })
      .catch(() => {
        toast(i18next.t("Loading error"));
      });
  };
  const fetchEmailDomainName = () => {
    if (!currentTenant?.id || !campaign.email_domain_id) return;
    // setHasLoaded(false);
    axios
      .get<Domain>(
        "/tenants/" + currentTenant?.id + "/domains/" + campaign.email_domain_id
      )
      .then((response) => {
        setEmailDomainName(response.data.domain_name);
        // setHasLoaded(true);
      })
      .catch(() => {
        toast(i18next.t("Loading error"));
      });
  };

  useEffect(() => {
    if (campaign?.schedule_datetime) {
      const [date, time] = getDateForInput(campaign.schedule_datetime);
      setScheduleDate(date);
      setScheduleTime(time);
    }
    if (campaign?.interactions_possible_until) {
      const [date, time] = getDateForInput(
        campaign.interactions_possible_until
      );
      setInteractionDate(date);
      setInteractionTime(time);
    }
    fetchPhishingDomainName();
    fetchEmailDomainName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const scheduleTimeout = campaign.schedule_timeout
    ? campaign.schedule_timeout
    : 0;
  const schedulePercentage = campaign.schedule_percentage
    ? campaign.schedule_percentage
    : 0;
  return (
    <div
      className="bg-white dark:bg-darkModeLighterBlue shadow-sm
        text-black dark:text-primaryLightGrey rounded-md overflow-hidden"
    >
      <div
        className="bg-primaryBlue dark:bg-darkModeBlue py-4 px-8 text-white
          text-sm"
      >
        <h1 className="text-lg m-0">{i18next.t("Info")}</h1>
      </div>

      {campaign.type === getEnumKey("CampaignType", "message") ? (
        <div className={`py-4 px-8`}>
          <form action="">
            <div
              className={`${
                campaign?.target_channel_email_enabled ? "block" : "hidden"
              } border-none shadow-md p-4 rounded-md`}
            >
              <div className="flex">
                <div>
                  <input
                    type="checkbox"
                    disabled={true}
                    defaultChecked={
                      campaign?.target_channel_email_enabled
                        ? campaign?.target_channel_email_enabled
                        : false
                    }
                    id="target_channel_email_enabled"
                    className={`hidden template-send`}
                  />
                  <label
                    htmlFor="target_channel_email_enabled"
                    className="flex items-center"
                  >
                    <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
                    <i className="fa fa-envelope-o px-2" aria-hidden="true"></i>
                    {i18next.t("E-Mail")}
                  </label>
                </div>
                <div
                  className={`${
                    campaign?.target_channel_email_enabled
                      ? "flex flex-col ml-auto text-right"
                      : "hidden"
                  } text-sm text-primaryDarkGrey`}
                >
                  <label
                    htmlFor="phishing_domain_id"
                    className="text-black dark:text-primaryLightGrey"
                  >
                    {i18next.t("Phishing domain")}
                  </label>
                  {/* fetch Phishing domain ID */}
                  <span>{phishingDomainName}</span>
                </div>
                <div
                  className={`${
                    campaign?.target_channel_email_enabled
                      ? "flex flex-col ml-6"
                      : "hidden"
                  } text-sm text-primaryDarkGrey text-right`}
                >
                  <label
                    htmlFor=""
                    className="text-black dark:text-primaryLightGrey"
                  >
                    {i18next.t("Outgoing server")}
                  </label>
                  <span>
                    {campaign?.server?.name
                      ? campaign.server.name
                      : i18next.t("not selected")}
                  </span>
                </div>
              </div>
              <div
                className={`${
                  campaign?.target_channel_email_enabled
                    ? "flex flex-wrap"
                    : "hidden"
                } gap-2`}
              >
                <div className="basis-full">
                  <div>
                    <label htmlFor="sender_name" className="font-bold">
                      {i18next.t("Sender name")}
                    </label>
                  </div>
                  <div>
                    <span>
                      {campaign?.sender_name ? campaign.sender_name : ""}
                    </span>
                  </div>
                </div>
                <div className="basis-full">
                  <div>
                    <label htmlFor="sender_email_prefix" className="font-bold">
                      {i18next.t("Sender email")}
                    </label>
                  </div>
                  <div className="flex">
                    <span>
                      {campaign?.sender_email_prefix
                        ? campaign.sender_email_prefix
                        : ""}
                      @{emailDomainName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                campaign?.target_channel_sms_enabled ? "block" : "hidden"
              } border-none shadow-md p-4 rounded-md`}
            >
              <div className="flex justify-between">
                <div>
                  <input
                    type="checkbox"
                    disabled={true}
                    defaultChecked={
                      campaign?.target_channel_sms_enabled
                        ? campaign.target_channel_sms_enabled
                        : false
                    }
                    id="target_channel_sms_enabled"
                    className={`hidden template-send`}
                  />
                  <label
                    htmlFor="target_channel_sms_enabled"
                    className="flex items-center"
                  >
                    <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
                    <i className="fa fa-mobile px-2" aria-hidden="true"></i>
                    {i18next.t("SMS")}
                  </label>
                </div>
              </div>
              <div
                className={`${
                  campaign?.target_channel_sms_enabled ? "flex" : "hidden"
                } mt-2`}
              >
                <div className="flex flex-col w-full">
                  <label htmlFor="" className="font-bold">
                    {i18next.t("Sender's number or name")}
                  </label>
                  <span>
                    {campaign?.sms_sender_name ? campaign.sms_sender_name : ""}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`${
                campaign?.target_channel_whatsapp_enabled ? "block" : "hidden"
              } shadow-md p-4 rounded-md`}
            >
              <div className="flex justify-between">
                <div>
                  <input
                    type="checkbox"
                    disabled={true}
                    defaultChecked={
                      campaign?.target_channel_whatsapp_enabled
                        ? campaign.target_channel_whatsapp_enabled
                        : false
                    }
                    id="target_channel_whatsapp_enabled"
                    className={`hidden template-send`}
                  />
                  <label
                    htmlFor="target_channel_whatsapp_enabled"
                    className="flex items-center"
                  >
                    <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
                    <i className="fa fa-whatsapp px-2" aria-hidden="true"></i>
                    {i18next.t("Whatsapp")}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex mb-6 align-center justify-between">
              <div
                className={`${
                  campaign.use_tracking_pixel ? "block" : "hidden"
                }`}
              >
                <input
                  type="checkbox"
                  disabled={true}
                  defaultChecked={campaign.use_tracking_pixel ? true : false}
                  name="use_tracking_pixel"
                  id="use_tracking_pixel"
                  className={`hidden target-pixel`}
                />
                <label
                  htmlFor="use_tracking_pixel"
                  className="flex items-center px-4"
                >
                  <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
                  {i18next.t("Tracking pixel")}
                </label>
              </div>
            </div>
            <div className="flex gap-x-6 px-4">
              <div
                className={`${
                  campaign?.schedule_send_immediately ? "block" : "hidden"
                }`}
              >
                <input
                  type="radio"
                  disabled={true}
                  defaultChecked={
                    campaign?.schedule_send_immediately ? true : false
                  }
                  name="schedule_send"
                  id="schedule_send_immediately"
                  className={`hidden schedule-send`}
                />
                <label
                  htmlFor="schedule_send_immediately"
                  className="flex items-center"
                >
                  <span className="radio h-4 w-4 mr-2 inline-block border rounded-full"></span>
                  {i18next.t("Send immediately")}
                </label>
              </div>
              <div
                className={`${
                  !campaign?.schedule_send_immediately ? "block" : "hidden"
                }`}
              >
                <div>
                  <div className="my-4">
                    <div>
                      <label htmlFor="" className="font-bold">
                        {i18next.t("Shipping time")}:
                      </label>{" "}
                      <span>
                        {scheduleTime} {scheduleDate}
                      </span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div>
                      <label className="font-bold" htmlFor="">
                        {i18next.t("Shipping interval")}:
                      </label>
                      <span>
                        {" "}
                        {/* CampaignSendPercentage */}
                        {getEnumValue(
                          "CampaignSendPercentage",
                          schedulePercentage
                        ) === "send5Percent"
                          ? i18next.t("To 5% recipients")
                          : null}
                        {getEnumValue(
                          "CampaignSendPercentage",
                          schedulePercentage
                        ) === "send20Percent"
                          ? i18next.t("To 20% recipients")
                          : null}
                        {getEnumValue(
                          "CampaignSendPercentage",
                          schedulePercentage
                        ) === "send33Percent"
                          ? i18next.t("To 33% recipients")
                          : null}
                        {getEnumValue(
                          "CampaignSendPercentage",
                          schedulePercentage
                        ) === "send50Percent"
                          ? i18next.t("To 50% recipients")
                          : null}
                        {getEnumValue(
                          "CampaignSendPercentage",
                          schedulePercentage
                        ) === "send100Percent"
                          ? i18next.t("To 100% recipients")
                          : null}{" "}
                        {/* CampaignSendDelay */}
                        {getEnumValue("CampaignSendDelay", scheduleTimeout) ===
                        "none"
                          ? i18next.t("At the same time")
                          : null}
                        {getEnumValue("CampaignSendDelay", scheduleTimeout) ===
                        "after15Minutes"
                          ? i18next.t("After 15 Minutes")
                          : null}
                        {getEnumValue("CampaignSendDelay", scheduleTimeout) ===
                        "after30Minutes"
                          ? i18next.t("After 30 Minutes")
                          : null}
                        {getEnumValue("CampaignSendDelay", scheduleTimeout) ===
                        "after45Minutes"
                          ? i18next.t("After 45 Minutes")
                          : null}
                        {getEnumValue("CampaignSendDelay", scheduleTimeout) ===
                        "after1Hour"
                          ? i18next.t("After 1 Hour")
                          : null}
                        {getEnumValue("CampaignSendDelay", scheduleTimeout) ===
                        "after2Hours"
                          ? i18next.t("After 2 Hours")
                          : null}
                        {getEnumValue("CampaignSendDelay", scheduleTimeout) ===
                        "after5Hours"
                          ? i18next.t("After 5 Hours")
                          : null}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                campaign.interactions_possible_until ? "block" : "hidden"
              } mb-6 px-4`}
            >
              <div className="flex gap-x-1 items-center">
                <label
                  htmlFor="interactions_possible_until"
                  className="font-bold"
                >
                  {i18next.t("Interaction time:")}
                </label>
                <span>{`${interactionTime}  ${interactionDate}`}</span>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="p-4 ">
          <div className="flex items-baseline gap-2">
            <span className="text-sm text-primaryDarkGrey basis-1/5">
              Phishing Domain:
            </span>{" "}
            <span>{phishingDomainName}</span>
          </div>
          {campaign.template?.attachments?.length ? (
            <div className="pt-3 flex">
              <div className="text-sm text-primaryDarkGrey basis-1/5">
                {i18next.t("Attachments")}:
              </div>
              <ol className="pl-2">
                {campaign?.template?.attachments.map((item) => (
                  <li key={item.id}>{item.filename}</li>
                ))}
              </ol>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
export default SentCampaignInfo;
