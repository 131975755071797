import i18next from "i18next";
import Template from "../../../types/models/Template";
import Wrapper from "../../UI/Wrapper";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";
import useClone from "../../../hooks/use-clone";
import { getEnumKey } from "../../../utils/Enum";
import DesignCreatedForMessage from "./DesignCreatedForMessage";
import DesignCreatedForUSB from "./DesignCreatedForUSB";

interface Props {
  template: Template;
  currentTemplateId: string;
  setCurrentTemplate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setEditedTemplate: (arg0: string) => void;
  reloadList: (page?: number) => void;
}

const TemplateItem = ({
  template,
  currentTemplateId,
  setCurrentTemplate,
  setEditedTemplate,
  reloadList,
}: Props) => {
  const [imgURL, setImgURL] = useState<string>("");
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const fetchImg = () => {
    if (!currentTenant?.id || !template?.id) return;
    setHasLoaded(false);

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/templates/" +
      template.id +
      "/template-preview.jpg";
    axios
      .get<Blob>(url, { responseType: "blob" })
      .then((response) => {
        let imgURL = URL.createObjectURL(response.data);
        setImgURL(imgURL);
      })
      .catch(() => {
        toast(i18next.t("Error loading image"));
        // resetData();
      })
      .finally(() => {
        if (!hasLoaded) setHasLoaded(true);
      });
  };

  useEffect(() => {
    fetchImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cloneTemplate = useClone(
    currentTenant,
    "/templates/",
    template.id ? template.id : ""
  );

  return (
    <div key={template.id} className="mb-4">
      <input
        type="radio"
        id={template.id}
        name="templates"
        className={`hidden templates-list`}
        onChange={setCurrentTemplate}
        data-groupname={template.name}
        checked={currentTemplateId === template.id ? true : false}
      />
      <label
        htmlFor={template.id}
        className="w-full cursor-pointer
          border border-white dark:border-darkModeLighterBlue
          rounded"
      >
        <Wrapper className="min-w-full flex">
          <div className="">
            <div className="radio h-4 w-4 mr-2 inline-block border rounded-full"></div>
          </div>

          <img
            src={imgURL}
            alt=""
            className="object-contain bg-white -mb-6 w-1/5"
          />
          <div className="pl-4 grow">
            <div className="flex justify-between">
              <h4 className="text-lg dark:text-white">{template.name}</h4>
              <div className="text-primaryDarkGrey flex gap-x-2">
                <i
                  className="fa fa-files-o hover:scale-110"
                  aria-hidden="true"
                  onClick={() => {
                    cloneTemplate();
                    reloadList();
                  }}
                ></i>
                <i
                  className="fa fa-pencil hover:scale-110"
                  aria-hidden="true"
                  onClick={() => {
                    if (!template.id) return;
                    setEditedTemplate(template.id);
                  }}
                ></i>
              </div>
            </div>
            <div className="flex flex-wrap gap-x-2 text-sm text-primaryDarkGrey">
              <div>{i18next.t("Design created for")}</div>
              {template.campaign_type ===
              getEnumKey("CampaignType", "message") ? (
                <DesignCreatedForMessage template={template} />
              ) : (
                <DesignCreatedForUSB template={template} />
              )}
            </div>
          </div>
        </Wrapper>
      </label>
    </div>
  );
};
export default TemplateItem;
