import i18next from "i18next";
import { ChangeEventHandler } from "react";
import FormErrorResponse from "../../../types/FormErrorResponse";
import Campaign from "../../../types/models/Campaign";
import Domain from "../../../types/models/Domain";

interface PropsType {
  currentTenantId: string | undefined;
  currentCampaign: Campaign | undefined;
  onChangeHandler: ChangeEventHandler<HTMLInputElement>;
  errors: FormErrorResponse | null;
  phishingDomains: Domain[] | undefined;
  onChangeSelectHandler: ChangeEventHandler<HTMLSelectElement>;
}

const SendAsWhatsapp = ({
  currentTenantId,
  currentCampaign,
  onChangeHandler,
  onChangeSelectHandler,
  errors,
  phishingDomains,
}: PropsType) => {
  return (
    <>
      <div
        className={`${
          currentCampaign?.target_channel_whatsapp_enabled
            ? "border-primaryBlue mb-4"
            : "border-white"
        } border shadow-md p-4 rounded-md`}
      >
        <div className="flex justify-between">
          <div>
            <input
              type="checkbox"
              checked={
                currentCampaign?.target_channel_whatsapp_enabled
                  ? currentCampaign.target_channel_whatsapp_enabled
                  : false
              }
              id="target_channel_whatsapp_enabled"
              onChange={onChangeHandler}
              className={`hidden template-send`}
            />
            <label
              htmlFor="target_channel_whatsapp_enabled"
              className="flex items-center"
            >
              <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
              <i className="fa fa-whatsapp px-2" aria-hidden="true"></i>
              {i18next.t("Whatsapp")}
            </label>
          </div>
          <div
            className={`${
              currentCampaign?.target_channel_whatsapp_enabled
                ? "flex flex-col ml-auto text-right"
                : "hidden"
            } text-sm text-primaryDarkGrey`}
          >
            <label
              htmlFor="phishing_domain_id"
              className="text-black dark:text-primaryLightGrey"
            >
              {i18next.t("Phishing domain")}
              <sup>*</sup>
            </label>
            {/* fetch Phishing domain ID */}
            <select
              name="phishing_domain_id"
              id="phishing_domain_id"
              onChange={onChangeSelectHandler}
              value={
                currentCampaign?.phishing_domain_id
                  ? currentCampaign.phishing_domain_id
                  : "null"
              }
              className="custom-select text-right outline-none dark:bg-darkModeLighterBlue
              dark:text-primaryLightGrey"
            >
              <option disabled value="null">
                -- {i18next.t("select")} --
              </option>
              {phishingDomains?.length &&
                phishingDomains.map((domain, index) => {
                  return (
                    <option
                      key={domain.id ? domain.id.concat("_phishing") : index}
                      value={domain.id}
                    >
                      {domain.domain_name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};
export default SendAsWhatsapp;
