import { Form } from "react-bootstrap";
import i18next from "i18next";
import MediaLibrary from "./MediaLibrary/MediaLibrary";
interface Props {
  isVisible: boolean;
  emailSubject: string | undefined;
  setTemplate: (field: string, value: string) => void;
  addImageToTemplate: (arg0: string) => void;
}

const EmailSubject = ({
  isVisible,
  emailSubject,
  setTemplate,
  addImageToTemplate,
}: Props) => {
  return (
    <Form.Group
      className={`${
        isVisible ? "flex" : "hidden"
      } bg-white p-6 items-center gap-6 rounded-tr`}
    >
      <Form.Label
        htmlFor="email_subject"
        className="text-sm font-bold m-0 shrink-0 dark:text-primaryDarkGrey"
      >
        {i18next.t("Email subject")}
      </Form.Label>
      <Form.Control
        className="border-t-0 border-l-0 border-r-0 rounded-none border-primaryDarkGrey
          focus:border-black focus:shadow-none focus-reset"
        value={emailSubject ? emailSubject : ""}
        placeholder={i18next.t("Email subject")}
        id="email_subject"
        onChange={(e) => {
          setTemplate("email_subject", e.target.value);
        }}
      />
      <MediaLibrary onItemSelectHandler={addImageToTemplate}></MediaLibrary>
    </Form.Group>
  );
};
export default EmailSubject;
