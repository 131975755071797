import i18next from "i18next";
import InputValidationError from "../../UI/InputValidationError";
import { useEffect, useState } from "react";
import FormErrorResponse from "../../../types/FormErrorResponse";

interface PropsType {
  errors: FormErrorResponse | null;
  interaktionsPossibleUntil: string | undefined;
  onInputChangeHandler(field: string, value: string | boolean): void;
}
const removeFieldErrorsFromErrorBag = (
  errorBag: FormErrorResponse | null,
  fieldName: string
): void => {
  delete errorBag?.[fieldName];
};

const Interactions = ({
  errors,
  interaktionsPossibleUntil,
  onInputChangeHandler,
}: PropsType) => {
  const [interactionDate, setInteractionDate] = useState<string>("");
  const [interactionTime, setInteractionTime] = useState<string>("");

  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInteractionDate(event.target.value);
    removeFieldErrorsFromErrorBag(errors, "interactions_possible_until");
  };
  const onChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInteractionTime(event.target.value);

    removeFieldErrorsFromErrorBag(errors, "interactions_possible_until");
  };

  useEffect(() => {
    interactionTime &&
      interactionDate &&
      onInputChangeHandler(
        "interactions_possible_until",
        interactionDate + " " + interactionTime
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionDate, interactionTime]);

  const getFullValue = (value: number) => {
    let fullValue;
    if (value < 10) {
      fullValue = `0${value}`;
    } else {
      fullValue = value;
    }
    return fullValue;
  };
  const getDateForInput = (date: string) => {
    const dateTime = new Date(date.slice(0, -1)); // remove Z to prevent time zone conversion
    const year = dateTime.getFullYear();
    const month = getFullValue(dateTime.getMonth() + 1);
    const day = getFullValue(dateTime.getDate());
    const hours = getFullValue(dateTime.getHours());
    const minutes = getFullValue(dateTime.getMinutes());

    return [`${year}-${month}-${day}`, `${hours}:${minutes}`];
  };

  useEffect(() => {
    if (interaktionsPossibleUntil) {
      const [date, time] = getDateForInput(interaktionsPossibleUntil);
      setInteractionDate(date);
      setInteractionTime(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-6 px-4">
      <div className="flex gap-x-6 items-center">
        <label htmlFor="interactions_possible_until">
          {i18next.t("Interaction time")}
        </label>
        <input
          className="border border-primaryDarkGrey rounded flex-grow p-2
            dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
          type="date"
          id="interactions_possible_until_date"
          value={interactionDate}
          onChange={onChangeDate}
        ></input>
        <input
          className="border border-primaryDarkGrey rounded flex-grow p-2
            dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
          type="time"
          id="interactions_possible_until_time"
          value={interactionTime}
          onChange={onChangeTime}
        ></input>
      </div>

      <InputValidationError
        errors={errors}
        fieldName="interactions_possible_until"
      ></InputValidationError>
    </div>
  );
};
export default Interactions;
