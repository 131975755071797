import i18next from "i18next";
import ConfirmationModal from "../../UI/ConfirmationModal";
import axios from "axios";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";
import { useState } from "react";
import toast from "react-hot-toast";

interface Props {
  getUsers: any;
  isVisible: any;
  setIsVisible: any;
}

const UserInvitation = ({ getUsers, isVisible, setIsVisible }: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const [invitedUser, setInvitedUser] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    capability: number;
  }>({ firstName: "", lastName: "", email: "", capability: 0 });

  const inviteUser = () => {
    let url = "/tenants/" + currentTenant?.id + "/users";
    axios
      .post(url, {
        first_name: invitedUser.firstName,
        last_name: invitedUser.lastName,
        email: invitedUser.email,
        capability_type: invitedUser.capability,
      })
      .then((response) => {
        getUsers();
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };

  return (
    <ConfirmationModal
      show={isVisible}
      closeCallback={() => {
        setIsVisible(false);
      }}
      confirmCallback={() => {
        inviteUser();
      }}
      title={i18next.t("Add new User")}
      text={i18next.t(
        "Use the form below to send an invitation to a new user. Fill in the email field and select the necessary access rights that will be granted to the user."
      )}
      confirmButtonText={i18next.t("Save")}
      confirmButtonVariant="danger"
    >
      <form className="">
        <div className="flex gap-4 mb-4">
          <input
            type="text"
            name="firstName"
            placeholder={i18next.t("First Name")}
            value={invitedUser.firstName}
            onChange={(e) => {
              setInvitedUser((prev) => {
                return { ...prev, firstName: e.target.value };
              });
            }}
            className="basis-[calc(30%-1rem)] border-solid border-0 border-primaryGrey focus:border-primaryDarkGrey border-b focus:outline-none"
          />
          <input
            type="text"
            name="lastName"
            placeholder={i18next.t("Last Name")}
            value={invitedUser.lastName}
            onChange={(e) => {
              setInvitedUser((prev) => {
                return { ...prev, lastName: e.target.value };
              });
            }}
            className="basis-[calc(70%-4rem)] border-solid border-0 border-primaryGrey focus:border-primaryDarkGrey border-b focus:outline-none"
          />
        </div>
        <div className="flex gap-4">
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={invitedUser.email}
            onChange={(e) => {
              setInvitedUser((prev) => {
                return { ...prev, email: e.target.value };
              });
            }}
            className="basis-[calc(30%-1rem)] border-solid border-0 border-primaryGrey focus:border-primaryDarkGrey border-b focus:outline-none"
          />
          <select
            name="capability_type"
            id=""
            className="basis-[calc(50%-4rem)] text-right border-solid border-0 border-primaryGrey focus:border-primaryDarkGrey border-b focus:outline-none"
            onChange={(e) => {
              setInvitedUser((prev) => {
                return { ...prev, capability: +e.target.value };
              });
            }}
          >
            <option value="">{i18next.t("Capability type")}</option>
            <option value="0">{i18next.t("All Access")}</option>
            <option value="1">{i18next.t("Manage Tenant")}</option>
            <option value="2">{i18next.t("View Tenant Reports")}</option>
            {/* <option value="3">{i18next.t("Manage Campaign")}</option>
            <option value="4">{i18next.t("Send Campaign")}</option>
            <option value="5">{i18next.t("View Campaign Reports")}</option>
            <option value="6">{i18next.t("View Deanonymized Data")}</option> */}
            <option value="7">{i18next.t("Access Tenant Platform")}</option>
          </select>
        </div>
      </form>
    </ConfirmationModal>
  );
};
export default UserInvitation;
