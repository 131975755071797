import React from "react";

/**
 * Run an effect only once (even in strict mode).
 *
 * Usually using `useEffect` is perfectly fine. This hook is meant to be used
  in situation in which an API call *must** never be called twice with the
 * same dependencies.
 *
 * More information about handling effects in strict mode:
 * @link https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
 *
 */
export const useEffectOnce: typeof React.useEffect = (effect, deps) => {
  const ref = React.useRef<typeof deps>();
  React.useEffect(() => {
    /**
     * check if all dependencies are still unchanged.
     * we are intentionally using strict equality here to
     * mimic the original behavior of React.useEffect
     */
    const previousDeps = ref.current;

    if (previousDeps && !deps?.some((d, i) => previousDeps[i] !== d)) return;
    ref.current = deps;

    return effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
