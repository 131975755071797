import { ReactNode } from "react";

interface propsType {
  children?: ReactNode;
  className?: string;
  isSearching?: boolean;
}

const ListControll = ({ children, className, isSearching }: propsType) => {
  return (
    <>
      <div
        className={`${isSearching ? "blur-[2px]" : ""}
          flex mt-10 mb-4 justify-between items-center
          border-0 border-b border-solid border-primaryGrey
          text-sm overflow-x-scroll lg:overflow-x-visible
          ${className ? className : ""}`}
      >
        {children}
      </div>
    </>
  );
};
export default ListControll;
