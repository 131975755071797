import ListItem from "../components/UI/ListDisplay/ListItem";
import CampaignListItem from "../components/Campaigns/CampaignListItem";
import Button from "../components/UI/Button";
import Search from "../components/UI/Search";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import axios from "axios";
import { toast } from "react-hot-toast";
import { default as CampaignType } from "../types/models/Campaign";
import Pagination from "../components/UI/Pagination";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import ListTabs from "../components/UI/ListDisplay/ListTabs";
import ListItemsPerPage from "../components/UI/ListDisplay/ListItemsPerPage";
import { getEnumKey } from "../utils/Enum";
import ListSort from "../components/UI/ListDisplay/ListSort";
import { loadStateFromLocalStorage } from "../utils/LocalStorageState";
import ListControll from "../components/UI/ListDisplay/ListControll";
import {
  paginationDefault,
  sortingDefault,
  tabsCampaigns,
} from "../components/UI/ListDisplay/data";

interface ResponseType {
  current_page: number;
  data: CampaignType[];
  last_page: number;
  per_page: number;
  total: number;
  count_status_draft: number;
  count_status_sent: number;
  count_type_file: number;
  count_type_message: number;
  count_archived: number;
  count_all: number;
}

function Campaigns() {
  const navigate = useNavigate();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const initialLocalStorageState = loadStateFromLocalStorage();
  const currentItemsPerPage = initialLocalStorageState?.campaignsPerPage
    ? +initialLocalStorageState.campaignsPerPage
    : 10;
  const currentSortValue = initialLocalStorageState?.campaignsSorting
    ? initialLocalStorageState.campaignsSorting
    : "updated-desc";

  const [campaignsList, setCampaignList] = useState<CampaignType[]>([]);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [tabValues, setTabsValues] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(currentItemsPerPage);
  const [sortValue, setSortValue] = useState<string>(currentSortValue);
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentCampaignID, setCurrentCampaignID] = useState<string>("");

  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [showCopyConfirmationModal, setShowCopyConfirmationModal] =
    useState<boolean>(false);

  // set values for tabs, sorting & pagination
  const tabs = tabsCampaigns.map((item) => ({
    ...item,
    value: tabValues[item.id],
    current: currentTab === item.id ? true : false,
  }));
  const numberOfPagesInPagination = paginationDefault.map((item) => ({
    ...item,
    current: itemsPerPage === item.value ? true : false,
  }));
  const sortElements = sortingDefault.map((item) => ({
    ...item,
    current: sortValue === item.value ? true : false,
  }));

  const resetData = () => {
    setCampaignList([]);
    setCurrentPage(0);
    setLastPage(0);
    setTotalCount(0);
    setHasLoaded(false);
  };

  const remove = () => {
    if (!currentCampaignID || !currentTenant?.id) return;

    axios
      .delete(
        "/tenants/" + currentTenant?.id + "/campaigns/" + currentCampaignID
      )
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));
        fetchItems();
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  const copy = () => {
    if (!currentCampaignID || !currentTenant?.id) return;

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/campaigns/" +
      currentCampaignID +
      "/clone";
    axios
      .post(url)
      .then((response) => {
        toast(i18next.t("Record copy successfully."));

        fetchItems();
      })
      .catch(() => {
        toast(i18next.t("Record copy error."));
      });
  };
  const fetchItems = (page: number = 1) => {
    if (!currentTenant?.id) return;
    resetData();

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/campaigns?page=" +
      page +
      "&items-per-page=" +
      itemsPerPage;
    if (searchValue) url += "&search=" + searchValue;
    if (sortValue) url += "&sort=" + sortValue;
    if (currentTab === 1)
      url += "&type=" + getEnumKey("CampaignType", "message");
    if (currentTab === 2) url += "&type=" + getEnumKey("CampaignType", "file");
    if (currentTab === 5) url += "&archived=1";
    if (currentTab === 3)
      url += "&status=" + getEnumKey("CampaignStatus", "draft");
    if (currentTab === 4)
      url +=
        "&status=" + getEnumKey("CampaignStatus", "awaitingVictimInteraction");
    axios
      .get<ResponseType>(url)
      .then((response) => {
        setCampaignList(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
        setTabsValues([
          response.data.count_all - response.data.count_archived,
          response.data.count_type_message,
          response.data.count_type_file,
          response.data.count_status_draft,
          response.data.count_status_sent,
          response.data.count_archived,
        ]);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));
        resetData();
      })
      .finally(() => {
        setHasLoaded(true);
      });
  };
  const changeItemsPerPageHandler = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
  };
  const changeTabHandler = (tab: number) => {
    setCurrentTab(tab);
  };
  const changeSortHandler = (value: string) => {
    setSortValue(value);
  };
  useEffect(() => {
    if (!currentTenant?.id) return;
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, searchValue, currentTab, sortValue]);
  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          remove();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      <ConfirmationModal
        show={showCopyConfirmationModal}
        closeCallback={() => {
          setShowCopyConfirmationModal(false);
        }}
        confirmCallback={() => {
          copy();
        }}
        title={i18next.t("Copy record")}
        text={i18next.t("This action cannot be done.")}
        confirmButtonText={i18next.t("Copy")}
        confirmButtonVariant="danger"
      />

      <div className="flex flex-col w-full lg:pt-5 pt-2">
        <div className="flex flex-col gap-6 lg:flex-row lg:gap-10">
          <Button
            onClick={() => {
              navigate("/campaigns/create");
            }}
          >
            <i className="fa fa-plus pr-2" aria-hidden="true"></i>{" "}
            {i18next.t("Add new campaign")}
          </Button>

          <Search
            placeholder={i18next.t("Search...")}
            searchHandler={setSearchValue}
          ></Search>
        </div>
        <ListControll isSearching={searchValue?.length ? true : false}>
          <ListTabs tabs={tabs} changeTabHandler={changeTabHandler}></ListTabs>
        </ListControll>
        <div
          className={`${
            searchValue?.length ? "blur-[2px]" : ""
          } flex justify-between items-end -mt-2 mb-2
            text-sm overflow-x-scroll lg:overflow-x-visible`}
        >
          <ListSort
            sortElements={sortElements}
            changeSortHandler={changeSortHandler}
            sortingName="campaignsSorting"
          ></ListSort>
          <ListItemsPerPage
            tabs={numberOfPagesInPagination}
            activeTab={itemsPerPage}
            changeItemsPerPageHandler={changeItemsPerPageHandler}
            paginationName="campaignsPerPage"
          ></ListItemsPerPage>
        </div>

        <div className="mt-4">
          {campaignsList.length > 0 &&
            campaignsList.map((campaign, index) => (
              <ListItem
                className="lg:pr-6 pb-0"
                key={campaign.id}
                copyHandler={() => {
                  setShowCopyConfirmationModal(true);
                  setCurrentCampaignID(campaign.id ? campaign.id : "");
                }}
                clickHandler={() => {
                  navigate("/campaigns/" + campaign.id);
                }}
                showReportHandler={() => {
                  navigate("/reports/" + campaign.id);
                }}
                deleteHandler={() => {
                  setShowDeletionConfirmationModal(true);
                  setCurrentCampaignID(campaign.id ? campaign.id : "");
                }}
                buttonType={
                  campaign.status === getEnumKey("CampaignStatus", "draft") ||
                  campaign.is_archived
                    ? "edit"
                    : campaign.status ===
                        getEnumKey(
                          "CampaignStatus",
                          "awaitingVictimInteraction"
                        ) ||
                      campaign.status ===
                        getEnumKey("CampaignStatus", "aborted") ||
                      campaign.status ===
                        getEnumKey("CampaignStatus", "archived")
                    ? "report&info"
                    : "info"
                }
              >
                <CampaignListItem campaign={campaign} />
              </ListItem>
            ))}
        </div>
        <p>
          {!campaignsList.length && hasLoaded && i18next.t("No entries found.")}
          {!hasLoaded && <span className="loading"></span>}
        </p>
        {campaignsList.length ? (
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            totalCount={totalCount}
            nextCallback={() => {
              fetchItems(currentPage + 1);
            }}
            backCallback={() => {
              fetchItems(currentPage - 1);
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default Campaigns;
