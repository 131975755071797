import { ChangeEvent, useState } from "react";
import Attachment from "../../../types/models/Attachment";
import ConfirmationModal from "../../UI/ConfirmationModal";
import i18next from "i18next";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";

interface Props {
  attachment: Attachment;
  onChangeHandler: (page?: number) => void;
  isTemplateForFileCampaign?: boolean;
}

const AttachmentItem = ({
  attachment,
  onChangeHandler,
  isTemplateForFileCampaign = false,
}: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const [isEdited, setIsEdited] = useState(false);
  const [fileName, setFileName] = useState<string | undefined>(
    attachment.filename
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const nameChangingHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target) return;
    setFileName(e.currentTarget.value);
  };

  const toggleEditing = () => {
    setIsEdited((prev) => !prev);
    setFileName(attachment.filename);
  };

  const removeHandler = () => {
    if (!currentTenant?.id) return;

    const url =
      "/tenants/" + currentTenant.id + "/attachments/" + attachment.id;

    const axiosHandler = axios.delete(url);

    axiosHandler
      .then((response) => {
        onChangeHandler();
      })
      .catch((error) => {
        toast(i18next.t("Error deleting record."));
      });
  };
  const saveHandler = () => {
    if (!currentTenant?.id) return;

    const extensionPattern = /\.[0-9a-z]+$/i;
    const newExtension = fileName?.match(extensionPattern);
    const prevExtension = attachment?.filename?.match(extensionPattern);
    if (newExtension && prevExtension && newExtension[0] !== prevExtension[0]) {
      toast(i18next.t("The file extension should not change"));
      setFileName(attachment.filename);
      return;
    }

    const url =
      "/tenants/" + currentTenant.id + "/attachments/" + attachment.id;

    const axiosHandler = axios.patch(url, { filename: fileName });

    axiosHandler
      .then((response) => {
        toast(i18next.t("Record saved."));
        onChangeHandler();
        setIsEdited(false);
      })
      .catch((error) => {
        toast(i18next.t("Error saving record."));
      });
  };

  return (
    <>
      <ConfirmationModal
        show={isModalVisible}
        closeCallback={() => {
          setIsModalVisible(false);
        }}
        confirmCallback={removeHandler}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      <div
        className={`${
          isTemplateForFileCampaign ? "basis-[45%]" : "w-full"
        } flex gap-2 mt-3 justify-between`}
      >
        <input
          type="text"
          disabled={!isEdited}
          value={fileName}
          onChange={nameChangingHandler}
          className={`${
            isEdited
              ? "border-primaryGrey focus:border-primaryDarkGrey"
              : "bg-white border-white"
          } border-b border-solid border-0 outline-none w-auto grow`}
        />
        <div className="flex gap-2">
          {isEdited ? (
            <>
              <div onClick={saveHandler}>
                <span className="fa fa-save hover:scale-110 cursor-pointer"></span>
              </div>
              <div onClick={toggleEditing}>
                <span className="fa fa-times hover:scale-110 cursor-pointer"></span>
              </div>
            </>
          ) : (
            <div onClick={toggleEditing}>
              <span className="fa fa-pencil hover:scale-110 cursor-pointer"></span>
            </div>
          )}
          <div
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            <span className="fa fa-trash-o hover:scale-110 cursor-pointer"></span>
          </div>
        </div>
      </div>
    </>
  );
};
export default AttachmentItem;
