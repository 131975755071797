import i18next from "i18next";

interface Props {
  submittedForms: number;
  sentEmails: number;
}
const SubmittedForms = ({ submittedForms, sentEmails }: Props) => {
  return (
    <div className="flex-1 border-solid border p-6 border-primaryRed rounded">
      {i18next.t("Submitted")}
      <div className="text-3xl font-bold">{i18next.t("Forms")}</div>
      <div className="ml-auto grow-0 text-primaryGrey">
        <span className="text-black dark:text-white text-lg">
          {submittedForms}
        </span>
        /{sentEmails}
      </div>
      <div className="grow-0 text-5xl mt-10 text-primaryRed">
        {sentEmails ? submittedForms / sentEmails : 0}%
      </div>
      <div className="basis-full mt-6 h-2 bg-primaryLightGrey rounded">
        {sentEmails ? (
          <div
            className="h-2 bg-primaryRed rounded"
            style={{
              width: (submittedForms * 100) / sentEmails + "%",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default SubmittedForms;
