import i18next from "i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { default as TargetType } from "../types/models/Target";
import { toast } from "react-hot-toast";
import FormErrorResponse from "../types/FormErrorResponse";
import { loadStateFromLocalStorage } from "../utils/LocalStorageState";
import TargetGroupDeletingConfirmationModal from "../components/TargetGroup/TargetGroupDeletingConfirmationModal";
import TargetDeletingConfirmationModal from "../components/TargetGroup/TargetDeletingConfirmationModal";
import CsvImportConfirmationModal from "../components/TargetGroup/csvImportConfirmationModal";
import TargetGroupDescription from "../components/TargetGroup/TargetGroupDescription";
import Controls from "../components/TargetGroup/Controls";
import TargetsList from "../components/TargetGroup/TargetsList";
import SearchByDomainConfirmationModal from "../components/TargetGroup/SearchByDomainConfirmationModal";
import { useEffectOnce } from "../utils/ReactHelper";

function TargetGroup({
  isCreate,
  targetGroupToEditId,
}: {
  isCreate: boolean;
  targetGroupToEditId?: string;
}) {
  const { id } = useParams();

  // currentId = templateToEditId, if templateToEditId exist
  // currentId = id, if !isCreate
  const currentId = targetGroupToEditId
    ? targetGroupToEditId
    : !isCreate
    ? id
    : "";

  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const initialLocalStorageState = loadStateFromLocalStorage();
  const currentItemsPerPage = initialLocalStorageState?.targetsPerPage
    ? +initialLocalStorageState.targetsPerPage
    : 10;
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);

  const [
    showDomainSearchConfirmationModal,
    setShowDomainSearchConfirmationModal,
  ] = useState<boolean>(false);
  const [
    showTargetDeletionConfirmationModal,
    setShowTargetDeletionConfirmationModal,
  ] = useState<boolean>(false);
  const [showCSVImportConfirmationModal, setShowCSVImportConfirmationModal] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrorResponse | null>(null);
  const [itemsPerPage, setItemsPerPage] = useState<number>(currentItemsPerPage);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [targets, setTargets] = useState<TargetType[] | null>();
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [searchByDomainValue, setSearchByDomainValue] = useState<string>();
  const [itemsForRemoving, setItemsForRemoving] = useState<string[] | []>([]);

  const getTargets = (page: number = 1) => {
    if (!currentTenant?.id || isCreate) return;

    let url = `/tenants/${currentTenant?.id}/targets/?target-group=${currentId}&items-per-page=${itemsPerPage}`;
    if (searchValue) url += "&search=" + searchValue;
    if (searchByDomainValue)
      url += "&domain-email-suffix=" + searchByDomainValue;
    if (page > 1) url += "&page=" + page;

    const axiosHandler = axios.get<{
      current_page: number;
      data: TargetType[];
      last_page: number;
      per_page: number;
      total: number;
    }>(url);

    setErrors(null);
    setHasLoaded(true);
    // resetData();
    axiosHandler
      .then((response) => {
        setTargets(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
        setHasLoaded(false);
      })
      .catch((error) => {
        setHasLoaded(false);
        if (error?.response?.data) setErrors(error.response.data);
        if (error?.response?.data["domain-email-suffix"]) {
          toast(i18next.t("Invalid domain name."));
        } else {
          toast(i18next.t("Error"));
        }
      });
  };
  useEffect(() => {
    getTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchByDomainValue]);

  // if the search type has been changed, the value of the previous search type is reset
  useEffect(() => {
    setSearchByDomainValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  useEffect(() => {
    setSearchValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByDomainValue]);

  const [targetsEditStatus, setTargetsEditStatus] = useState<boolean>(false);
  const onChangeTargetsEditStatus = (newStatus: boolean) => {
    setTargetsEditStatus(newStatus);
  };
  return (
    <div
      className={`${
        targetsEditStatus ? "blur-on-edit" : "blur-none"
      } dark:text-white`}
    >
      <TargetGroupDeletingConfirmationModal
        targetGroupId={currentId ? currentId : ""}
        isModalVisible={showDeletionConfirmationModal}
        onCloseHandler={setShowDeletionConfirmationModal}
      />
      <TargetDeletingConfirmationModal
        targetGroupId={currentId ? currentId : ""}
        isModalVisible={showTargetDeletionConfirmationModal}
        onCloseHandler={setShowTargetDeletionConfirmationModal}
        itemsForRemoving={itemsForRemoving}
        setItemsForRemoving={setItemsForRemoving}
        targetsListUpdatingHandler={getTargets}
      />
      <CsvImportConfirmationModal
        targetGroupId={currentId ? currentId : ""}
        isModalVisible={showCSVImportConfirmationModal}
        onCloseHandler={setShowCSVImportConfirmationModal}
        onListReloadHandler={getTargets}
        onErrorsHandler={setErrors}
        onLoadingHandler={setHasLoaded}
      />
      <SearchByDomainConfirmationModal
        isModalVisible={showDomainSearchConfirmationModal}
        onCloseHandler={setShowDomainSearchConfirmationModal}
        setSearchByDomainValue={setSearchByDomainValue}
      />
      <TargetGroupDescription
        targetGroupId={currentId ? currentId : ""}
        isCreate={isCreate}
        isTargetsListEdited={targetsEditStatus}
        onShowModalHandler={setShowDeletionConfirmationModal}
      />
      <Controls
        targetGroupId={currentId ? currentId : ""}
        isCreate={isCreate}
        isTargetsListEdited={targetsEditStatus}
        onShowModalHandler={setShowCSVImportConfirmationModal}
        onShowSearchModalHandler={setShowDomainSearchConfirmationModal}
        targetsListUpdatingHandler={setSearchValue}
      />
      <TargetsList
        targetGroupId={currentId ? currentId : ""}
        isCreate={isCreate}
        hasLoaded={hasLoaded}
        totalCount={totalCount}
        currentPage={currentPage}
        lastPage={lastPage}
        targets={targets}
        setTargets={setTargets}
        onChangeTargetsEditStatus={onChangeTargetsEditStatus}
        itemsForRemoving={itemsForRemoving}
        setItemsForRemoving={(items) => {
          setItemsForRemoving(items);
        }}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setErrors={setErrors}
        onTargetDeleteHandler={setShowTargetDeletionConfirmationModal}
        onListReloadHandler={getTargets}
      />
    </div>
  );
}
export default TargetGroup;
