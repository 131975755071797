import i18next from "i18next";
import { Form } from "react-bootstrap";

interface Props {
  isVisible: boolean;
  content: string | undefined;
  setTemplate: (field: string, value: string) => void;
}
const WhatsappContent = ({ isVisible, content, setTemplate }: Props) => {
  return (
    <Form.Control
      as="textarea"
      placeholder={i18next.t("WhatsApp content content")}
      className={`${
        isVisible ? "block" : "hidden"
      } mb-4 focus-reset focus:outline-none focus:shadow-none border-white focus:border-white
        rounded-tl-none shadow-md pt-6`}
      rows={15}
      id="whatsapp_content"
      value={content ? content : ""}
      onChange={(e) => {
        setTemplate("whatsapp_content", e.target.value);
      }}
    />
  );
};
export default WhatsappContent;
