import i18next from "i18next";
import { default as TargetType } from "../../types/models/Target";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

interface Props {
  target: TargetType;
  isListEdited: boolean;
  itemsForRemoving: string[];
  selectForRemovingHandler: (id: string, isChecked: boolean) => void;
  targetEditHandler: (id: string) => void;
  onTargetDeleteHandler: Dispatch<SetStateAction<boolean>>;
  setItemsForRemoving: Dispatch<SetStateAction<string[]>>;
}
const tdClass = "w-full px-6 relative";

const Target = ({
  target,
  isListEdited,
  itemsForRemoving,
  selectForRemovingHandler,
  targetEditHandler,
  onTargetDeleteHandler,
  setItemsForRemoving,
}: Props) => {
  const onEditHandler = () => {
    targetEditHandler(target.id ? target.id : "");
    setItemsForRemoving([]);
  };
  const onRemoveHandler = () => {
    if (!target.id) return;
    onTargetDeleteHandler(true);
    setItemsForRemoving([]);
    selectForRemovingHandler(target.id, false);
  };
  const onCheckboxChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!target.id) return;
    selectForRemovingHandler(target.id, e.target.checked);
  };
  return (
    <label
      key={target.id}
      className={`${
        itemsForRemoving.some((item) => item === target.id)
          ? "bg-lightGreen"
          : ""
      } flex cursor-pointer m-1 items-center rounded-md`}
      htmlFor={target.id}
    >
      <div className={tdClass + " md:w-[10%] flex items-center gap-2"}>
        {isListEdited ? (
          <>
            <input
              className="hidden target-select"
              type="checkbox"
              id={target.id}
              checked={itemsForRemoving.some((item) => item === target.id)}
              onChange={onCheckboxChangeHandler}
            ></input>
            <span className="fa fa-square-o mt-[2px]"></span>
            <span className="fa fa-check-square-o mt-[2px]"></span>
          </>
        ) : null}
        {target.salutation === 1 && i18next.t("Mr")}
        {target.salutation === 0 && i18next.t("Mrs")}
      </div>
      <div className={tdClass + " md:w-[10%]"}>{target.first_name}</div>
      <div className={tdClass + " md:w-[10%]"}>{target.last_name}</div>
      <div className={tdClass + " md:w-[25%]"}>{target.company_name}</div>
      <div className={tdClass + " md:w-[20%]"}>{target.email}</div>
      <div className={tdClass + " md:w-[10%]"}>{target.phone}</div>
      <div className={tdClass + " md:w-[15%] text-right"}>
        {isListEdited ? (
          <>
            <i
              className="fa fa-pencil text-base px-2 cursor-pointer ease-out duration-300
                hover:scale-125"
              onClick={onEditHandler}
            ></i>
            <i
              className="fa fa-trash-o text-base px-2 cursor-pointer ease-out duration-300
                hover:scale-125"
              onClick={onRemoveHandler}
            ></i>
          </>
        ) : null}
      </div>
    </label>
  );
};
export default Target;
