import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Template from "../../types/models/Template";
import TemplateTabs from "./TemplateTabs";
import EmailContent from "./EmailContent";
import SmsContent from "./SmsContent";
import WhatsappContent from "./WhatsappContent";
import PhishingPageContent from "./PhishingPageContent";
import ReproofPageContent from "./ReproofPageContent";
import TemplateDescription from "./TemplateDescription";
import { Form } from "react-bootstrap";
import { getEnumValue } from "../../utils/Enum";
import { useParams } from "react-router-dom";
import FormErrorResponse from "../../types/FormErrorResponse";

interface Props {
  isCreate: boolean;
  setHasLoaded: Dispatch<SetStateAction<boolean>>;
  template: Template | undefined;
  setTemplate: Dispatch<SetStateAction<Template | undefined>>;
  setIsContentBlocked: Dispatch<SetStateAction<boolean>>;
  contentStatus: string;
  fetchingTemplateHandler: () => void;
  savingTemplateHandler: () => void;
  removingTemplateHandler: () => void;
  errors: FormErrorResponse | null;
  templateToEditId?: string;
}
const TemplateForNotificationCampagne = ({
  isCreate,
  setHasLoaded,
  template,
  setTemplate,
  setIsContentBlocked,
  contentStatus,
  fetchingTemplateHandler,
  savingTemplateHandler,
  removingTemplateHandler,
  errors,
  templateToEditId,
}: Props) => {
  const { id } = useParams();

  // const [newTemplate, setNewTemplate] = useState<TemplateType>();
  const [currentTab, setCurrentTab] = useState<number>(0);

  // currentId = templateToEditId, if templateToEditId exist
  // currentId = id, if !isCreate
  const currentId = templateToEditId ? templateToEditId : !isCreate ? id : "";

  const setNewTemplateData = (
    field: string,
    value: string | boolean | number
  ) => {
    setTemplate((prev) => {
      setIsContentBlocked(true);
      return { ...prev, [field]: value };
    });
  };

  useEffect(() => {
    setNewTemplateData("campaign_type", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addImageToMJMLContent = (content: string, imgID: string): string => {
    const parser = new DOMParser();
    let mjmlImageSection;
    if (content.length) {
      mjmlImageSection = parser.parseFromString(content, "application/xml");
    } else {
      mjmlImageSection = parser.parseFromString(
        "<mjml><mj-body></mj-body></mjml>",
        "application/xml"
      );
    }
    const errorNode = mjmlImageSection.querySelector("parsererror");
    if (!errorNode) {
      const sectionMJML = mjmlImageSection.createElement("mj-section");
      const columnMJML = mjmlImageSection.createElement("mj-column");
      // const imgPlaceHolder = document.createTextNode(
      //   `@media-item-url(${imgID})`
      // );
      const imgMjml = mjmlImageSection.createElement("mj-image");
      imgMjml.setAttribute("src", `@media-item-url(${imgID})`);
      columnMJML.appendChild(imgMjml);
      sectionMJML.appendChild(columnMJML);
      mjmlImageSection.documentElement
        .getElementsByTagName("mj-body")[0]
        .appendChild(sectionMJML);
    } else {
      console.log("error while parsing");
    }
    const result = new XMLSerializer()
      .serializeToString(mjmlImageSection)
      .replaceAll("\n", "")
      .replaceAll("  ", "")
      .replaceAll("<mj-body>", "  <mj-body>")
      .replaceAll("</mj-body>", "  </mj-body>")
      .replaceAll("<mj-section>", "    <mj-section>")
      .replaceAll("</mj-section>", "    </mj-section>")
      .replaceAll("<mj-column>", "      <mj-column>")
      .replaceAll("</mj-column>", "      </mj-column>")
      .replaceAll("<mj-column>", "<mj-column>        ")
      .replaceAll(">", ">\n");
    return result;
  };
  const addImageToHTMLContent = (content: string, imgID: string): string => {
    const parser = new DOMParser();
    let htmlContent;
    if (content.length) {
      htmlContent = parser.parseFromString(content, "text/html");
    } else {
      htmlContent = parser.parseFromString(
        "<html><head></head><body></body></html>",
        "text/html"
      );
    }
    const errorNode = htmlContent.querySelector("parsererror");
    if (!errorNode) {
      const imgHtml = htmlContent.createElement("img");
      imgHtml.setAttribute("src", `@media-item-url(${imgID})`);
      htmlContent.documentElement
        .getElementsByTagName("body")[0]
        .appendChild(imgHtml);
    } else {
      console.log("error while parsing");
    }

    const result = new XMLSerializer()
      .serializeToString(htmlContent)
      .replaceAll("\n", "")
      .replaceAll("  ", "")
      .replaceAll("<head>", "  <head>")
      .replaceAll("</head>", "  </head>")
      .replaceAll("<body>", "  <body>")
      .replaceAll("</body>", "  </body>")
      .replaceAll("<body>", "<body>    ")
      .replaceAll(">", ">\n");
    return result;
  };
  const addImageToTemplate = (value: string | undefined) => {
    if (!value) return;
    setTemplate((prev) => {
      if (
        prev?.email_format === undefined ||
        prev?.email_content_input_mode === undefined
      )
        return;
      const currentEmailContent = prev?.email_content ? prev.email_content : "";
      const emailFormat = getEnumValue("EmailFormat", prev.email_format);
      const emailInputMode = getEnumValue(
        "InputMode",
        prev.email_content_input_mode
      );
      let newEmailContent = currentEmailContent;

      if (emailFormat === "html" && emailInputMode === "editor")
        newEmailContent = addImageToMJMLContent(currentEmailContent, value);

      if (emailFormat === "html" && emailInputMode === "raw")
        newEmailContent = addImageToHTMLContent(currentEmailContent, value);

      return { ...prev, email_content: newEmailContent };
    });
  };

  const currentEmailFormat = template?.email_content_input_mode
    ? template?.email_format
      ? "html"
      : "text"
    : "mjml";

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (isCreate) setHasLoaded(true);
        savingTemplateHandler();
        setIsContentBlocked(false);
      }}
    >
      <TemplateDescription
        isCreate={isCreate}
        name={template?.name}
        currentId={currentId}
        errors={errors}
        onDeleteHandler={removingTemplateHandler}
        setTemplate={setNewTemplateData}
      ></TemplateDescription>

      <TemplateTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        template={template}
      ></TemplateTabs>

      <EmailContent
        isVisible={currentTab === 0}
        isLocked={contentStatus === "locked" ? true : false}
        template={template}
        fetchTemplate={fetchingTemplateHandler}
        emailFormat={currentEmailFormat}
        setTemplate={setNewTemplateData}
        addImageToTemplate={addImageToTemplate}
      ></EmailContent>

      <SmsContent
        isVisible={currentTab === 1}
        content={template?.sms_content}
        setTemplate={setNewTemplateData}
      ></SmsContent>

      <WhatsappContent
        isVisible={currentTab === 2}
        content={template?.whatsapp_content}
        setTemplate={setNewTemplateData}
      ></WhatsappContent>

      <PhishingPageContent
        isVisible={currentTab === 3}
        title={template?.phishing_page_title}
        content={template?.phishing_page_content}
        setTemplate={setNewTemplateData}
      ></PhishingPageContent>

      <ReproofPageContent
        isVisible={currentTab === 4}
        title={template?.reproof_page_title}
        content={template?.reproof_page_content}
        setTemplate={setNewTemplateData}
      ></ReproofPageContent>
    </Form>
  );
};
export default TemplateForNotificationCampagne;
