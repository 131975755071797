import i18next from "i18next";
import ConfirmationModal from "../UI/ConfirmationModal";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";

interface Props {
  isModalVisible: boolean;
  onCloseHandler: Dispatch<SetStateAction<boolean>>;
  itemsForRemoving: string[];
  setItemsForRemoving: Dispatch<SetStateAction<string[]>>;
  targetsListUpdatingHandler: (page?: number) => void;
  targetGroupId: string;
}

const TargetDeletingConfirmationModal = ({
  isModalVisible,
  onCloseHandler,
  itemsForRemoving,
  setItemsForRemoving,
  targetsListUpdatingHandler,
  targetGroupId,
}: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const currentTenantId = currentTenant?.id;

  const removeTargets = (targets: string[]) => {
    if (!currentTenantId || !targets.length) return;

    axios
      .post(
        "/tenants/" +
          currentTenantId +
          "/target-groups/" +
          targetGroupId +
          "/remove-targets/",
        { target_ids: targets }
      )
      .then((response) => {
        toast(i18next.t("Targets successfully deleted"));
        targetsListUpdatingHandler();
        setItemsForRemoving([]);
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  return (
    <ConfirmationModal
      show={isModalVisible}
      closeCallback={() => {
        onCloseHandler(false);
        setItemsForRemoving([]);
      }}
      confirmCallback={() => {
        removeTargets(itemsForRemoving);
      }}
      title={i18next.t("Remove record")}
      text={i18next.t("This action cannot be undone.")}
      confirmButtonText={i18next.t("Remove")}
      confirmButtonVariant="danger"
    />
  );
};
export default TargetDeletingConfirmationModal;
