import {
  ReducerActionConnectedTenants,
  ReducerActionCurrentTenant,
  ReducerActionCurrentTenantID,
  ReducerActionCurrentUser,
  ReducerActionToken,
} from "../types/ReducerActions";

export const token = (state = null, action: ReducerActionToken) => {
  if (action.type === "SET_TOKEN") return action.payload;
  else if (action.type === "LOGOUT") return null;

  return state;
};

export const currentUser = (state = null, action: ReducerActionCurrentUser) => {
  if (action.type === "SET_CURRENT_USER") return action.payload;
  else if (action.type === "LOGOUT") return null;

  return state;
};

export const currentCapabilities = (
  state = null,
  action: ReducerActionCurrentUser
) => {
  if (action.type === "SET_CURRENT_CAPABILITIES") return action.payload;
  else if (action.type === "LOGOUT") return null;

  return state;
};

export const currentTenant = (
  state = null,
  action: ReducerActionCurrentTenant
) => {
  if (action.type === "SET_CURRENT_TENANT") return action.payload;

  return state;
};

export const currentTenantID = (
  state = null,
  action: ReducerActionCurrentTenantID
) => {
  if (action.type === "SET_CURRENT_TENANT_ID") return action.payload;
  else if (action.type === "LOGOUT") return null;

  return state;
};

export const connectedTenants = (
  state = [],
  action: ReducerActionConnectedTenants
) => {
  if (action.type === "SET_CONNECTED_TENANTS") return action.payload;
  else if (action.type === "LOGOUT") return [];

  return state;
};
