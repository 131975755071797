import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import logo from './logo.svg';
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Campaigns from "./views/Campaigns";
import Templates from "./views/Templates";
import TargetGroups from "./views/TargetGroups";
import Reports from "./views/Reports";
import Login from "./views/Login";
import "./App.css";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import { LinkContainer } from "react-router-bootstrap";
import axios from "axios";
import {
  setCurrentUser,
  setToken,
  setCurrentTenant,
  setCurrentTenantID,
  setConnectedTenants,
  logout,
  setCurrentCapabilities,
} from "./actions";
import { useSelector, useDispatch } from "react-redux";
import ReducerState from "./types/ReducerState";
import { default as UserType } from "./types/models/User";
import Tenant from "./types/models/Tenant";
import { useEffectOnce } from "./utils/ReactHelper";
import Toaster from "./components/UI/Toaster";
import i18next from "i18next";
import Enum from "./types/Enum";
import {
  clearEnums,
  getEnumKey,
  getEnumValue,
  registerEnum,
} from "./utils/Enum";
import TargetGroup from "./views/TargetGroup";

import Sidebar from "./components/UI/Sidebar/Sidebar";
import Header from "./components/UI/Header/Header";
import Template from "./views/Template";
import Campaign from "./views/Campaign";
import Domains from "./views/Domains";
import Domain from "./views/Domain";
import Servers from "./views/Servers";
import Server from "./views/Server";
import Report from "./views/Report";
import Profile from "./views/Profile";
import { toast } from "react-hot-toast";
import { saveStateToLocalStorage } from "./utils/LocalStorageState";
import Users from "./views/Users";
import User from "./views/User";
import UserCapability from "./types/models/UserCapability";
import AccessDenied from "./views/AccessDenied";
import Tenants from "./views/Tenants";
import AdministrationDashboard from "./views/AdministrationDashboard";
//import LocalStorageState from "./types/LocalStorageState";
//import { disposeEmitNodes } from "typescript";
// import { currentTenantID } from "./reducers/loggedInState";

type BasicInfoResponse = {
  current_user: UserType | null;
  current_tenant: Tenant | null;
  connected_tenants: Tenant[];
  user_capabilities: UserCapability[];
  enums: {
    [key: string]: Enum;
  };
};

function App() {
  const [searchParams] = useSearchParams();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [currentTenantName, setCurrentTenantName] = useState("");
  const token = useSelector((state: ReducerState) => state.token);
  const currentTenantID = useSelector(
    (state: ReducerState) => state.currentTenantID
  );
  const currentUser = useSelector((state: ReducerState) => state.currentUser);
  // const currentTenant = useSelector(
  //   (state: ReducerState) => state.currentTenant
  // );
  // const connectedTenants = useSelector(
  //   (state: ReducerState) => state.connectedTenants
  // );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const urlToken = searchParams.get("sso-token") ?? null;
  const urlTenantId = searchParams.get("sso-tenant-id") ?? null;

  // URL has "sso-token" (i.e. coming from SSO callback) --> remove from URL, request new token and store it
  useEffectOnce(() => {
    if (urlToken && urlTenantId) {
      setHasLoaded(false);
      axios
        .post<{
          token: string | null;
        }>(
          "tenants/" + urlTenantId + "/refresh-token",
          {
            token: urlToken,
          },
          {
            headers: {
              Authorization: "Bearer " + urlToken,
            },
          }
        )
        .then((response) => {
          dispatch(setToken(response.data.token ?? null));
          dispatch(setCurrentTenantID(urlTenantId ?? null));
          setHasLoaded(true);
          navigate("/");
        })
        .catch(() => {
          dispatch(setToken(null));
          dispatch(setCurrentTenantID(null));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlToken, urlTenantId]);

  // fetch basic-info
  useEffect(() => {
    setHasLoaded(false);
    const url = currentTenantID
      ? "/tenants/" + currentTenantID + "/basic-info"
      : "/basic-info";
    axios
      .get<BasicInfoResponse>(url)
      .then((response) => {
        // set enums
        clearEnums();
        for (const enumName in response.data.enums) {
          registerEnum(enumName, response.data.enums[enumName]);
        }
        // set locale
        if (response.data.current_user?.locale) {
          const locale = getEnumValue(
            "Locale",
            response.data.current_user.locale
          );
          if (locale) {
            i18next.changeLanguage(locale);
          }
        } else {
          i18next.changeLanguage("de");
        }

        dispatch(setCurrentUser(response.data.current_user));
        dispatch(setCurrentCapabilities(response.data.user_capabilities));
        dispatch(setCurrentTenant(response.data.current_tenant));
        dispatch(setConnectedTenants(response.data.connected_tenants));

        setHasLoaded(true);
        if (response.data.current_tenant?.name)
          setCurrentTenantName(response.data.current_tenant.name);
      })
      .catch(() => {
        dispatch(setCurrentUser(null));
        dispatch(setCurrentTenant(null));
        dispatch(setConnectedTenants([]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const LogoutHandler = () => {
    axios
      .post("/user/logout", { all: true })
      .then((response) => {
        //
      })
      .catch((error) => {
        toast(i18next.t("Error"));
      });
    dispatch(logout());
    saveStateToLocalStorage({});
  };

  // for mobile
  const [sidebarVisability, setSidebarVisability] = useState(false);
  const showSidebarHandler = () => {
    setSidebarVisability(true);
  };
  const hideSidebarHandler = () => {
    setSidebarVisability(false);
  };

  const checkCapabilitiesForViewReports = (
    capabilities: UserCapability[] | undefined
  ) => {
    if (!capabilities) return;
    const allowedCapabilities = [
      getEnumKey("UserCapabilityType", "all"),
      getEnumKey("UserCapabilityType", "manageTenant"),
      getEnumKey("UserCapabilityType", "viewTenantReports"),
      getEnumKey("UserCapabilityType", "manageCampaign"),
      getEnumKey("UserCapabilityType", "viewCampaignReports"),
    ];
    return capabilities.some((capability) =>
      allowedCapabilities.includes(capability.capability_type)
    );
  };
  const checkCapabilitiesForManageCampaign = (
    capabilities: UserCapability[] | undefined
  ) => {
    if (!capabilities) return;
    const allowedCapabilities = [
      getEnumKey("UserCapabilityType", "all"),
      getEnumKey("UserCapabilityType", "manageTenant"),
      getEnumKey("UserCapabilityType", "manageCampaign"),
    ];
    return capabilities.some((capability) =>
      allowedCapabilities.includes(capability.capability_type)
    );
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{i18next.t("Social Phishing Toolkit")}</title>
      </Helmet>
      {token && hasLoaded ? (
        <div
          className="flex w-full h-full bg-primaryLightGrey
          dark:bg-darkModeDarkBlue"
        >
          <Sidebar
            className={sidebarVisability ? "block" : "hidden"}
            hideSidebar={hideSidebarHandler}
            logo={currentTenantName}
          ></Sidebar>
          <div className="flex w-full h-screen flex-col">
            <Header
              showSidebar={showSidebarHandler}
              currentUser={currentUser}
              logoutHandler={LogoutHandler}
            ></Header>
            <main className="w-full h-full overflow-scroll px-0 lg:px-6 max-w-fullscreen lg:max-w-[1440px] dark:text-white">
              <Routes>
                <Route path="/login" element={<Login />} />
                {/* <Route path="/" element={<Dashboard />} /> */}
                <Route path="/profile" element={<Profile />}></Route>

                {/* only for capabilities all, manageTenant, manageCampaign, viewTenantReports, viewCampaignReports */}
                {checkCapabilitiesForViewReports(
                  currentUser?.user_capabilities_of_current_tenant
                ) ? (
                  <>
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/reports/:id" element={<Report />} />
                  </>
                ) : (
                  <Route path="/access-denied" element={<AccessDenied />} />
                )}

                {/* only for capabilities all, manageTenant, manageCampaign */}
                {checkCapabilitiesForManageCampaign(
                  currentUser?.user_capabilities_of_current_tenant
                ) ? (
                  <>
                    <Route path="/campaigns" element={<Campaigns />} />
                    <Route
                      path="/campaigns/create/"
                      element={<Campaign isCreate={true} />}
                    />
                    <Route
                      path="/campaigns/:id"
                      element={<Campaign isCreate={false} />}
                    />
                    <Route path="/templates" element={<Templates />} />
                    <Route
                      path="/templates/create"
                      element={<Template isCreate={true} />}
                    />
                    <Route
                      path="/templates/:id"
                      element={<Template isCreate={false} />}
                    />
                    <Route path="/target-groups" element={<TargetGroups />} />
                    <Route
                      path="/target-groups/create"
                      element={<TargetGroup isCreate={true} />}
                    />
                    <Route
                      path="/target-groups/:id"
                      element={<TargetGroup isCreate={false} />}
                    />
                    <Route path="/domains" element={<Domains />} />
                    <Route
                      path="/domains/create"
                      element={<Domain isCreate={true} />}
                    />
                    <Route
                      path="/domains/:id"
                      element={<Domain isCreate={false} />}
                    />
                    <Route path="/servers" element={<Servers />} />
                    <Route
                      path="/servers/create"
                      element={<Server isCreate={true} />}
                    />
                    <Route
                      path="/servers/:id"
                      element={<Server isCreate={false} />}
                    />
                  </>
                ) : (
                  <Route path="/access-denied" element={<AccessDenied />} />
                )}

                {currentUser?.is_admin_in_current_tenant ? (
                  <>
                    {/* <Route path="/users" element={<Users />} /> */}
                    <Route path="/admin-dashboard" element={<Users />} />
                    <Route
                      path="/users/:id"
                      element={<User isCreate={false} />}
                    />
                    {/* <Route path="/tenants" element={<Tenants />} /> */}
                    {/* <Route
                      path="/admin-dashboard"
                      element={<AdministrationDashboard />}
                    /> */}
                  </>
                ) : (
                  <Route path="/access-denied" element={<AccessDenied />} />
                )}
              </Routes>
            </main>
          </div>
        </div>
      ) : !hasLoaded ? (
        <>
          <div className="flex h-screen w-full justify-center items-center">
            <div role="alert" aria-busy="true" className="loading"></div>
          </div>
        </>
      ) : (
        <>
          <Login />
        </>
      )}
      <Toaster />
    </HelmetProvider>
  );
}

export default App;
