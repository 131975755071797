import i18next from "i18next";
import Target from "../../types/models/Target";
import { Dispatch, SetStateAction, useEffect } from "react";

interface Props {
  isEdited: boolean;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  editedTarget: Target;
  setEditedTarget: (field: string, value: number | string) => void;
  onSaveTarget: () => void;
}
const spanClass = "block md:hidden absolute t-0 l-0";
const inputClass =
  "border-t-0 border-r-0 border-l-0 border-b border-solid border-primaryGrey focus:outline-none dark:bg-darkModeBlue dark:text-white";

const TargetEditing = ({
  isEdited,
  setIsEdited,
  editedTarget,
  setEditedTarget,
  onSaveTarget,
}: Props) => {
  const resetEditedTarget = () => {
    setEditedTarget("salutation", 1);
    setEditedTarget("first_name", "");
    setEditedTarget("last_name", "");
    setEditedTarget("company_name", "");
    setEditedTarget("email", "");
    setEditedTarget("phone", "");
  };
  useEffect(() => {
    setEditedTarget("salutation", 1); // set default salutation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <form
        id="add-new-target"
        onSubmit={(e) => {
          e.preventDefault();
          onSaveTarget();
        }}
      ></form>
      <div
        className={`flex border-dotted border-1 border-primaryDarkGrey m-4
          text-center dark:text-white ${
            // isAddingNewTarget
            isEdited ? "bg-white dark:bg-darkModeBlue" : "bg-primaryLightGrey"
          }
        `}
      >
        <button
          className={`${
            isEdited && "hidden"
          } w-full p-4 border-0 dark:bg-darkModeDarkBlue dark:text-white`}
          onClick={() => setIsEdited(true)}
        >
          <i className="fa fa-plus pr-2" aria-hidden="true"></i>{" "}
          {i18next.t("Create recipient")}
        </button>
        <div
          className={`flex w-full gap-x-2 justify-around items-center px-2 py-6 mb-0 ${
            !isEdited && "hidden "
          }`}
        >
          <div className="md:w-[10%] text-left">
            <span className={spanClass}>{i18next.t("Salutation")}</span>

            <select
              name="salutation"
              id="salutation"
              className={`${inputClass} custom-select`}
              form="add-new-target"
              value={
                editedTarget.salutation !== undefined
                  ? editedTarget.salutation
                  : 1
              }
              onChange={(e) => {
                setEditedTarget("salutation", +e.target.value);
              }}
            >
              <option value="1">{i18next.t("Mr")}</option>
              <option value="0">{i18next.t("Mrs")}</option>
            </select>
          </div>
          <div className="md:w-[10%] text-left">
            <span className={spanClass}>{i18next.t("Firstname")}</span>
            <input
              className={`${inputClass} w-11/12`}
              type="text"
              name="first_name"
              form="add-new-target"
              value={editedTarget.first_name ? editedTarget.first_name : ""}
              placeholder={i18next.t("Firstname")}
              onChange={(e) => {
                setEditedTarget("first_name", e.target.value);
              }}
            />
          </div>
          <div className="md:w-[10%] text-left">
            <span className={spanClass}>{i18next.t("Lastname")}</span>
            <input
              className={`${inputClass} w-11/12`}
              type="text"
              name="last_name"
              placeholder={i18next.t("Lastname")}
              form="add-new-target"
              value={editedTarget.last_name ? editedTarget.last_name : ""}
              onChange={(e) => {
                setEditedTarget("last_name", e.target.value);
              }}
            />
          </div>
          <div className="md:w-[25%] text-left">
            <span className={spanClass}>{i18next.t("Company")}</span>
            <input
              className={inputClass}
              type="text"
              name="company_name"
              placeholder="Firma"
              form="add-new-target"
              value={editedTarget.company_name ? editedTarget.company_name : ""}
              onChange={(e) => {
                setEditedTarget("company_name", e.target.value);
              }}
            />
          </div>
          <div className="md:w-[20%] text-left">
            <span className={spanClass}>{i18next.t("E-Mail")}</span>
            <input
              className={inputClass}
              type="text"
              name="email"
              placeholder="mail@domain.com"
              form="add-new-target"
              value={editedTarget.email ? editedTarget.email : ""}
              onChange={(e) => {
                setEditedTarget("email", e.target.value);
              }}
            />
          </div>
          <div className="md:w-[15%]">
            <span className={spanClass}>{i18next.t("Phone")}</span>
            <input
              className={inputClass}
              type="text"
              name="phone"
              placeholder="+49 123 00000000"
              form="add-new-target"
              value={editedTarget.phone ? editedTarget.phone : ""}
              onChange={(e) => {
                setEditedTarget("phone", e.target.value);
              }}
            />
          </div>
          <div className="md:w-[10%] flex justify-end">
            <button
              className="border-0 bg-transparent p-0"
              type="reset"
              form="add-new-target"
              onClick={() => {
                setIsEdited(false);
                resetEditedTarget();
                // setTargetToEdit(false);
              }}
            >
              <i
                className="fa fa-close text-primaryRed text-lg pl-3"
                aria-hidden="true"
              ></i>
            </button>
            <button
              className="border-0 bg-transparent p-0 mr-2"
              type="submit"
              form="add-new-target"
            >
              <i
                className="fa fa-check text-primaryGreen text-lg pl-3"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default TargetEditing;
