import axios from "axios";
import { store } from "..";
import { logout } from "../actions";
import toast from "react-hot-toast";

export const initAxios = () => {
  // axios defaults
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.baseURL = process.env.REACT_APP_API_URI;

  // interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch(logout());

        toast("Your session is expired. Please log in again.");

        return Promise.resolve();
      }

      return Promise.reject(error);
    }
  );
};
