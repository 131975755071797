import i18next from "i18next";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import InputValidationError from "../UI/InputValidationError";
import FormErrorResponse from "../../types/FormErrorResponse";
import ConfirmationModal from "../UI/ConfirmationModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  name: string | undefined;
  onDeleteHandler: () => void;
  errors: FormErrorResponse | null;
  isCreate: boolean;
  setTemplate: (arg0: string, arg1: string) => void;
  currentId: string | undefined;
}
const extractFieldErrorsFromErrorBag = (
  errorBag: FormErrorResponse | null,
  fieldName: string
): string[] => {
  if (!errorBag) return [];

  const errorTexts = errorBag?.[fieldName];

  return errorTexts || [];
};
const removeFieldErrorsFromErrorBag = (
  errorBag: FormErrorResponse | null,
  fieldName: string
): void => {
  delete errorBag?.[fieldName];
};

const TemplateDescription = ({
  name,
  onDeleteHandler,
  errors,
  isCreate,
  setTemplate,
  currentId,
}: Props) => {
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          onDeleteHandler();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      {/* <dl>
        <dt>{i18next.t("ID")}</dt>
        <dd>
          {currentId
            ? currentId
            : "(" + i18next.t("Will be set after creation.") + ")"}
        </dd>
      </dl> */}
      <Form.Group className="mb-4 flex items-end" controlId="formName">
        <Form.Group className="w-1/3">
          <Form.Label className="font-bold " htmlFor="name">
            {i18next.t("Name")}
          </Form.Label>
          <Form.Control
            id="name"
            type="text"
            value={name ? name : ""}
            className="flex w-full bg-transparent border-t-0
              border-l-0 border-r-0 rounded-none border-primaryDarkGrey dark:text-white
              focus:border-black focus:shadow-none outline-none focus-reset"
            placeholder={i18next.t("Template's name")}
            onChange={(e) => {
              setTemplate("name", e.target.value);
              removeFieldErrorsFromErrorBag(errors, "name");
            }}
            isInvalid={
              extractFieldErrorsFromErrorBag(errors, "name").length > 0
            }
          />
          <Form.Group>
            <InputValidationError
              errors={errors}
              fieldName="name"
            ></InputValidationError>
            <Form.Text className="text-muted px-2">
              {i18next.t("The value has to be unique.")}
            </Form.Text>
          </Form.Group>
        </Form.Group>
        <ButtonGroup className="ml-auto max-w-min">
          <Button
            type="submit"
            className="bg-primaryGreen border-primaryGreen px-5 min-w-max"
          >
            <i
              className="fa fa-save text-white text-lg pr-2"
              aria-hidden="true"
            ></i>
            {i18next.t("Save")}
          </Button>
          {!isCreate && (
            <>
              <Button
                className="bg-primaryRed border-primaryRed px-5 min-w-max"
                variant="danger"
                onClick={() => {
                  setShowDeletionConfirmationModal(true);
                }}
              >
                <i
                  className="fa fa-trash text-white text-lg pr-2"
                  aria-hidden="true"
                ></i>
                {i18next.t("Remove")}
              </Button>
            </>
          )}
        </ButtonGroup>
      </Form.Group>
    </>
  );
};
export default TemplateDescription;
