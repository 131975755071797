import i18next from "i18next";
import React, { Dispatch } from "react";
import { SetStateAction } from "react";
import Campaign from "../../../types/models/DateiCampaign";

interface propsType {
  currentStep: number;
  campaignInfo: Campaign;
  setCurrentStepHandler: Dispatch<SetStateAction<number>>;
}

const Navigation = ({
  currentStep,
  campaignInfo,
  setCurrentStepHandler,
}: propsType) => {
  const steps = [
    {
      step: 1,
      name: i18next.t("Common"),
      status: campaignInfo.name ? true : false,
    },
    {
      step: 2,
      name: i18next.t("Design"),
      status: campaignInfo.template_id ? true : false,
    },
    {
      step: 3,
      name: i18next.t("Settings"),
      status: true,
    },
  ];

  return (
    <>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <button
            key={index}
            onClick={() => setCurrentStepHandler(index + 1)}
            className="group grow flex flex-col justify-center items-center
              cursor-pointer border-0 bg-transparent text-white"
          >
            <div
              className={`rounded-full
                text-3xl font-bold w-12 h-12
                justify-center flex items-center
                border-solid
                ${
                  currentStep === index + 1
                    ? " bg-white drop-shadow-md text-primaryBlue border-2 border-primaryBlue dark:bg-darkModeBlue dark:text-primaryLightGrey dark:border-primaryLightGrey"
                    : " bg-primaryGre group-hover:drop-shadow-md "
                }
                ${
                  currentStep > index + 1 && steps[index].status
                    ? " bg-white dark:bg-darkModeLighterBlue text-primaryGreen border-2 border-primaryGreen"
                    : currentStep > index + 1 && !steps[index].status
                    ? " bg-white dark:bg-darkModeLighterBlue text-primaryRed border-2 border-primaryRed"
                    : ""
                }
                ${
                  currentStep < index + 1
                    ? " text-white dark:text-darkModeBlue bg-primaryGrey"
                    : ""
                }
              `}
            >
              {currentStep > index + 1 && steps[index].status ? (
                <i className="fa fa-check" aria-hidden="true"></i>
              ) : currentStep > index + 1 && !steps[index].status ? (
                <i className="fa fa-exclamation" aria-hidden="true"></i>
              ) : (
                step.step
              )}
            </div>
            <div
              className={`${
                currentStep === index + 1
                  ? "text-primaryBlue dark:text-primaryLightGrey"
                  : "text-primaryGrey dark:text-primaryDarkGrey"
              } text-sm mt-2`}
            >
              {step.name}
            </div>
          </button>
          {index < steps.length - 1 && (
            <div
              className={`${
                currentStep > index + 1
                  ? "border-primaryBlue"
                  : "border-primaryDarkGrey opacity-20"
              } grow-[2] border-0 border-b border-solid mt-[-20px]`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export default Navigation;
