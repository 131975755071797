import i18next from "i18next";
import ConfirmationModal from "../../UI/ConfirmationModal";
import axios from "axios";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";
import toast from "react-hot-toast";

interface Props {
  getUsers: any;
  currentUserID: string;
  isVisible: boolean;
  setIsVisible: any;
}
const UserRemoving = ({
  currentUserID,
  getUsers,
  isVisible,
  setIsVisible,
}: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const removeUser = () => {
    let url = `/tenants/${currentTenant?.id}/users/${currentUserID}`;

    axios
      .delete(url)
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));

        getUsers();
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  return (
    <ConfirmationModal
      show={isVisible}
      closeCallback={() => {
        setIsVisible(false);
      }}
      confirmCallback={() => {
        removeUser();
      }}
      title={i18next.t("Remove record")}
      text={i18next.t("This action cannot be undone.")}
      confirmButtonText={i18next.t("Remove")}
      confirmButtonVariant="danger"
    />
  );
};
export default UserRemoving;
