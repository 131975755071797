class MenuItem {
  label: string;
  link: string;
  icon?: string;
  children?: MenuItem[];
  className?: string;

  constructor(
    menuItemLabel: string,
    menuItemLink: string,
    menuItemIcon?: string,
    menuChildren?: MenuItem[]
  ) {
    this.label = menuItemLabel;
    this.link = menuItemLink;
    this.icon = menuItemIcon;
    this.children = menuChildren;
  }
}

export default MenuItem;
