import i18next from "i18next";
import User from "../../../types/models/User";
import GenericCallback from "../../../types/GenericCallback";
import { useNavigate } from "react-router-dom";

interface propsType {
  currentUser: User | null;
  showSidebar: GenericCallback;
  logoutHandler: GenericCallback;
}

const Header = ({ currentUser, showSidebar, logoutHandler }: propsType) => {
  const userFirstName = currentUser?.first_name;
  const userLastName = currentUser?.last_name;

  const showSideBar = (event: React.MouseEvent) => {
    showSidebar();
  };
  const navigate = useNavigate();

  return (
    <header
      className="flex flex-row justify-between items-baseline
        px-8 py-2 text-gray-700 dark:text-white max-w-full"
    >
      <div onClick={showSideBar}>
        <i
          className="fa fa-bars mr-4 p-2 bg-gray-300 rounded-full cursor-pointer xl:hidden"
          aria-hidden="true"
        ></i>
        {i18next.t("Hello")} {userFirstName} {userLastName}!
      </div>
      <div className="group relative z-9 cursor-pointer pb-2">
        <i
          className="fa fa-user-circle-o text-3xl md:text-2xl pr-1"
          aria-hidden="true"
        ></i>{" "}
        <span className="hidden md:inline">
          {userFirstName} {userLastName}
        </span>
        <ul
          className="
            hidden group-hover:block absolute w-full p-0
            bg-white dark:bg-darkModeBlue rounded-[.2rem]
            z-10 px-2 m-0 text-l md:text-sm top-100 right-0
            shadow-sm min-w-max"
        >
          <li
            className="hover:[&>a]:text-primaryRed dark:hover:[&>a]:text-white p-2
              border-solid border-0 border-b border-primaryGrey list-none"
          >
            <i className="fa fa-eye pr-2" aria-hidden="true"></i>
            <span
              onClick={() => {
                navigate("/profile/");
              }}
              className="no-underline text-primaryBlue dark:text-primaryLightGrey"
            >
              {i18next.t("Show profile")}
            </span>
          </li>
          <li
            className="hover:[&>a]:text-primaryRed dark:hover:[&>a]:text-white p-2
              border-solid border-0 border-primaryGrey list-none"
            onClick={logoutHandler}
          >
            <i className="fa fa-sign-out pr-2" aria-hidden="true"></i>
            <a
              href="/#"
              className="no-underline text-primaryBlue dark:text-primaryLightGrey"
            >
              {i18next.t("Logout")}
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};
export default Header;
