import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { toast } from "react-hot-toast";
import axios from "axios";
import { default as TemplateType } from "../types/models/Template";
import i18next from "i18next";
import useSave from "../hooks/use-save";
import FormErrorResponse from "../types/FormErrorResponse";
import TemplateForNotificationCampagne from "../components/Template/TemplateForNotificationCampagne";
import ChoosingTemplateType from "../components/Template/ChoosingTemplateType";
import { getEnumKey } from "../utils/Enum";
import TemplateForFileCampaign from "../components/Template/TemplateForFileCampaign";

const Template = ({
  isCreate,
  templateToEditId, // if template is edited by step 3 in campaign's creating
  isTemplateForMessageCampaign,
}: {
  isCreate: boolean;
  templateToEditId?: string;
  isTemplateForMessageCampaign?: boolean;
}) => {
  const { id } = useParams();
  const currentId = templateToEditId ? templateToEditId : !isCreate ? id : "";

  const navigate = useNavigate();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const [template, setTemplate] = useState<TemplateType>();
  const [templateType, setTemplateType] = useState<number | undefined>(
    undefined
  );
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errors, setErrors] = useState<FormErrorResponse | null>(null);

  const [isContentBlocked, setIsContentBlocked] = useState<boolean>(false);
  const [contentStatus, setContentStatus] = useState<string>("release"); // release, lock, locked

  const fetchItem = () => {
    if (isCreate || !currentId || !currentTenant?.id) return;
    setHasLoaded(false);
    axios
      .get<TemplateType>(
        "/tenants/" + currentTenant?.id + "/templates/" + currentId
      )
      .then((response) => {
        if (response.data) setTemplate(response.data);
        setHasLoaded(true);
        setTemplateType(response.data.campaign_type);
      })
      .catch(() => {
        toast(i18next.t("Error loading page"));

        navigate("/templates");
      });
  };
  const remove = () => {
    if (!currentId || !currentTenant?.id) return;

    axios
      .delete("/tenants/" + currentTenant?.id + "/templates/" + currentId)
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));
        setIsContentBlocked(false);
        navigate("/templates");
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  const [savedData, error, sendRequest] = useSave(
    currentId ? "/templates/" + currentId : "/templates/",
    template,
    isCreate
  );
  useEffect(() => {
    if (savedData && !error) setTemplate(savedData);
    if (error) setErrors(error);
  }, [savedData, error]);

  const sendLockRequest = () => {
    axios
      .post("/content-lock/lock", {
        model_id: currentId,
        model_type: "App\\Models\\Template",
      })
      .then((response) => {
        setContentStatus("lock");
      })
      .catch(() => {
        setContentStatus("locked");
      });
  };
  const sendReleaseRequest = () => {
    axios
      .post("/content-lock/release", {
        model_id: currentId,
        model_type: "App\\Models\\Template",
      })
      .then((response) => {
        setContentStatus("release");
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };
  useEffect(() => {
    if (!isCreate)
      if (isContentBlocked) {
        sendLockRequest();
      } else {
        sendReleaseRequest();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentBlocked]);

  useEffect(() => {
    fetchItem();
    return () => {
      if (!isCreate) {
        sendReleaseRequest();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // if template is created by step 3 in campaign's creating
  // select the template type depending on the type of campaign
  useEffect(() => {
    if (typeof isTemplateForMessageCampaign === "boolean") {
      const msgType = getEnumKey("CampaignType", "message");
      const fileType = getEnumKey("CampaignType", "file");
      isTemplateForMessageCampaign
        ? setTemplateType(msgType)
        : setTemplateType(fileType);
    } else {
      setTemplateType(undefined);
    }
  }, []);
  useEffect(() => {
    isCreate ? setTemplate(undefined) : fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);

  return (
    <>
      {isCreate ? (
        <>
          {templateType === undefined ? (
            <ChoosingTemplateType setTemplateType={setTemplateType} />
          ) : null}
          {templateType === getEnumKey("CampaignType", "message") ? (
            <TemplateForNotificationCampagne
              isCreate={isCreate}
              template={template}
              setTemplate={setTemplate}
              setIsContentBlocked={setIsContentBlocked}
              contentStatus={contentStatus}
              setHasLoaded={setHasLoaded}
              fetchingTemplateHandler={fetchItem}
              savingTemplateHandler={sendRequest}
              removingTemplateHandler={remove}
              errors={errors}
            />
          ) : null}
          {templateType === getEnumKey("CampaignType", "file") ? (
            <TemplateForFileCampaign
              isCreate={isCreate}
              template={template}
              setTemplate={setTemplate}
              setIsContentBlocked={setIsContentBlocked}
              contentStatus={contentStatus}
              setHasLoaded={setHasLoaded}
              fetchingTemplateHandler={fetchItem}
              savingTemplateHandler={sendRequest}
              removingTemplateHandler={remove}
              errors={errors}
            />
          ) : null}
        </>
      ) : (
        <>
          {hasLoaded ? (
            templateType ? (
              <TemplateForFileCampaign
                isCreate={isCreate}
                template={template}
                setTemplate={setTemplate}
                setIsContentBlocked={setIsContentBlocked}
                contentStatus={contentStatus}
                setHasLoaded={setHasLoaded}
                fetchingTemplateHandler={fetchItem}
                savingTemplateHandler={sendRequest}
                removingTemplateHandler={remove}
                errors={errors}
              />
            ) : (
              <TemplateForNotificationCampagne
                isCreate={isCreate}
                template={template}
                setTemplate={setTemplate}
                setIsContentBlocked={setIsContentBlocked}
                contentStatus={contentStatus}
                setHasLoaded={setHasLoaded}
                fetchingTemplateHandler={fetchItem}
                savingTemplateHandler={sendRequest}
                removingTemplateHandler={remove}
                errors={errors}
              />
            )
          ) : (
            <div className="max-w-[660px]">
              <div className="loading "></div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Template;
