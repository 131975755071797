import {
  token,
  currentUser,
  currentTenant,
  currentTenantID,
  connectedTenants,
  currentCapabilities,
} from "./loggedInState";
import { combineReducers } from "@reduxjs/toolkit";

const allReducer = combineReducers({
  token,
  currentUser,
  currentTenant,
  currentTenantID,
  connectedTenants,
  currentCapabilities,
});

export default allReducer;
