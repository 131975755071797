import i18next from "i18next";
import axios from "axios";
import { toast } from "react-hot-toast";
import Wrapper from "../UI/Wrapper";
import Button from "../UI/Button";
import Search from "../UI/Search";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import { default as TargetGroupType } from "../../types/models/TargetGroup";
import Pagination from "../UI/Pagination";
import useClone from "../../hooks/use-clone";
import TargetGroup from "../../views/TargetGroup";

interface propsType {
  currentTargetGroup?: string;
  asideVisabilityHandler: Dispatch<SetStateAction<boolean>>;
  onInputChangeHandler(
    field: string,
    value: string | boolean | { id: string; name: string }
  ): void;
}

const StepTargetGroups = ({
  currentTargetGroup,
  onInputChangeHandler,
  asideVisabilityHandler,
}: propsType) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const [targetGroups, setTargetGroups] = useState<TargetGroupType[] | null>(
    []
  );
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [targetGroupIdForClone, setTargetGroupIdForClone] =
    useState<string>("");

  const [editedTargetGroup, setEditedTargetGroup] = useState<string>("");
  const [creatingNewTargetGroup, setCreatingNewTargetGroup] =
    useState<boolean>(false);
  const fetchTargetGroups = (page: number = 1) => {
    if (!currentTenant?.id) return;
    setHasLoaded(false);

    var url =
      "/tenants/" +
      currentTenant?.id +
      "/target-groups?sort=updated-desc&page=" +
      page;
    if (searchValue) url += "&search=" + searchValue;
    axios
      .get<{
        current_page: number;
        data: TargetGroupType[];
        last_page: number;
        per_page: number;
        total: number;
      }>(url)
      .then((response) => {
        setTargetGroups(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));

        // resetData();
      })
      .finally(() => {
        setHasLoaded(true);
      });
  };
  useEffect(() => {
    fetchTargetGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;
    const name = event.target.getAttribute("data-groupname");

    onInputChangeHandler("target_group", { id, name: name ? name : "" });
    onInputChangeHandler("target_group_id", id);
  };

  const cloneTargetGroup = useClone(
    currentTenant,
    "/target-groups/",
    targetGroupIdForClone
  );

  const reset = () => {
    setEditedTargetGroup("");
    setCreatingNewTargetGroup(false);
    setTargetGroups(null);
    setHasLoaded(false);
    fetchTargetGroups();
    asideVisabilityHandler(true);
  };

  return (
    <>
      {!editedTargetGroup && !creatingNewTargetGroup ? (
        <>
          <div className="flex">
            <Button
              className="mr-auto uppercase"
              onClick={() => {
                // navigate("/target-groups/create");

                setCreatingNewTargetGroup(true);
                asideVisabilityHandler(false);
              }}
            >
              <i className="fa fa-plus mr-6" aria-hidden="true"></i>
              {i18next.t("Create new list")}
            </Button>
            <Search
              className="ml-auto"
              placeholder={i18next.t("Search target groups")}
              searchHandler={setSearchValue}
            ></Search>
          </div>
          {targetGroups?.length ? (
            <>
              <form action="" className="grid gap-6 grid-cols-2 mt-6">
                {targetGroups.map((group) => (
                  <div key={group.id}>
                    <input
                      id={group.id}
                      onChange={onChangeHandler}
                      className={`hidden target-list`}
                      type="radio"
                      name="targetgroups"
                      data-groupname={group.name}
                      checked={currentTargetGroup === group.id ? true : false}
                    />
                    <label
                      htmlFor={group.id}
                      className={`w-full cursor-pointer
                    border border-white dark:border-darkModeLighterBlue
                    rounded`}
                    >
                      <Wrapper className="text-primaryDarkGrey text-sm">
                        <div className="flex justify-between">
                          <span className="text-black dark:text-white text-base font-bold flex items-center">
                            <span className="radio h-4 w-4 mr-2 inline-block border rounded-full"></span>
                            {group.name}
                          </span>
                          <div className="flex gap-2">
                            <i
                              className="fa fa-files-o hover:scale-110"
                              aria-hidden="true"
                              onClick={() => {
                                setTargetGroupIdForClone(group.id);
                                cloneTargetGroup();
                                setTargetGroups(null);
                                fetchTargetGroups();
                              }}
                            ></i>
                            <i
                              className="fa fa-pencil hover:scale-110"
                              aria-hidden="true"
                              onClick={() => {
                                setEditedTargetGroup(group.id);
                                asideVisabilityHandler(false);
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="mt-4">
                          <i
                            className="fa fa-users mr-2"
                            aria-hidden="true"
                          ></i>
                          <span>
                            {i18next.t("Recipients")} {group.count}
                          </span>
                        </div>
                      </Wrapper>
                    </label>
                  </div>
                ))}
              </form>
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                totalCount={totalCount}
                nextCallback={() => {
                  fetchTargetGroups(currentPage + 1);
                }}
                backCallback={() => {
                  fetchTargetGroups(currentPage - 1);
                }}
              />
            </>
          ) : hasLoaded ? (
            <div className="mt-6">{i18next.t("No recipients available")}</div>
          ) : (
            <div className="mt-6">
              <div className="loading"></div>
            </div>
          )}
        </>
      ) : (
        <>
          <Button className="mb-4" onClick={reset}>
            <i className="fa fa-close mr-4"></i>
            {i18next.t("Finish editing")}
          </Button>
          {creatingNewTargetGroup ? (
            <TargetGroup isCreate={true} />
          ) : (
            <TargetGroup
              isCreate={false}
              targetGroupToEditId={editedTargetGroup}
            />
          )}
        </>
      )}
    </>
  );
};
export default StepTargetGroups;
