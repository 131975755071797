import i18next from "i18next";
import GenericCallback from "../../../types/GenericCallback";
import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from "../../../utils/LocalStorageState";
import LocalStorageState from "../../../types/LocalStorageState";

interface propsType {
  tabs: {
    value: number;
    onClick?: GenericCallback;
  }[];
  changeItemsPerPageHandler?: (arg0: number) => void;
  activeTab?: number;
  paginationName: string; // The pagination for each list can be read from local storage
}

const ListItemsPerPage = ({
  tabs,
  changeItemsPerPageHandler,
  activeTab,
  paginationName,
}: propsType) => {
  const initialLocalStorageState = loadStateFromLocalStorage();
  const itemsPerPageFromLocalStorage = initialLocalStorageState
    ? initialLocalStorageState[paginationName as keyof LocalStorageState]
    : null;
  const currentItemsPerPage = itemsPerPageFromLocalStorage
    ? +itemsPerPageFromLocalStorage
    : activeTab;

  return (
    <>
      <div className="flex text-primaryDarkGrey">
        {i18next.t("Results per page")}
        {tabs.map((number) => (
          <a
            key={number.value}
            href="/#"
            className={`text-primaryDarkGrey pl-2 text-g
              hover:text-primaryBlue hover:opacity-100
              ease-out duration-300
              ${
                number.value === currentItemsPerPage
                  ? "underline-offset-1"
                  : "no-underline opacity-60"
              }`}
            onClick={(e) => {
              e.preventDefault();
              if (changeItemsPerPageHandler)
                changeItemsPerPageHandler(number.value);
              saveStateToLocalStorage({
                ...initialLocalStorageState,
                [paginationName]: number.value.toString(),
              });
            }}
          >
            {number.value}
          </a>
        ))}
      </div>
    </>
  );
};
export default ListItemsPerPage;
