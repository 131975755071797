import Modal from "react-bootstrap/Modal";
import GenericCallback from "../../types/GenericCallback";
import i18next from "i18next";
import { ReactNode } from "react";
import Button from "./Button";

interface propsType {
  show: boolean;
  closeCallback: GenericCallback;
  confirmCallback: GenericCallback;
  title?: string;
  text?: string;
  confirmButtonText?: string;
  confirmButtonVariant: string;
  children?: ReactNode;
}

const ConfirmationModal = ({
  show,
  confirmCallback,
  closeCallback,
  title,
  text,
  confirmButtonText,
  confirmButtonVariant,
  children,
}: propsType) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        closeCallback();
      }}
      backdrop="static"
      keyboard={true}
    >
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      {text && <Modal.Body>{text}</Modal.Body>}
      {children && <Modal.Body>{children}</Modal.Body>}
      <Modal.Footer>
        <Button
          className="bg-primaryDarkGrey"
          onClick={() => {
            closeCallback();
          }}
        >
          {i18next.t("Close")}
        </Button>
        <Button
          onClick={() => {
            closeCallback();
            confirmCallback();
          }}
        >
          {confirmButtonText ? confirmButtonText : i18next.t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
