import i18next from "i18next";
import { getEnumKey } from "../../utils/Enum";
import { Dispatch, SetStateAction } from "react";
interface Props {
  setCampaignType: Dispatch<SetStateAction<number | undefined>>;
}

const ChoosingCampaignType = ({ setCampaignType }: Props) => {
  return (
    <>
      <div className="bg-white rounded-md px-4 py-8 mt-20">
        <div className="mb-8">{i18next.t("Choose the campaign type")}:</div>
        <div className="flex gap-4">
          <div
            className="p-8 py-16 rounded-md shadow text-white bg-primaryBlue no-underline
            transition-all ease-out duration-300 transform hover:bg-hoverBlue
            cursor-pointer"
            onClick={() => {
              setCampaignType(getEnumKey("CampaignType", "file"));
            }}
          >
            {i18next.t("Create Datei Campaign")}
          </div>
          <div
            className="p-8 py-16 rounded-md shadow text-white bg-primaryBlue no-underline
            transition-all ease-out duration-300 transform hover:bg-hoverBlue
            cursor-pointer"
            onClick={() => {
              setCampaignType(getEnumKey("CampaignType", "message"));
            }}
          >
            {i18next.t("Create Notification Campaign")}
          </div>
        </div>
      </div>
    </>
  );
};
export default ChoosingCampaignType;
