import { useNavigate } from "react-router-dom";
import ListItem from "../../UI/ListDisplay/ListItem";
import i18next from "i18next";
import User from "../../../types/models/User";

const dateToString = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const tmpMonth = dateObj.getMonth() + 1;
  const month = tmpMonth > 9 ? tmpMonth : "0" + tmpMonth;
  const day =
    dateObj.getDate() > 9 ? dateObj.getDate() : "0" + dateObj.getDate();
  // const hour =
  //   dateObj.getHours() > 9 ? dateObj.getHours() : "0" + dateObj.getHours();
  // const minute =
  //   dateObj.getMinutes() > 9
  //     ? dateObj.getMinutes()
  //     : "0" + dateObj.getMinutes();
  let string = `${day}.${month}.${year}`;
  return string;
};
interface Props {
  getUsers: any;
  users: User[];
  setShowDeletionConfirmationModal: any;
  setUserForDelete: any;
}

const Users = ({
  getUsers,
  users,
  setShowDeletionConfirmationModal,
  setUserForDelete,
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {users.length > 0 &&
        users.map((user, index) => (
          <ListItem
            className="p-4 pb-4"
            key={user.id}
            clickHandler={() => {
              navigate("/users/" + user.id);
            }}
            deleteHandler={() => {
              setShowDeletionConfirmationModal(true);
              setUserForDelete(user.id ? user.id : "");
            }}
          >
            <div className="w-1/3 flex flex-col">
              <h4 className="text-black dark:text-white">{user.name}</h4>
              <p className="flex gap-1 m-0">
                <span>{i18next.t("Created at")}</span>
                <span>{dateToString(user.created_at)}</span>
              </p>
            </div>
            <div className="flex gap-2 items-center ml-0 mr-auto">
              <span className="fa fa-envelope-o"></span>
              <span>{user.email}</span>
            </div>
          </ListItem>
        ))}
    </>
  );
};
export default Users;
