import i18next from "i18next";
import translationsDe from "../translations/de.json";

const init = () => {
  i18next.init({
    // lng set based on current user
    // lng: "de",
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: "en",
    // interpolation: {
    //   escapeValue: false // react already safes from xss
    // }
    // debug: true,
    returnNull: false,
    resources: {
      de: {
        translation: translationsDe,
      },
    },
  });
};

export { i18next, init };
