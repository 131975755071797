import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tenant from "../types/models/Tenant";
import axios from "axios";
import {
  setToken,
  setCurrentUser,
  setConnectedTenants,
  setCurrentTenantID,
} from "../actions";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import Wrapper from "../components/UI/Wrapper";
import { getEnumKey } from "../utils/Enum";

type AuthDataItem = {
  provider: string;
  label: string;
  url: string;
};

function Login() {
  const [email, setEmail] = useState("");
  const [stepEmailNextClicked, setStepEmailNextClicked] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenantAuthDataItems, setSelectedTenantAuthDataItems] =
    useState<AuthDataItem[]>([]);
  const [locale, setLocale] = useState<number | undefined>(
    getEnumKey("Locale", "de")
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // log out current user
    dispatch(setToken(null));
    dispatch(setCurrentTenantID(null));
    dispatch(setCurrentUser(null));
    dispatch(setConnectedTenants([]));
  }, [dispatch]);

  function handleEmailSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setStepEmailNextClicked(true);
    setIsProcessing(true);

    axios
      .get<Tenant[]>("user-tenants?email=" + encodeURIComponent(email))
      .then((response) => {
        setTenants(response.data);

        if (response.data.length === 1)
          setSelectedTenantAuthDataItems(
            getSelectedTenantAuthDataItemsFromTenantSelection(0, response.data)
          );
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }

  function getSelectedTenantAuthDataItemsFromTenantSelection(
    selectedIndex: number,
    tenants: Tenant[]
  ): AuthDataItem[] {
    let output: AuthDataItem[] = [];
    let tenant = tenants[selectedIndex];

    if (!tenant || !tenant.auth_providers) return output;

    for (const provider of tenant.auth_providers) {
      if (!tenant.auth_provider_labels || !tenant.auth_urls) continue;

      let providerData = {
        provider,
        label: tenant.auth_provider_labels[provider],
        url: tenant.auth_urls[provider],
      };
      if (providerData.label && providerData.url) output.push(providerData);
    }

    return output;
  }

  function reset() {
    setEmail("");
    setStepEmailNextClicked(false);
    setTenants([]);
    setSelectedTenantAuthDataItems([]);
  }

  const setNewLocale = (newLocale: string) => {
    i18next.changeLanguage(newLocale);
    setLocale(getEnumKey("Locale", newLocale));
  };

  return (
    <div
      className="p-20 dark:text-white min-h-[100vh]
        bg-primaryLightGrey"
    >
      <Wrapper className="w-full lg:w-1/2 mx-auto">
        <div className="flex gap-2 items-center w-full -mb-7 justify-end">
          <span
            className={`cursor-pointer ${
              getEnumKey("Locale", "de") === locale ? "opacity-1" : "opacity-50"
            }`}
            onClick={() => setNewLocale("de")}
          >
            DE
          </span>
          |
          <span
            className={`cursor-pointer ${
              getEnumKey("Locale", "en") === locale ? "opacity-1" : "opacity-50"
            }`}
            onClick={() => setNewLocale("en")}
          >
            EN
          </span>
        </div>
        <h1>{i18next.t("Login")}</h1>
        {tenants.length === 0 ? (
          <>
            {!isProcessing && stepEmailNextClicked && (
              <div
                className="bg-primaryRed rounded-md p-3 opacity-50 text-white
                  my-3 shadow-sm"
              >
                {i18next.t(
                  "No identity providers were found for this account."
                )}
              </div>
            )}
            <Form onSubmit={handleEmailSubmit}>
              <Form.Group className="mb-4">
                <Form.Label>{i18next.t("E-mail address")}</Form.Label>
                <Form.Control
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="no-shadow focus:outline-none focus:border-b-primaryDarkGrey
                    focus:shadow-none rounded-none shadow-none
                    border-t-0 border-l-0 border-r-0 w-full
                    border-b border-b-solid border-b-primaryGrey"
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="bg-primaryBlue dark:bg-darkModeBlue text-white
                  rounded-[.2rem] py-2 px-6 ease-out duration-300 transition-all
                  shadow-sm border-0 hover:scale-105 cursor-pointer"
              >
                {i18next.t("Next")}
              </Button>
            </Form>
          </>
        ) : (
          <>
            {tenants.length > 1 ? (
              <>
                <p>
                  Available tenants and identity providers for account "{email}
                  ":
                </p>
                <Row>
                  <Col lg={6}>
                    <Form.Select
                      aria-label="Tenant"
                      className="mb-4 col-lg-6"
                      onChange={(e) =>
                        setSelectedTenantAuthDataItems(
                          e.target.value === ""
                            ? []
                            : getSelectedTenantAuthDataItemsFromTenantSelection(
                                parseInt(e.target.value),
                                tenants
                              )
                        )
                      }
                    >
                      <option value="">
                        {i18next.t("Please select a tenant")}
                      </option>
                      {tenants.map((tenant, i) => (
                        <option value={i} key={tenant.id}>
                          {tenant.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <p>
                  Available identity providers for account "{email}" on tenant "
                  {tenants[0].name}":
                </p>
              </>
            )}
            <p>
              <Button
                variant="secondary"
                onClick={reset}
                className="bg-primaryGrey dark:bg-darkModeBlue text-white
                  rounded-[.2rem] py-2 px-6 me-2 mb-4 ease-out duration-300
                  transition-all shadow-sm border-0 hover:scale-105 cursor-pointer"
              >
                {i18next.t("Back")}
              </Button>
              {selectedTenantAuthDataItems.map((selectedTenantAuthDataItem) => (
                <Button
                  variant="primary"
                  as="a"
                  href={selectedTenantAuthDataItem.url}
                  className="bg-primaryBlue dark:bg-darkModeBlue text-white
                    rounded-[.2rem] py-2 px-6 me-2 mb-4 ease-out duration-300
                    transition-all shadow-sm border-0 hover:scale-105 cursor-pointer"
                  key={selectedTenantAuthDataItem.provider}
                >
                  {i18next.t("Login via")} {selectedTenantAuthDataItem.label}
                </Button>
              ))}
            </p>
          </>
        )}
      </Wrapper>
    </div>
  );
}

export default Login;
