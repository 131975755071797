// interacts with backend enums that could be either arrays or objects

import Enum from "../types/Enum";

let registeredEnums: {
  [key: string]: Enum;
} = {};

const registerEnum = (enumName: string, mappings: Enum): void => {
  // translate array to object
  let enumTemp: Enum = {};
  if (Array.isArray(mappings)) {
    for (let i = 0; i < mappings.length; i++) {
      enumTemp["" + i] = mappings[i];
    }
  } else {
    enumTemp = mappings;
  }

  registeredEnums[enumName] = enumTemp;
};

const clearEnums = () => {
  registeredEnums = {};
};

const getEnumValue = (
  enumName: string,
  key: number | string
): string | undefined => {
  if (typeof key === "number") key = "" + key;

  if (!registeredEnums?.[enumName]?.[key]) return undefined;

  return registeredEnums[enumName][key];
};

const getEnumKey = (enumName: string, value: string): number | undefined => {
  const enumMapping = registeredEnums?.[enumName];
  if (!enumMapping) return undefined;

  let key = Object.keys(enumMapping).find((key) => enumMapping[key] === value);
  if (typeof key === "undefined") return undefined;

  return parseInt(key);
};

export { registerEnum, clearEnums, getEnumValue, getEnumKey };
