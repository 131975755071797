import axios from "axios";
import i18next from "i18next";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import ReducerState from "../../types/ReducerState";
import { useSelector } from "react-redux";
import { Dispatch, SetStateAction, useState } from "react";
import Campaign from "../../types/models/Campaign";

interface propsType {
  sender: string;
  blackLists: string[] | undefined;
  spamScore: number | undefined;
  refreshFunction: Dispatch<SetStateAction<Campaign>>;
}
const SpamSuspicion = ({
  blackLists,
  spamScore,
  refreshFunction,
  sender,
}: propsType) => {
  const [rotate, setRotate] = useState(false);
  const { id } = useParams();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const spamScoreRefresh = () => {
    if (!id || !currentTenant?.id) return;
    setRotate(true);

    const url =
      "/tenants/" +
      currentTenant.id +
      "/campaigns/" +
      id +
      "/refresh-spam-evaluation";

    axios
      .post(url)
      .then((response) => {
        refreshFunction(response.data.campaign);
      })
      .catch(() => {
        toast(i18next.t("Reload error"));
      })
      .finally(() => {
        setRotate(false);
      });
  };
  return (
    <>
      <div
        className={`${
          blackLists?.length
            ? "bg-white text-primaryRed"
            : "bg-lightGreen text-secondaryGreen"
        } max-w-[25%] min-w-[300px] text-sm ml-5 my-4 px-8 py-4 rounded-b-md shadow-sm`}
      >
        <div className="flex justify-between items-center">
          <div className="text-xl m-l-auto">{i18next.t("Spam Suspicion")}</div>
          <div className="flex items-center gap-2">
            <div className="text-3xl font-bold ">
              {Math.round(spamScore ? spamScore : 0)}/10
            </div>
            <i
              className={`${
                rotate ? "animate-spin" : ""
              } fa fa-refresh cursor-pointer`}
              aria-hidden="true"
              onClick={spamScoreRefresh}
            ></i>
          </div>
        </div>
        <div className="mt-4">
          <div className="text-black">{i18next.t("Blacklists")}</div>
          {!blackLists?.length ? (
            <div>{i18next.t("You're not blacklisted")}</div>
          ) : (
            ""
          )}
          {blackLists?.length ? (
            <div className="text-primaryRed">
              [
              {blackLists.map((blackList, index) => {
                return (
                  <span key={index}>
                    {index === 0 ? "" : ", "}
                    {blackList}
                  </span>
                );
              })}
              ]
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="mt-4">
          <div className="text-black">{i18next.t("Sender")}</div>
          <div>{sender}</div>
        </div>
      </div>
    </>
  );
};
export default SpamSuspicion;
