import i18next from "i18next";
import { ChangeEventHandler, useEffect, useState } from "react";
import Domain from "../../../types/models/Domain";
import axios from "axios";
import { toast } from "react-hot-toast";
import Server from "../../../types/models/Server";
import Campaign from "../../../types/models/Campaign";

interface PropsType {
  currentTenantId: string | undefined;
  currentCampaign: Campaign | undefined;
  onChangeHandler: ChangeEventHandler<HTMLInputElement>;
  onChangeSelectHandler: ChangeEventHandler<HTMLSelectElement>;
  setLoadingHandler: (loadingStatus: boolean) => void;
  phishingDomains: Domain[] | undefined;
  setPhishingDomains: any;
}

const SendAsEmail = ({
  currentTenantId,
  currentCampaign,
  onChangeHandler,
  onChangeSelectHandler,
  setLoadingHandler,
  phishingDomains,
  setPhishingDomains,
}: PropsType) => {
  const [servers, setServers] = useState<Server[]>();
  const [emailDomains, setEmailDomains] = useState<Domain[]>();

  const fetchFishingDomains = () => {
    if (!currentTenantId) return;
    setLoadingHandler(false);

    var url = "/tenants/" + currentTenantId + "/domains/?supports-phishing=1";
    axios
      .get<{
        data: Domain[];
      }>(url)
      .then((response) => {
        setPhishingDomains(response.data.data);
        setLoadingHandler(true);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));

        // resetData();
      })
      .finally(() => {
        setLoadingHandler(true);
      });
  };

  const fetchEmailDomains = () => {
    if (!currentTenantId) return;
    setLoadingHandler(false);

    var url = "/tenants/" + currentTenantId + "/domains/?supports-email=1";
    axios
      .get<{
        data: Domain[];
      }>(url)
      .then((response) => {
        setEmailDomains(response.data.data);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));
        setLoadingHandler(true);
        // resetData();
      })
      .finally(() => {
        setLoadingHandler(true);
      });
  };

  const fetchServers = () => {
    if (!currentTenantId) return;
    setLoadingHandler(false);

    var url = "/tenants/" + currentTenantId + "/servers/";
    axios
      .get<{
        data: Server[];
      }>(url)
      .then((response) => {
        setServers(response.data.data);
        setLoadingHandler(true);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));
        // resetData();
      })
      .finally(() => {
        setLoadingHandler(true);
      });
  };

  useEffect(() => {
    fetchEmailDomains();
    fetchFishingDomains();
    fetchServers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`${
        currentCampaign?.target_channel_email_enabled
          ? "border-primaryBlue mb-4"
          : "border-white"
      } border shadow-md p-4 rounded-md`}
    >
      <div className="flex">
        <div>
          <input
            type="checkbox"
            checked={
              currentCampaign?.target_channel_email_enabled
                ? currentCampaign?.target_channel_email_enabled
                : false
            }
            id="target_channel_email_enabled"
            onChange={onChangeHandler}
            className={`hidden template-send`}
          />
          <label
            htmlFor="target_channel_email_enabled"
            className="flex items-center"
          >
            <span className="checkbox h-4 w-4 mr-2 inline-block border rounded-sm"></span>
            <i className="fa fa-envelope-o px-2" aria-hidden="true"></i>
            {i18next.t("E-Mail")}
          </label>
        </div>
        <div
          className={`${
            currentCampaign?.target_channel_email_enabled
              ? "flex flex-col ml-auto text-right"
              : "hidden"
          } text-sm text-primaryDarkGrey`}
        >
          <label
            htmlFor="phishing_domain_id"
            className="text-black dark:text-primaryLightGrey"
          >
            {i18next.t("Phishing domain")}
            <sup>*</sup>
          </label>
          {/* fetch Phishing domain ID */}
          <select
            name="phishing_domain_id"
            id="phishing_domain_id"
            onChange={onChangeSelectHandler}
            value={
              currentCampaign?.phishing_domain_id
                ? currentCampaign.phishing_domain_id
                : "null"
            }
            className="custom-select text-right outline-none dark:bg-darkModeLighterBlue
              dark:text-primaryLightGrey"
          >
            <option disabled value="null">
              -- {i18next.t("select")} --
            </option>
            {phishingDomains?.length &&
              phishingDomains.map((domain, index) => {
                return (
                  <option
                    key={domain.id ? domain.id.concat("_phishing") : index}
                    value={domain.id}
                  >
                    {domain.domain_name}
                  </option>
                );
              })}
          </select>
        </div>
        <div
          className={`${
            currentCampaign?.target_channel_email_enabled
              ? "flex flex-col ml-6"
              : "hidden"
          } text-sm text-primaryDarkGrey`}
        >
          <label htmlFor="" className="text-black dark:text-primaryLightGrey">
            {i18next.t("Outgoing server")}
          </label>
          {/* fetch servers */}
          <select
            name="server_id"
            id="email_server_id"
            onChange={onChangeSelectHandler}
            className="custom-select text-right outline-none
              dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
            value={
              currentCampaign?.server_id ? currentCampaign.server_id : "null"
            }
          >
            <option disabled value="null">
              -- {i18next.t("select")} --
            </option>
            {servers?.length &&
              servers.map((server, index) => {
                return (
                  <option key={server.id ? server.id : index} value={server.id}>
                    {server.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div
        className={`${
          currentCampaign?.target_channel_email_enabled
            ? "flex flex-wrap"
            : "hidden"
        } mt-6 gap-6`}
      >
        <div className="basis-full">
          <div>
            <label htmlFor="sender_name" className="font-bold">
              {i18next.t("Sender name")}
            </label>
          </div>
          <div>
            <input
              type="text"
              className="border-solid border-0 border-b border-primaryGrey w-1/2 outline-none
                dark:bg-darkModeLighterBlue dark:text-primaryLightGrey focus:border-primaryDarkGrey"
              placeholder="Max Mustermann"
              name="sender_name"
              id="sender_name"
              onChange={onChangeHandler}
              value={
                currentCampaign?.sender_name ? currentCampaign.sender_name : ""
              }
            />
          </div>
        </div>
        <div className="basis-full">
          <div>
            <label htmlFor="sender_email_prefix" className="font-bold">
              {i18next.t("Sender email")}
            </label>
          </div>
          <div className="flex">
            <input
              type="text"
              className="border-solid border-0 border-b border-primaryGrey outline-none
                dark:bg-darkModeLighterBlue dark:text-primaryLightGrey focus:border-primaryDarkGrey"
              placeholder="max.mustermann"
              name="sender_email_prefix"
              id="sender_email_prefix"
              onChange={onChangeHandler}
              value={
                currentCampaign?.sender_email_prefix
                  ? currentCampaign.sender_email_prefix
                  : ""
              }
            />{" "}
            @
            <select
              name="email_domain_id"
              id="email_domain_id"
              className="custom-select outline-none focus:border-primaryDarkGrey
                dark:bg-darkModeLighterBlue dark:text-primaryLightGrey"
              onChange={onChangeSelectHandler}
              value={
                currentCampaign?.email_domain_id
                  ? currentCampaign.email_domain_id
                  : "null"
              }
            >
              <option disabled value="null" className="text-right">
                -- {i18next.t("select")} --
              </option>
              {emailDomains?.length &&
                emailDomains.map((domain, index) => {
                  return (
                    <option
                      key={domain.id ? domain.id.concat("_email") : index}
                      value={domain.id}
                    >
                      {domain.domain_name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SendAsEmail;
