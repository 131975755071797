import axios from "axios";

import Template from "../types/models/Template";
import Target from "../types/models/Target";
import TargetGroup from "../types/models/TargetGroup";
import Campaign from "../types/models/Campaign";
import { toast } from "react-hot-toast";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { useState } from "react";
import FormErrorResponse from "../types/FormErrorResponse";

const useSave = (
  urlPart: string,
  data: Template | TargetGroup | Target | Campaign | undefined,
  isCreate: boolean
): any => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const [fetchData, setFetchData] = useState<
    Template | TargetGroup | Target | Campaign | null
  >(null);
  const [fetchError, setFetchError] = useState<FormErrorResponse>();

  const navigate = useNavigate();
  if (!currentTenant) return null;

  const url = "/tenants/" + currentTenant.id + urlPart;

  const sendRequest = () => {
    const axiosHandler = isCreate
      ? axios.post<Template | TargetGroup | Target | Campaign>(url, data)
      : axios.patch<Template | TargetGroup | Target | Campaign>(url, data);

    axiosHandler
      .then((response) => {
        if (isCreate) {
          navigate(urlPart + response.data.id);
        } else {
          toast(i18next.t("Record saved."));
          setFetchData(response.data);
        }
      })
      .catch((error) => {
        toast(i18next.t("Error saving record."));
        if (error?.response?.data) setFetchError(error.response.data);
      });
  };
  return [fetchData, fetchError, sendRequest];
};
export default useSave;
