import i18next from "i18next";
import { Form } from "react-bootstrap";
interface Props {
  isVisible: boolean;
  title: string | undefined;
  content: string | undefined;
  setTemplate: (field: string, value: string) => void;
}
const ReproofPageContent = ({
  isVisible,
  title,
  content,
  setTemplate,
}: Props) => {
  return (
    <>
      <Form.Group
        className={`${
          isVisible ? "block" : "hidden"
        } flex gap-5 bg-white p-4 rounded shadow-md dark:bg-darkModeBlue`}
      >
        <Form.Group className="flex-1">
          <Form.Label
            htmlFor="reproof_page_title"
            className="text-sm font-bold"
          >
            {i18next.t("Instruction Page Title")}
          </Form.Label>
          <Form.Control
            className="flex bg-transparent border-t-0
              border-l-0 border-r-0 rounded-none border-primaryDarkGrey
              focus:border-black focus:shadow-none focus-reset dark:text-white"
            value={title ? title : ""}
            placeholder={i18next.t("Title")}
            id="reproof_page_title"
            onChange={(e) => {
              setTemplate("reproof_page_title", e.target.value);
            }}
          />
        </Form.Group>
      </Form.Group>
      <Form.Control
        as="textarea"
        placeholder={i18next.t("Instructions page")}
        className={`${
          isVisible ? "block" : "hidden"
        } mb-4 focus-reset focus:outline-none focus:shadow-none border-white focus:border-white
          rounded-tl-none shadow-md pt-6`}
        rows={15}
        id="reproof_page_content"
        value={content ? content : ""}
        onChange={(e) => {
          setTemplate("reproof_page_content", e.target.value);
        }}
      />
    </>
  );
};
export default ReproofPageContent;
