import i18next from "i18next";
import Campaign from "../../types/models/Campaign";
import { getEnumValue } from "../../utils/Enum";

const DesignCreatedForMessage = ({ campaign }: { campaign: Campaign }) => {
  const currCampaignStatus = getEnumValue(
    "CampaignStatus",
    campaign.status !== undefined ? campaign.status : ""
  );
  return (
    <>
      <div
        className="flex text-base gap-2 flex-wrap
    lg:flex-nowrap lg:text-xs max-w-[400px] xl:max-w-none"
      >
        {currCampaignStatus === "draft" ? (
          <div className="text-black dark:text-white w-full lg:w-auto">
            {i18next.t("Design created for")}
          </div>
        ) : (
          <div className="text-black dark:text-white w-full lg:w-auto">
            {i18next.t("Sent as")}
          </div>
        )}
        <div
          className={`${
            campaign.has_feature_prepared_channel_email
              ? "text-primaryBlue dark:text-white font-bold"
              : ""
          }`}
        >
          <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
          {i18next.t("E-mail")}
          {campaign.has_feature_prepared_channel_email && (
            <i
              className="fa fa-check pl-1 text-primaryGreen"
              aria-hidden="true"
            ></i>
          )}
        </div>
        <div
          className={`${
            campaign.has_feature_prepared_channel_sms
              ? "text-primaryBlue dark:text-white font-bold"
              : ""
          }`}
        >
          <i className="fa fa-mobile" aria-hidden="true"></i> {i18next.t("SMS")}
          {campaign.has_feature_prepared_channel_sms && (
            <i
              className="fa fa-check pl-1 text-primaryGreen"
              aria-hidden="true"
            ></i>
          )}
        </div>
        <div
          className={`${
            campaign.has_feature_prepared_channel_whatsapp
              ? "text-primaryBlue dark:text-white font-bold"
              : ""
          }`}
        >
          <i className="fa fa-whatsapp" aria-hidden="true"></i>{" "}
          {i18next.t("Whatsapp")}
          {campaign.has_feature_prepared_channel_whatsapp && (
            <i
              className="fa fa-check pl-1 text-primaryGreen"
              aria-hidden="true"
            ></i>
          )}
        </div>
      </div>
      <div className="flex text-base lg:gap-2 flex-col lg:flex-row lg:text-xs max-w-[400px] xl:max-w-none">
        <div
          className={`shrink-0 ${
            campaign.has_feature_prepared_phishing_page
              ? "text-primaryBlue dark:text-white font-bold"
              : ""
          }
    `}
        >
          <i className="fa fa-television" aria-hidden="true"></i>{" "}
          {i18next.t("Phishing page with form")}
          {campaign.has_feature_prepared_phishing_page && (
            <i
              className="fa fa-check pl-1 text-primaryGreen"
              aria-hidden="true"
            ></i>
          )}
        </div>
        <div
          className={`shrink-0 ${
            campaign.has_feature_prepared_reproof_page
              ? "text-primaryBlue dark:text-white font-bold"
              : ""
          } `}
        >
          <i className="fa fa-television" aria-hidden="true"></i>{" "}
          {i18next.t("Instructions page")}
          {campaign.has_feature_prepared_reproof_page && (
            <i
              className="fa fa-check pl-1 text-primaryGreen"
              aria-hidden="true"
            ></i>
          )}
        </div>
      </div>
    </>
  );
};
export default DesignCreatedForMessage;
