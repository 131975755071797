import i18next from "i18next";
import GenericCallback from "../../types/GenericCallback";

interface propsType {
  currentStep: number;
  lastStep: number;
  goToNextStep: GenericCallback;
  goToPrevStep: GenericCallback;
}

const Pagination = ({
  currentStep,
  lastStep,
  goToNextStep,
  goToPrevStep,
}: propsType) => {
  return (
    <div className="my-8 flex max-w-[660px]">
      {currentStep !== 1 && (
        <div
          onClick={goToPrevStep}
          className="mr-auto flex items-center text-primaryBlue font-semibold cursor-pointer"
        >
          <i
            className="fa fa-angle-left mr-2 w-6 h-6 flex
              bg-primaryBlue text-white text-xl rounded-full justify-center items-center"
            aria-hidden="true"
          ></i>
          {i18next.t("Previous Step")}{" "}
        </div>
      )}
      {currentStep !== lastStep && (
        <div
          onClick={goToNextStep}
          className="ml-auto flex items-center text-primaryBlue font-semibold cursor-pointer"
        >
          {i18next.t("Next Step")}{" "}
          <i
            className="fa fa-angle-right flex ml-2 w-6 h-6
              bg-primaryBlue text-white text-xl rounded-full justify-center items-center"
            aria-hidden="true"
          ></i>
        </div>
      )}
    </div>
  );
};

export default Pagination;
