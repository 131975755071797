// TODO: fix infinite request bug when logging out while this component is shown (also on TargetGroup)

import i18next from "i18next";
import Button from "../components/UI/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import TargetGroup from "../types/models/TargetGroup";
import { useNavigate } from "react-router-dom";
// import { store } from "..";
// import Tenant from "../types/models/Tenant";
import Pagination from "../components/UI/Pagination";
import ListItem from "../components/UI/ListDisplay/ListItem";
import ListTabs from "../components/UI/ListDisplay/ListTabs";
import Search from "../components/UI/Search";
import ListItemsPerPage from "../components/UI/ListDisplay/ListItemsPerPage";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import useClone from "../hooks/use-clone";
import ListSort from "../components/UI/ListDisplay/ListSort";
import { loadStateFromLocalStorage } from "../utils/LocalStorageState";
import ListControll from "../components/UI/ListDisplay/ListControll";
import {
  paginationDefault,
  sortingDefault,
  tabsTargetGroups,
} from "../components/UI/ListDisplay/data";
interface ResponseType {
  current_page: number;
  data: TargetGroup[];
  last_page: number;
  per_page: number;
  total: number;
  count_0_to_100: number;
  count_100_to_1000: number;
  count_1000_plus: number;
}

function TargetGroups() {
  const initialLocalStorageState = loadStateFromLocalStorage();
  const currentItemsPerPage = initialLocalStorageState?.targetgroupsPerPage
    ? +initialLocalStorageState.targetgroupsPerPage
    : 10;
  const currentSortValue = initialLocalStorageState?.targetgroupsSorting
    ? initialLocalStorageState.targetgroupsSorting
    : "updated-desc";

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [items, setItems] = useState<TargetGroup[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [tabValues, setTabsValues] = useState<number[]>([0, 0, 0, 0]);
  const [sortValue, setSortValue] = useState<string>(currentSortValue);
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(currentItemsPerPage);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [currentTargetGroupID, setCurrentTargetGroupID] = useState<string>("");
  const [showCopyConfirmationModal, setShowCopyConfirmationModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  // postpone loading until currentTenant is available
  // TODO: fix bug: refresh browser does not load list
  // store.subscribe(() => {
  //   const currentTenant = store.getState().currentTenant as Tenant | null;

  //   if (!hasLoaded && currentTenant?.id) {
  //     fetchItems();
  //   }
  // });

  const tabs = tabsTargetGroups.map((item) => ({
    ...item,
    value: tabValues[item.id],
    current: currentTab === item.id ? true : false,
  }));
  const numberOfPagesInPagination = paginationDefault.map((item) => ({
    ...item,
    current: itemsPerPage === item.value ? true : false,
  }));
  const sortElements = sortingDefault.map((item) => ({
    ...item,
    current: sortValue === item.value ? true : false,
  }));

  const resetData = () => {
    setItems([]);
    setCurrentPage(0);
    setLastPage(0);
    setTotalCount(0);
    setHasLoaded(false);
  };

  const createUrl = (page: number) => {
    let url =
      "/tenants/" +
      currentTenant?.id +
      "/target-groups?page=" +
      page +
      "&items-per-page=" +
      itemsPerPage;
    if (searchValue) url += "&search=" + searchValue;
    if (sortValue) url += "&sort=" + sortValue;
    if (currentTab > 0 && tabs[currentTab].targetCountMin)
      url += "&target-count-min=" + tabs[currentTab].targetCountMin;
    if (currentTab > 0 && tabs[currentTab].targetCountMax)
      url += "&target-count-max=" + tabs[currentTab].targetCountMax;
    return url;
  };

  const fetchItems = (page: number = 1) => {
    if (!currentTenant?.id) return;

    resetData();
    const url = createUrl(page);
    axios
      .get<ResponseType>(url)
      .then((response) => {
        setItems(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
        setHasLoaded(true);
        setTabsValues([
          response.data.count_0_to_100 +
            response.data.count_100_to_1000 +
            response.data.count_1000_plus,
          response.data.count_0_to_100,
          response.data.count_100_to_1000,
          response.data.count_1000_plus,
        ]);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));
      })
      .finally(() => {
        if (!hasLoaded) setHasLoaded(true);
      });
  };
  const changeItemsPerPageHandler = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
  };
  const changeTabHandler = (tab: number) => {
    setCurrentTab(tab);
  };
  const changeSortHandler = (value: string) => {
    setSortValue(value);
  };
  useEffect(() => {
    if (!currentTenant?.id) return;
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, searchValue, currentTab, sortValue]);

  const remove = () => {
    if (!currentTargetGroupID || !currentTenant?.id) return;

    axios
      .delete(
        "/tenants/" +
          currentTenant?.id +
          "/target-groups/" +
          currentTargetGroupID
      )
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));

        fetchItems();
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };
  const cloneTargetGroup = useClone(
    currentTenant,
    "/target-groups/",
    currentTargetGroupID
  );
  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          remove();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      <ConfirmationModal
        show={showCopyConfirmationModal}
        closeCallback={() => {
          setShowCopyConfirmationModal(false);
        }}
        confirmCallback={() => {
          cloneTargetGroup().then(() => {
            fetchItems();
          });
        }}
        title={i18next.t("Copy record")}
        confirmButtonText={i18next.t("Copy")}
        confirmButtonVariant="danger"
      />
      <div className="flex flex-col w-full pt-5">
        <div className="flex gap-6">
          <Button
            onClick={() => {
              navigate("/target-groups/create");
            }}
          >
            <i className="fa fa-plus pr-2" aria-hidden="true"></i>{" "}
            {i18next.t("Add new target group")}
          </Button>

          <Search
            placeholder={i18next.t("Search target groups")}
            searchHandler={setSearchValue}
          ></Search>
        </div>
        <ListControll isSearching={searchValue?.length ? true : false}>
          <ListTabs tabs={tabs} changeTabHandler={changeTabHandler}></ListTabs>
          <ListSort
            sortElements={sortElements}
            changeSortHandler={changeSortHandler}
            sortingName="targetgroupsSorting"
          ></ListSort>
          <ListItemsPerPage
            tabs={numberOfPagesInPagination}
            paginationName="targetgroupsPerPage"
            activeTab={itemsPerPage}
            changeItemsPerPageHandler={changeItemsPerPageHandler}
          ></ListItemsPerPage>
        </ListControll>
        {items.length > 0 ? (
          <>
            {items.map((item) => (
              <ListItem
                key={item.id}
                className="p-6 d-flex justify-between align-items-start"
                clickHandler={() => {
                  navigate("/target-groups/" + item.id);
                }}
                copyHandler={() => {
                  setShowCopyConfirmationModal(true);
                  setCurrentTargetGroupID(item.id);
                }}
                deleteHandler={() => {
                  setShowDeletionConfirmationModal(true);
                  setCurrentTargetGroupID(item.id);
                }}
              >
                <div className="flex-1">
                  <div
                    className="fw-bold js-interaction text-black dark:text-white text-lg"
                    onClick={() => {
                      navigate("/target-groups/" + item.id);
                    }}
                  >
                    {item.name}
                  </div>
                </div>
                <div className="flex-1">
                  <i
                    className="fa fa-users mr-3 text-xl"
                    aria-hidden="true"
                  ></i>
                  <span className="mr-2">{i18next.t("Recipient")}</span>
                  <span>{item.count}</span>
                </div>
              </ListItem>
            ))}

            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              totalCount={totalCount}
              nextCallback={() => {
                fetchItems(currentPage + 1);
              }}
              backCallback={() => {
                fetchItems(currentPage - 1);
              }}
            />
          </>
        ) : (
          <p>
            {!hasLoaded ? (
              <span className="loading"></span>
            ) : (
              i18next.t("No entries found.")
            )}
          </p>
        )}
      </div>
    </>
  );
}

export default TargetGroups;
