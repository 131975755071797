import i18next from "i18next";
import ConfirmationModal from "../UI/ConfirmationModal";
import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import FormErrorResponse from "../../types/FormErrorResponse";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";

interface Props {
  isModalVisible: boolean;
  onCloseHandler: Dispatch<SetStateAction<boolean>>;
  onErrorsHandler: Dispatch<SetStateAction<FormErrorResponse | null>>;
  onLoadingHandler: Dispatch<SetStateAction<boolean>>;
  onListReloadHandler: (page?: number) => void;
  targetGroupId: string;
}

const CsvImportConfirmationModal = ({
  isModalVisible,
  onCloseHandler,
  onErrorsHandler,
  onLoadingHandler,
  onListReloadHandler,
  targetGroupId,
}: Props) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const currentTenantId = currentTenant?.id;
  const [CSVfile, setCSVfile] = useState<Blob>();

  const csvImport = () => {
    if (!targetGroupId || !currentTenantId || !CSVfile) return;

    const url = "/tenants/" + currentTenantId + "/targets/import";

    var formData = new FormData();
    formData.append("file", CSVfile, "test.csv");
    formData.append("exclude_first_row", "1");
    formData.append("target_group_id", targetGroupId);

    const axiosHandler = axios.post<FormData>(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    onErrorsHandler(null);
    onLoadingHandler(true);
    axiosHandler
      .then((response) => {
        toast(i18next.t("Record saved."));
        onListReloadHandler();
        onLoadingHandler(false);
      })
      .catch((error) => {
        toast(i18next.t("Error saving record."));
        onLoadingHandler(false);

        if (error?.response?.data) onErrorsHandler(error.response.data);
      });
  };

  return (
    <ConfirmationModal
      show={isModalVisible}
      closeCallback={() => {
        onCloseHandler(false);
      }}
      confirmCallback={() => {
        csvImport();
      }}
      title={i18next.t("Choose a file")}
      confirmButtonText={i18next.t("Save")}
      confirmButtonVariant="danger"
    >
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={(e) => {
            if (!e.target.files?.length) return;
            setCSVfile(e.target.files[0]);
          }}
        />
      </form>
    </ConfirmationModal>
  );
};
export default CsvImportConfirmationModal;
