import i18next from "i18next";
import { KeyboardEvent } from "react";

interface propsType {
  placeholder?: string;
  className?: string;
  searchHandler: (arg0: string) => void;
}

const Search = ({ className, placeholder, searchHandler }: propsType) => {
  const keyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (e.key === "Enter" || !value.length) {
      e.preventDefault();
      searchHandler(value);
    }
  };
  return (
    <form className="relative">
      <i
        className={`${className} fa fa-search flex items-center absolute top-0 bottom-0 left-3 my-auto
          text-primaryDarkGrey dark:text-white`}
        aria-hidden="true"
      ></i>
      <input
        type="text"
        placeholder={placeholder ? placeholder : i18next.t("Search...")}
        className="pr-6 pl-10 py-2 border-0 focus:outline-none rounded-[.2rem] focus:shadow-sm
          dark:bg-darkModeLighterBlue dark:text-white"
        onKeyUp={keyUpHandler}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      />
    </form>
  );
};
export default Search;
