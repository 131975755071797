import Button from "../UI/Button";
import Search from "../UI/Search";
import i18next from "i18next";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Pagination from "../UI/Pagination";
import { default as TemplateType } from "../../types/models/Template";
import TemplateItem from "./TemplateItem/TemplateItem";
import { default as TemplatePage } from "../../views/Template";
import { getEnumKey } from "../../utils/Enum";
interface propsType {
  currentTemplate?: string;
  asideVisabilityHandler: Dispatch<SetStateAction<boolean>>;
  onInputChangeHandler(
    field: string,
    value: string | boolean | { id: string; name: string }
  ): void;
  isCampaignTypeMessage?: boolean;
}
const Template = ({
  currentTemplate,
  onInputChangeHandler,
  asideVisabilityHandler,
  isCampaignTypeMessage = true,
}: propsType) => {
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const [templates, setTemplates] = useState<TemplateType[] | null>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [editedTemplate, setEditedTemplate] = useState<string>("");
  const [creatingNewTemplate, setCreatingNewTemplate] =
    useState<boolean>(false);
  const fetchTemplates = (page: number = 1) => {
    if (!currentTenant?.id) return;
    setHasLoaded(false);
    var url =
      "/tenants/" +
      currentTenant?.id +
      "/templates?sort=updated-desc&page=" +
      page;
    if (searchValue) url += "&search=" + searchValue;
    if (isCampaignTypeMessage)
      url += "&campaign-type=" + getEnumKey("CampaignType", "message");
    if (!isCampaignTypeMessage)
      url += "&campaign-type=" + getEnumKey("CampaignType", "file");
    axios
      .get<{
        current_page: number;
        data: TemplateType[];
        last_page: number;
        per_page: number;
        total: number;
      }>(url)
      .then((response) => {
        setTemplates(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));
        // resetData();
      })
      .finally(() => {
        setHasLoaded(true);
      });
  };
  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const reset = () => {
    setEditedTemplate("");
    setCreatingNewTemplate(false);
    setTemplates(null);
    setHasLoaded(false);
    fetchTemplates();
    asideVisabilityHandler(true);
  };

  // const navigate = useNavigate();

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;
    const name = event.target.getAttribute("data-groupname");
    onInputChangeHandler("template", { id, name: name ? name : "" });
    onInputChangeHandler("template_id", id);
  };

  return (
    <>
      {!editedTemplate && !creatingNewTemplate ? (
        <>
          <div className="flex">
            <Button
              className="mr-auto uppercase"
              onClick={() => {
                // navigate("/templates/create");
                setCreatingNewTemplate(true);
                asideVisabilityHandler(false);
              }}
            >
              <i className="fa fa-plus mr-6" aria-hidden="true"></i>
              {i18next.t("Add new template")}
            </Button>
            <Search
              className="ml-auto"
              placeholder={i18next.t("Search template...")}
              searchHandler={setSearchValue}
            ></Search>
          </div>
          {templates?.length ? (
            <>
              <form action="" className="mt-6">
                {templates.map((template) => (
                  <TemplateItem
                    key={template.id}
                    template={template}
                    currentTemplateId={currentTemplate ? currentTemplate : ""}
                    setCurrentTemplate={onChangeHandler}
                    setEditedTemplate={(id) => {
                      setEditedTemplate(id);
                      asideVisabilityHandler(false);
                    }}
                    reloadList={fetchTemplates}
                    // copyTemplateHandler ={()=>{}}
                  />
                ))}
              </form>
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                totalCount={totalCount}
                nextCallback={() => {
                  fetchTemplates(currentPage + 1);
                }}
                backCallback={() => {
                  fetchTemplates(currentPage - 1);
                }}
              />
            </>
          ) : hasLoaded ? (
            <div className="mt-6">{i18next.t("No recipients available")}</div>
          ) : (
            <div className="mt-6">
              <div className="loading"></div>
            </div>
          )}{" "}
        </>
      ) : (
        <>
          <Button className="mb-4" onClick={reset}>
            <i className="fa fa-close mr-4"></i>
            {i18next.t("Finish editing")}
          </Button>
          {creatingNewTemplate ? (
            <TemplatePage
              isCreate={true}
              isTemplateForMessageCampaign={isCampaignTypeMessage}
            />
          ) : (
            <TemplatePage isCreate={false} templateToEditId={editedTemplate} />
          )}
        </>
      )}
    </>
  );
};
export default Template;
