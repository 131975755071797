import i18next from "i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ReducerState from "../types/ReducerState";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/UI/Pagination";
import Button from "../components/UI/Button";
import Search from "../components/UI/Search";
import ListTabs from "../components/UI/ListDisplay/ListTabs";
import ListItemsPerPage from "../components/UI/ListDisplay/ListItemsPerPage";
import ListItem from "../components/UI/ListDisplay/ListItem";
import Domain from "../types/models/Domain";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import ListSort from "../components/UI/ListDisplay/ListSort";
import { loadStateFromLocalStorage } from "../utils/LocalStorageState";
import ListControll from "../components/UI/ListDisplay/ListControll";
import {
  paginationDefault,
  sortingDomains,
  tabsDomains,
} from "../components/UI/ListDisplay/data";

function Domains() {
  const initialLocalStorageState = loadStateFromLocalStorage();
  const currentItemsPerPage = initialLocalStorageState?.domainsPerPage
    ? +initialLocalStorageState.domainsPerPage
    : 10;
  const currentSortValue = initialLocalStorageState?.domainsSorting
    ? initialLocalStorageState.domainsSorting
    : "updated-desc";
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [domainsList, setDomainsList] = useState<Domain[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [tabValues, setTabsValues] = useState<number[]>([0, 0, 0, 0]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(currentItemsPerPage);
  const [sortValue, setSortValue] = useState<string>(currentSortValue);
  const [currentDomainID, setCurrentDomainID] = useState<string>("");
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const navigate = useNavigate();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const tabs = tabsDomains.map((item) => ({
    ...item,
    value: tabValues[item.id],
    current: currentTab === item.id ? true : false,
  }));
  const numberOfPagesInPagination = paginationDefault.map((item) => ({
    ...item,
    current: itemsPerPage === item.value ? true : false,
  }));
  const sortElements = sortingDomains.map((item) => ({
    ...item,
    current: sortValue === item.value ? true : false,
  }));

  // postpone loading until currentTenant is available
  // store.subscribe(() => {
  //   const currentTenant = store.getState().currentTenant as Tenant | null;

  //   if (!hasLoaded && currentTenant?.id) {
  //     fetchItems();
  //   }
  // });

  const resetData = () => {
    setDomainsList([]);
    setCurrentPage(0);
    setLastPage(0);
    setTotalCount(0);
    setHasLoaded(false);
  };

  const createUrl = (page: number) => {
    let url =
      "/tenants/" +
      currentTenant?.id +
      "/domains?page=" +
      page +
      "&items-per-page=" +
      itemsPerPage;
    if (searchValue) url += "&search=" + searchValue;
    if (currentTab === 1) url += "&supports-email=1";
    if (currentTab === 2) url += "&supports-phishing=1";
    if (currentTab === 3) url += "&archived=1";
    if (sortValue) url += "&sort=" + sortValue;

    return url;
  };

  const fetchItems = (page: number = 1) => {
    if (!currentTenant?.id) return;

    resetData();
    const url = createUrl(page);
    axios
      .get<{
        current_page: number;
        data: Domain[];
        last_page: number;
        per_page: number;
        total: number;
        count_all: number;
        count_email_support: number;
        count_phishing_support: number;
        count_archive: number;
      }>(url)
      .then((response) => {
        setDomainsList(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotalCount(response.data.total);
        setTabsValues([
          response.data.count_all,
          response.data.count_email_support,
          response.data.count_phishing_support,
          response.data.count_archive,
        ]);
      })
      .catch(() => {
        toast(i18next.t("Error loading list"));
        resetData();
      })
      .finally(() => {
        setHasLoaded(true);
      });
  };
  const remove = () => {
    if (!currentDomainID || !currentTenant?.id) return;

    axios
      .delete("/tenants/" + currentTenant?.id + "/domains/" + currentDomainID)
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));

        fetchItems();
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  useEffect(() => {
    if (!currentTenant?.id) return;
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, searchValue, currentTab, sortValue]);

  const changeItemsPerPageHandler = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
  };
  const changeTabHandler = (tab: number) => {
    setCurrentTab(tab);
  };
  const changeSortHandler = (value: string) => {
    setSortValue(value);
  };

  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          remove();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      <div className="flex flex-col w-full pt-5">
        <div className="flex gap-6">
          <Button
            onClick={() => {
              navigate("/domains/create");
            }}
          >
            <i className="fa fa-plus pr-2" aria-hidden="true"></i>{" "}
            {i18next.t("Add new domain")}
          </Button>

          <Search
            placeholder={i18next.t("Search...")}
            searchHandler={setSearchValue}
          ></Search>
        </div>
        <ListControll isSearching={searchValue?.length ? true : false}>
          <ListTabs tabs={tabs} changeTabHandler={changeTabHandler}></ListTabs>
          <ListSort
            sortElements={sortElements}
            changeSortHandler={changeSortHandler}
            sortingName="domainsSorting"
          ></ListSort>
          <ListItemsPerPage
            tabs={numberOfPagesInPagination}
            paginationName="domainsPerPage"
            activeTab={itemsPerPage}
            changeItemsPerPageHandler={changeItemsPerPageHandler}
          ></ListItemsPerPage>
        </ListControll>
        <div className="mt-4">
          {domainsList.length > 0 &&
            domainsList.map((domain, index) => (
              <ListItem
                className="p-6"
                key={domain.id}
                clickHandler={() => {
                  navigate("/domains/" + domain.id);
                }}
                deleteHandler={() => {
                  setShowDeletionConfirmationModal(true);
                  setCurrentDomainID(domain.id ? domain.id : "");
                }}
              >
                <h4 className="text-black dark:text-white">
                  {domain.domain_name}
                </h4>
              </ListItem>
            ))}
        </div>
        <p>
          {!domainsList.length && hasLoaded && i18next.t("No entries found.")}
          {!domainsList.length && !hasLoaded && (
            <span className="loading"></span>
          )}
        </p>
        {domainsList.length ? (
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            totalCount={totalCount}
            nextCallback={() => {
              fetchItems(currentPage + 1);
            }}
            backCallback={() => {
              fetchItems(currentPage - 1);
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default Domains;
