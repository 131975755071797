import i18next from "i18next";
import Wrapper from "../UI/Wrapper";
import FormErrorResponse from "../../types/FormErrorResponse";
import InputValidationError from "../UI/InputValidationError";

interface propsType {
  errors: FormErrorResponse | null;
  currentCampaignName: string;
  currentCampaignDescription: string;
  onInputChangeHandler(field: string, value: string | boolean): void;
}

const StepCommon = ({
  errors,
  currentCampaignName,
  currentCampaignDescription,
  onInputChangeHandler,
}: propsType) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id;
    const value = event.target.value;
    onInputChangeHandler(field, value);
    // remove validation error on input change
    delete errors?.[field];
  };

  return (
    <>
      <Wrapper className="flex flex-col">
        <label
          htmlFor="name"
          className="text-xl font-bold mb-4
            dark:text-white"
        >
          {i18next.t("Title")}
        </label>
        <input
          type="text"
          id="name"
          value={currentCampaignName ? currentCampaignName : ""}
          onChange={onChangeHandler}
          placeholder={i18next.t("Campaign title...")}
          className="border-solid border-0 border-b border-primaryGrey
            placeholder:text-primaryGrey placeholder:font-semibold px-2 pb-2
            focus:outline-none focus:border-primaryDarkGrey
            dark:bg-darkModeLighterBlue dark:text-white"
        />
        <InputValidationError
          errors={errors}
          fieldName="name"
        ></InputValidationError>
      </Wrapper>
      <Wrapper className="flex flex-col mt-6">
        <label
          htmlFor="description"
          className="text-xl font-bold mb-4
            dark:text-white"
        >
          {i18next.t("Description")}
          <span className="text-sm text-primaryGrey px-2">
            ({i18next.t("Optional")})
          </span>
        </label>
        <input
          type="text"
          id="description"
          value={currentCampaignDescription ? currentCampaignDescription : ""}
          onChange={onChangeHandler}
          placeholder={i18next.t("Kampagnen Beschreibung...")}
          className="border-solid border-0 border-b border-primaryGrey
            placeholder:text-primaryGrey placeholder:font-semibold px-2 pb-2
            focus:outline-none focus:border-primaryDarkGrey
            dark:bg-darkModeLighterBlue dark:text-white"
        />
      </Wrapper>
    </>
  );
};
export default StepCommon;
