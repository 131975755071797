import SidebarMenu from "./SidebarMenu";
import GenericCallback from "../../../types/GenericCallback";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../../actions";
import { getEnumKey } from "../../../utils/Enum";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";
import axios from "axios";
import { toast } from "react-hot-toast";

interface propsType {
  className?: string;
  hideSidebar: GenericCallback;
  logo: string;
}

const Sidebar = ({ className, hideSidebar, logo }: propsType) => {
  const currentUser = useSelector((state: ReducerState) => state.currentUser);

  const dispatch = useDispatch();

  const setNewLocale = (newLocale: string) => {
    const newLocaleId = getEnumKey("Locale", newLocale);
    if (newLocaleId === undefined) return;

    i18next.changeLanguage(newLocale);

    const url = "/user/";
    axios
      .patch(url, { locale: newLocaleId })
      .then((response) => {
        dispatch(setCurrentUser(response.data));
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };

  const hideSideBarHandler = () => {
    hideSidebar();
  };

  return (
    <>
      <aside
        className={`md:max-w-min xl:flex xl:flex-col xl:static xl:left-0 xl:h-auto
          block w-screen h-screen p-6 fixed top-0 z-10
          text-white bg-primaryBlue dark:bg-darkModeBlue
          transition-all ease-in-out
        ${className === "block" ? " left-0" : ""}
        ${className === "hidden" ? "-left-full" : ""}`}
      >
        <div
          className="flex p-2 xl:hidden
            justify-end text-3xl text-white
            cursor-pointer"
          onClick={hideSideBarHandler}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <a href="/" className="text-primaryBlue no-underline">
          <div className="w-[210px] h-[95px] bg-white mt-4 mb-12 flex justify-center items-center">
            {logo}
          </div>
        </a>
        <div>{i18next.t("Pro Account")}</div>
        <SidebarMenu closeHandler={hideSideBarHandler}></SidebarMenu>
        <div className="flex gap-2 items-center mt-auto">
          <span
            className={`cursor-pointer ${
              getEnumKey("Locale", "de") === currentUser?.locale
                ? "opacity-1"
                : "opacity-50"
            }`}
            onClick={() => setNewLocale("de")}
          >
            DE
          </span>
          |
          <span
            className={`cursor-pointer ${
              getEnumKey("Locale", "en") === currentUser?.locale
                ? "opacity-1"
                : "opacity-50"
            }`}
            onClick={() => setNewLocale("en")}
          >
            EN
          </span>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
