const sortingDefault = [
  {
    id: 0,
    name: "Date edited (ascending)",
    value: "updated-asc",
  },
  {
    id: 1,
    name: "Date edited (descending)",
    value: "updated-desc",
  },
  {
    id: 2,
    name: "Name (ascending)",
    value: "field-name-asc",
  },
  {
    id: 3,
    name: "Name (descending)",
    value: "field-name-desc",
  },
];
const paginationDefault = [{ value: 10 }, { value: 30 }, { value: 50 }];

const tabsDomains = [
  {
    id: 0,
    name: "All",
    value: 0,
  },
  {
    id: 1,
    name: "Email",
    value: 0,
  },
  {
    id: 2,
    name: "Phishing",
    value: 0,
  },
  {
    id: 3,
    name: "Archive",
    value: 0,
  },
];
const sortingUsers = [
  {
    id: 0,
    name: "First name (ascending)",
    value: "field-first_name-asc",
  },
  {
    id: 1,
    name: "First name (descending)",
    value: "field-first_name-desc",
  },
  {
    id: 2,
    name: "Last name (ascending)",
    value: "field-last_name-asc",
  },
  {
    id: 3,
    name: "Last name (descending)",
    value: "field-last_name-desc",
  },
  {
    id: 4,
    name: "Email (ascending)",
    value: "field-email-asc",
  },
  {
    id: 5,
    name: "Email (descending)",
    value: "field-email-desc",
  },
];

const sortingTenants = [
  {
    id: 0,
    name: "Name (ascending)",
    value: "field-name-asc",
  },
  {
    id: 1,
    name: "Name (descending)",
    value: "field-name-desc",
  },
];

const sortingDomains = [
  {
    id: 0,
    name: "Date edited (ascending)",
    value: "updated-asc",
  },
  {
    id: 1,
    name: "Date edited (descending)",
    value: "updated-desc",
  },
  {
    id: 2,
    name: "Name (ascending)",
    value: "field-domain_name-asc",
  },
  {
    id: 3,
    name: "Name (descending)",
    value: "field-domain_name-desc",
  },
];

const sortingMediaItems = [
  {
    id: 0,
    name: "Date edited (ascending)",
    value: "updated-asc",
  },
  {
    id: 1,
    name: "Date edited (descending)",
    value: "updated-desc",
  },
  {
    id: 2,
    name: "Name (ascending)",
    value: "field-original_filename-asc",
  },
  {
    id: 3,
    name: "Name (descending)",
    value: "field-original_filename-desc",
  },
];

const tabsCampaigns = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Notification Campaigns",
  },
  {
    id: 2,
    name: "Datei Campaigns",
  },
  {
    id: 3,
    name: "Drafts",
  },
  {
    id: 4,
    name: "Sent",
  },
  {
    id: 5,
    name: "Archived",
  },
];

const tabsReports = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Archive",
  },
];

const tabsServers = [
  {
    id: 0,
    name: "All",
  },

  {
    id: 1,
    name: "Owned",
  },

  {
    id: 2,
    name: "Not Owned",
  },
];
const tabsTargetGroups = [
  {
    id: 0,
    name: "Alle",
  },
  {
    id: 1,
    name: "<100",
    targetCountMax: 100,
  },
  {
    id: 2,
    name: "100-1000",
    targetCountMin: 100,
    targetCountMax: 1000,
  },
  {
    id: 3,
    name: ">1000",
    targetCountMin: 1000,
  },
];

const tabsTemplates = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Notification campaign templates",
  },
  {
    id: 2,
    name: "File campaign templates",
  },
];

export {
  sortingDefault,
  paginationDefault,
  tabsDomains,
  sortingUsers,
  sortingTenants,
  sortingDomains,
  sortingMediaItems,
  tabsCampaigns,
  tabsReports,
  tabsServers,
  tabsTargetGroups,
  tabsTemplates,
};
