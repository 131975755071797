import i18next from "i18next";
import Button from "../UI/Button";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";

interface PropsType {
  currentCampaignID: string | undefined;
  enabledChannels: {
    email: boolean | undefined;
    sms: boolean | undefined;
    whatsapp: boolean | undefined;
  };
}

const TestCampaign = ({ currentCampaignID, enabledChannels }: PropsType) => {
  const [testEmail, setTestEmail] = useState("");
  const [testPhone, setTestPhone] = useState("");
  const [channels, setChannels] = useState<number[]>([0, 1, 2]);
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const onEmailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestEmail(event?.target.value);
  };
  const onSmsChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestPhone(event?.target.value);
  };
  const onChanelChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const id = event.target.id;

    if (id === "emailChannel")
      if (channels.includes(0)) {
        setChannels((prev) => prev.filter((element) => element !== 0));
      } else {
        setChannels((prev) => [...prev, 0]);
      }

    if (id === "smsChannel")
      if (channels.includes(1)) {
        setChannels((prev) => prev.filter((element) => element !== 1));
      } else {
        setChannels((prev) => [...prev, 1]);
      }
    if (id === "whatsappChannel")
      if (channels.includes(2)) {
        setChannels((prev) => prev.filter((element) => element !== 2));
      } else {
        setChannels((prev) => [...prev, 2]);
      }
  };

  const testSendingHandler = () => {
    if (!currentCampaignID || !currentTenant?.id) return;

    const url =
      "/tenants/" +
      currentTenant.id +
      "/campaigns/" +
      currentCampaignID +
      "/send-test";
    const data = {
      test_email: testEmail,
      test_phone: testPhone,
      channels: channels,
    };
    axios
      .post(url, data)
      .then((response) => {
        toast(i18next.t("Test sent"));
      })
      .catch(() => {
        toast(i18next.t("Test sending error"));
      });
  };
  return (
    <>
      {enabledChannels.email ||
      enabledChannels.sms ||
      enabledChannels.whatsapp ? (
        <div
          className={`bg-white
            max-w-[25%] min-w-[300px] text-sm
            ml-5 mt-4 px-8 py-4 rounded-md shadow-sm`}
        >
          {enabledChannels.email ? (
            <div className="basis-full justify-start mb-4">
              <div>
                <label htmlFor="test_email" className="font-bold">
                  {i18next.t("Email for test")}
                </label>
              </div>
              <div>
                <input
                  type="text"
                  className="border-solid border-0 border-b border-primaryGrey
                    w-full outline-none focus:border-primaryDarkGrey"
                  placeholder="email@test.de"
                  name="test_email"
                  id="test_email"
                  onChange={onEmailChangeHandler}
                  value={testEmail}
                />
              </div>
            </div>
          ) : null}

          {enabledChannels.sms || enabledChannels.whatsapp ? (
            <div className="basis-full justify-start mb-4">
              <div>
                <label htmlFor="test_sms" className="font-bold">
                  {i18next.t("Mobile number for test")}
                </label>
              </div>
              <div>
                <input
                  type="text"
                  className="border-solid border-0 border-b border-primaryGrey
                    w-full outline-none focus:border-primaryDarkGrey"
                  placeholder="+49 123 456 78900"
                  name="test_sms"
                  id="test_sms"
                  onChange={onSmsChangeHandler}
                  value={testPhone}
                />
              </div>
            </div>
          ) : null}

          <div className="basis-full justify-start mb-4">
            <div className="font-bold">{i18next.t("Channel")}</div>
            <div className="flex gap-8">
              {enabledChannels.email ? (
                <label
                  htmlFor="emailChannel"
                  className="flex items-center gap-2 cursor-pointer"
                  title={i18next.t("E-Mail")}
                >
                  <input
                    type="checkbox"
                    className="testingChannel hidden"
                    id="emailChannel"
                    onChange={onChanelChangeHandler}
                    checked={channels.includes(0) ? true : false}
                  />
                  <span className="checkbox h-4 w-4 inline-block border rounded-sm"></span>
                  <i className="fa fa-envelope-o"></i>
                </label>
              ) : null}
              {enabledChannels.sms ? (
                <label
                  htmlFor="smsChannel"
                  className="flex items-center gap-2 cursor-pointer"
                  title={i18next.t("SMS")}
                >
                  <input
                    type="checkbox"
                    className="testingChannel hidden"
                    id="smsChannel"
                    onChange={onChanelChangeHandler}
                    checked={channels.includes(1) ? true : false}
                  />
                  <span className="checkbox h-4 w-4 inline-block border rounded-sm"></span>
                  <i className="fa fa-mobile text-xl"></i>
                </label>
              ) : null}
              {enabledChannels.whatsapp ? (
                <label
                  htmlFor="whatsappChannel"
                  className="flex items-center gap-2 cursor-pointer"
                  title={i18next.t("Whatsapp")}
                >
                  <input
                    type="checkbox"
                    className="testingChannel hidden"
                    id="whatsappChannel"
                    onChange={onChanelChangeHandler}
                    checked={channels.includes(2) ? true : false}
                  />
                  <span className="checkbox h-4 w-4 inline-block border rounded-sm"></span>
                  <i className="fa fa-whatsapp"></i>
                </label>
              ) : null}
            </div>
          </div>
          <Button
            className="bg-white w-full border mx-auto border-primaryDarkGrey text-primaryDarkGrey"
            onClick={testSendingHandler}
          >
            {i18next.t("Test send")}
          </Button>
        </div>
      ) : null}
    </>
  );
};
export default TestCampaign;
