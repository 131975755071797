import i18next from "i18next";
import React, { Dispatch, useEffect } from "react";
import { SetStateAction } from "react";
import Campaign from "../../types/models/Campaign";

interface propsType {
  currentStep: number;
  campaignInfo: Campaign;
  setCurrentStepHandler: Dispatch<SetStateAction<number>>;
}

const Navigation = ({
  currentStep,
  campaignInfo,
  setCurrentStepHandler,
}: propsType) => {
  const steps = [
    {
      step: 1,
      name: i18next.t("Common"),
      status: campaignInfo.name ? true : false,
    },
    {
      step: 2,
      name: i18next.t("Target Groups"),
      status: campaignInfo.target_group?.id ? true : false,
    },
    {
      step: 3,
      name: i18next.t("Design"),
      status: campaignInfo.template?.id ? true : false,
    },
    { step: 4, name: i18next.t("Send"), status: true },
  ];

  // when loading the page, set the first empty step as the current one
  useEffect(() => {
    for (var i = 0; i < steps.length; i++) {
      if (!steps[i].status) {
        setCurrentStepHandler(steps[i].step);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <button
            key={index}
            onClick={() => setCurrentStepHandler(index + 1)}
            className="group grow flex flex-col justify-center items-center
              cursor-pointer border-0 bg-transparent"
          >
            <div
              className={`rounded-full
                text-3xl font-bold w-12 h-12
                justify-center flex items-center
                border-solid
                ${
                  // styles for current / !current step
                  currentStep === index + 1
                    ? "bg-white drop-shadow-md text-primaryBlue border-2 border-primaryBlue dark:bg-darkModeBlue dark:text-primaryLightGrey dark:border-primaryLightGrey"
                    : "bg-primaryGrey group-hover:drop-shadow-md"
                }
                ${
                  // styles for steps preceding the current one
                  // check for filled or not filled
                  currentStep > index + 1 && steps[index].status
                    ? "bg-white dark:bg-darkModeLighterBlue text-primaryGreen border-2 border-primaryGreen"
                    : currentStep > index + 1 && !steps[index].status
                    ? "bg-white dark:bg-darkModeLighterBlue text-primaryRed border-2 border-primaryRed"
                    : ""
                }
                ${
                  // style for the next steps after the current one
                  currentStep < index + 1
                    ? " text-white dark:text-darkModeBlue"
                    : ""
                }
              `}
            >
              {currentStep > index + 1 && steps[index].status ? (
                <i className="fa fa-check" aria-hidden="true"></i>
              ) : currentStep > index + 1 && !steps[index].status ? (
                <i className="fa fa-exclamation" aria-hidden="true"></i>
              ) : (
                step.step
              )}
            </div>
            <div
              className={`${
                currentStep === index + 1
                  ? "text-primaryBlue dark:text-primaryLightGrey"
                  : "text-primaryGrey dark:text-primaryDarkGrey"
              } text-sm mt-2`}
            >
              {step.name}
            </div>
          </button>
          {index < steps.length - 1 && (
            <div
              className={`${
                currentStep > index + 1
                  ? "border-primaryBlue"
                  : "border-primaryDarkGrey opacity-20"
              } grow-[2] border-0 border-b border-solid mt-[-20px]`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export default Navigation;
