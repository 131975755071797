import i18next from "i18next";
import Campaign from "../../types/models/Campaign";
import Button from "../UI/Button";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-hot-toast";
// import FormErrorResponse from "../../../types/FormErrorResponse";
import { Dispatch, SetStateAction, useEffect } from "react";
import useSave from "../../hooks/use-save";
import ConfirmationModal from "../UI/ConfirmationModal";
import ReducerState from "../../types/ReducerState";
import SpamSuspicion from "./SpamSuspicion";
import FormErrorResponse from "../../types/FormErrorResponse";
import { getEnumValue } from "../../utils/Enum";

interface propsType {
  summary: Campaign;
  isCreate: boolean;
  setCampaign: Dispatch<SetStateAction<Campaign>>;
  setErrors: (errors: FormErrorResponse) => void;
  isNotificationCampaign?: boolean;
}

const Summary = ({
  summary,
  isCreate,
  setCampaign,
  setErrors,
  isNotificationCampaign = true,
}: propsType) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );
  const currentTenantID = currentTenant?.id ? currentTenant.id : null;

  const [savedCampaign, error, sendSaveRequest] = useSave(
    id ? "/campaigns/" + id : "/campaigns/",
    summary,
    isCreate
  );

  const currCampaignStatus = getEnumValue(
    "CampaignStatus",
    summary?.status !== undefined ? summary.status : ""
  );
  const isEdited = currCampaignStatus === "draft" ? true : false;

  const remove = () => {
    if (!currentTenantID || !summary.id) return;

    axios
      .delete("/tenants/" + currentTenantID + "/campaigns/" + summary.id)
      .then((response) => {
        toast(i18next.t("Record deleted successfully."));
        navigate("/campaigns");
      })
      .catch(() => {
        toast(i18next.t("Error deleting record."));
      });
  };

  const startCampaign = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!summary.id || !currentTenantID) return;

    const url = "/tenants/" + currentTenantID + "/campaigns/" + summary.id;
    const data = {
      campaign_action: "start",
    };
    axios
      .patch(url, data)
      .then((response) => {
        setCampaign(response.data);
      })
      .catch(() => {
        toast(i18next.t("Campaign has not started"));
      });
  };

  const pauseCampaign = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!summary.id || !currentTenantID) return;

    const url = "/tenants/" + currentTenantID + "/campaigns/" + summary.id;
    const data = {
      campaign_action: "pause",
    };
    axios
      .patch(url, data)
      .then((response) => {
        setCampaign(response.data);
      })
      .catch(() => {
        toast(i18next.t("Campaign is not paused"));
      });
  };

  const abortCampaign = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!summary.id || !currentTenantID) return;

    const url = "/tenants/" + currentTenantID + "/campaigns/" + summary.id;
    const data = {
      campaign_action: "abort",
    };
    axios
      .patch(url, data)
      .then((response) => {
        setCampaign(response.data);
      })
      .catch(() => {
        toast(i18next.t("Campaign has not stopped"));
      });
  };

  const archiveCampaign = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!summary.id || !currentTenantID) return;

    const url = "/tenants/" + currentTenantID + "/campaigns/" + summary.id;
    const data = {
      is_archived: true,
    };
    axios
      .patch(url, data)
      .then((response) => {
        setCampaign(response.data);
      })
      .catch(() => {
        toast(i18next.t("Campaign is not archived"));
      });
  };

  useEffect(() => {
    if (savedCampaign) setCampaign(savedCampaign);
    if (error) setErrors(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCampaign, error]);

  return (
    <>
      <ConfirmationModal
        show={showDeletionConfirmationModal}
        closeCallback={() => {
          setShowDeletionConfirmationModal(false);
        }}
        confirmCallback={() => {
          remove();
        }}
        title={i18next.t("Remove record")}
        text={i18next.t("This action cannot be undone.")}
        confirmButtonText={i18next.t("Remove")}
        confirmButtonVariant="danger"
      />
      <div className="flex flex-col">
        <div
          className="mt-10 max-w-[25%] min-w-[300px] text-sm ml-5
            text-primaryDarkGrey"
        >
          <h3
            className="
              bg-primaryBlue text-white dark:bg-darkModeBlue
              m-0 px-8 py-4 rounded-t-md
              text-lg"
          >
            {i18next.t("Campaign")}
          </h3>
          <div
            className="
              bg-white dark:bg-darkModeLighterBlue dark:text-primaryLightGrey
              px-8 py-4 rounded-b-md shadow-sm
              [&>h4]:text-base [&>h4]:text-black [&>h4]:m-0
              dark:[&>h4]:text-primaryLightGrey"
          >
            <h4>
              {i18next.t("Title")}
              {summary.name && isEdited ? (
                <i
                  className="fa fa-check px-2 text-primaryGreen"
                  aria-hidden="true"
                ></i>
              ) : null}
            </h4>
            <p>{summary.name ? summary.name : "/"}</p>
            <h4>
              {i18next.t("Description")}
              {summary.description && isEdited ? (
                <i
                  className="fa fa-check px-2 text-primaryGreen"
                  aria-hidden="true"
                ></i>
              ) : null}
            </h4>
            <p>{summary.description ? summary.description : "/"}</p>
            {isNotificationCampaign ? (
              <>
                <h4>
                  {i18next.t("Target Group")}
                  {summary.target_group_id && isEdited ? (
                    <i
                      className="fa fa-check px-2 text-primaryGreen"
                      aria-hidden="true"
                    ></i>
                  ) : null}
                </h4>
                <p>
                  {summary.target_group?.name ? summary.target_group.name : "/"}
                </p>
              </>
            ) : null}

            <h4>
              {i18next.t("Template")}
              {summary.template_id && isEdited ? (
                <i
                  className="fa fa-check px-2 text-primaryGreen"
                  aria-hidden="true"
                ></i>
              ) : null}
            </h4>
            <p>{summary.template?.name ? summary.template?.name : "/"}</p>

            {isNotificationCampaign ? (
              <>
                {summary.target_channel_email_enabled && isEdited ? (
                  <div className="text-black dark:text-primaryLightGrey font-bold">
                    <i className="fa fa-envelope-o pr-2" aria-hidden="true"></i>
                    {i18next.t("E-Mail")}
                    {summary.sender_name && summary.sender_email_prefix && (
                      <i
                        className="fa fa-check px-2 text-primaryGreen"
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                ) : null}
                {summary.target_channel_sms_enabled && isEdited ? (
                  <div className="text-black dark:text-primaryLightGrey font-bold">
                    <i
                      className="fa fa-mobile pr-4 text-xl"
                      aria-hidden="true"
                    ></i>
                    {i18next.t("SMS")}
                    {summary.sms_sender_name && (
                      <i
                        className="fa fa-check px-2 text-primaryGreen"
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                ) : null}
                {summary.target_channel_whatsapp_enabled && isEdited ? (
                  <div className="text-black dark:text-primaryLightGrey font-bold mb-4">
                    <i
                      className="fa fa-whatsapp pr-2 text-lg"
                      aria-hidden="true"
                    ></i>
                    {i18next.t("Whatsapp")}
                    {summary.sms_sender_name && (
                      <i
                        className="fa fa-check px-2 text-primaryGreen"
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                ) : null}
                {id && isEdited && (
                  <div className="border-t border-primaryGrey py-4">
                    <div className="text-black dark:text-primaryLightGrey font-bold">
                      {i18next.t("Status")}
                    </div>
                    {summary.can_be_started ? (
                      <div className="text-secondaryGreen">
                        {i18next.t("Ready to send")}
                      </div>
                    ) : (
                      <div className="text-primaryRed">
                        {i18next.t("Not ready to send")}
                        <div className="text-primaryOrange">
                          {!summary.name && i18next.t("Enter campaign name")}
                        </div>
                        <div className="text-primaryOrange">
                          {!summary.target_group_id &&
                            !summary.template_id &&
                            i18next.t("Choose target group + Design")}
                          {!summary.target_group_id &&
                            summary.template_id &&
                            i18next.t("Choose target group")}
                          {!summary.template_id &&
                            summary.target_group_id &&
                            i18next.t("Choose template")}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                <h4 className="pb-4">
                  {i18next.t("Settings")}
                  {summary.phishing_domain_id &&
                  summary.template?.attachments?.length &&
                  isEdited ? (
                    <i
                      className="fa fa-check px-2 text-primaryGreen"
                      aria-hidden="true"
                    ></i>
                  ) : null}
                </h4>
              </>
            )}

            {/* show save & send button if campaign has status "draft" */}

            {summary.name && (currCampaignStatus === "draft" || isCreate) ? (
              <Button className="w-full mb-2" onClick={sendSaveRequest}>
                {i18next.t("Save")}
              </Button>
            ) : null}
            {id && summary.can_be_started && currCampaignStatus === "draft" && (
              <Button className="w-full mt-2" onClick={startCampaign}>
                {i18next.t("Start")}
              </Button>
            )}
            {/* show pause & stop buttons if campaign has status "sending" or "scheduled" */}
            {currCampaignStatus === "scheduled" ||
            currCampaignStatus === "sending" ? (
              <div className="flex flex-wrap gap-4 w-full justify-around">
                <Button
                  className="flex-1 bg-primaryOrange"
                  onClick={pauseCampaign}
                >
                  <i className="fa fa-pause" aria-hidden="true"></i>{" "}
                  {/* {i18next.t("Pause")} */}
                </Button>
                <Button
                  className="flex-1 bg-primaryRed"
                  onClick={abortCampaign}
                >
                  <i className="fa fa-stop" aria-hidden="true"></i>{" "}
                  {/* {i18next.t("Stop")} */}
                </Button>
              </div>
            ) : null}
            {/* show report & stop buttons if campaign has status "awaitingVictimInteraction" */}
            {currCampaignStatus === "awaitingVictimInteraction" ? (
              <div className="flex flex-wrap gap-4 w-full justify-around">
                <Button
                  className="w-full flex-1"
                  onClick={() => navigate("/reports/" + summary.id)}
                >
                  {i18next.t("Report")}
                </Button>
                <Button
                  className="flex-1 bg-primaryRed"
                  onClick={abortCampaign}
                >
                  <i className="fa fa-stop" aria-hidden="true"></i>{" "}
                  {i18next.t("Stop")}
                </Button>
              </div>
            ) : null}
            {/* show start & stop buttons if campaign has status "paused" */}
            {currCampaignStatus === "paused" ? (
              <div className="flex flex-wrap gap-4 w-full justify-around">
                {id && summary.can_be_started ? (
                  <Button className="w-full mt-2" onClick={startCampaign}>
                    {i18next.t("Start")}
                  </Button>
                ) : null}
                <Button
                  className="flex-1 bg-primaryRed"
                  onClick={abortCampaign}
                >
                  <i className="fa fa-stop" aria-hidden="true"></i>{" "}
                  {i18next.t("Stop")}
                </Button>
              </div>
            ) : null}
            {/* show archived button */}
            {currCampaignStatus !== "draft" &&
            !summary.is_archived &&
            !isCreate ? (
              <div className="flex flex-wrap mt-2 gap-4 w-full justify-around">
                <Button className="w-full flex-1" onClick={archiveCampaign}>
                  {i18next.t("archive")}
                </Button>
              </div>
            ) : null}
            {/* show report button if campaign has status "aborted" */}
            {currCampaignStatus === "aborted" ? (
              <div className="flex flex-wrap mt-2 gap-4 w-full justify-around">
                <Button
                  className="w-full flex-1"
                  onClick={() => navigate("/reports/" + summary.id)}
                >
                  {i18next.t("Report")}
                </Button>
              </div>
            ) : null}

            {id && (
              <Button
                className="m-auto block border-0 bg-transparent text-primaryDarkGrey shadow-none"
                onClick={() => {
                  setShowDeletionConfirmationModal(true);
                }}
              >
                <i className="fa fa-trash-o pr-2" aria-hidden="true"></i>
                {i18next.t("Remove")}
              </Button>
            )}
          </div>
        </div>

        {id && isNotificationCampaign && (
          <SpamSuspicion
            blackLists={summary.blacklists}
            spamScore={summary.spam_score}
            refreshFunction={setCampaign}
            sender={`
            ${summary.creator?.first_name ? summary.creator?.first_name : "Max"}
            ${
              summary.creator?.last_name
                ? summary.creator?.last_name
                : "Mustermann"
            }`}
          />
        )}
      </div>
    </>
  );
};
export default Summary;
