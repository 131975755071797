import React from "react";
import i18next from "i18next";
import SidebarMenuItem from "./SidebarMenuItem";

import MenuItem from "../../../types/models/MenuItem";
import GenericCallback from "../../../types/GenericCallback";
import { useSelector } from "react-redux";
import ReducerState from "../../../types/ReducerState";
import UserCapability from "../../../types/models/UserCapability";

const SidebarMenu: React.FC<{
  closeHandler: GenericCallback;
}> = ({ closeHandler }) => {
  const userCapabilities = useSelector(
    (state: ReducerState) => state.currentCapabilities
  );
  const checkCapabilities = (capabilities: UserCapability[]) => {
    return capabilities.some((capability) => capability.capability_type === 0);
  };

  const menuItems = [
    // new MenuItem(i18next.t("Dashboard"), "/", "fa fa-home"),
    new MenuItem(i18next.t("Campaigns"), "/campaigns", "fa fa-bullseye", [
      new MenuItem(i18next.t("List"), "/campaigns"),
      new MenuItem(
        i18next.t("New campaign"),
        "/campaigns/create",
        ""
        // [
        //   new MenuItem(i18next.t("Common"), "/step1"),
        //   new MenuItem(i18next.t("Target Groups"), "/step2"),
        //   new MenuItem(i18next.t("Design"), "/step3"),
        //   new MenuItem(i18next.t("Send"), "/step4"),
        // ]
      ),
    ]),
    new MenuItem(i18next.t("Templates"), "/templates", "fa fa-file-text-o", [
      new MenuItem(i18next.t("List"), "/templates"),
      new MenuItem(i18next.t("Add new template"), "/templates/create"),
    ]),
    new MenuItem(i18next.t("Target Groups"), "/target-groups", "fa fa-users", [
      new MenuItem(i18next.t("List"), "/target-groups"),
      new MenuItem(i18next.t("Add new template"), "/target-groups/create"),
    ]),
    new MenuItem(i18next.t("Domains"), "/domains", "fa fa-at", [
      new MenuItem(i18next.t("List"), "/domains"),
      new MenuItem(i18next.t("Add new domain"), "/domains/create"),
    ]),
    new MenuItem(i18next.t("Servers"), "/servers", "fa fa-server", [
      new MenuItem(i18next.t("List"), "/servers"),
      new MenuItem(i18next.t("Add new server"), "/servers/create"),
    ]),
    new MenuItem(i18next.t("Reports"), "/reports", "fa fa-bar-chart"),
  ];

  let availableMenuItems = [];
  if (checkCapabilities(userCapabilities)) {
    availableMenuItems = [
      ...menuItems,
      new MenuItem(
        i18next.t("Admin Dashboard"),
        "/admin-dashboard",
        "fa fa-cog"
      ),
    ];
  } else {
    availableMenuItems = [...menuItems];
  }
  return (
    <nav>
      <ul className="p-0">
        {availableMenuItems.map((item, index) => (
          <SidebarMenuItem
            key={index}
            item={item}
            closeHandler={closeHandler}
          ></SidebarMenuItem>
        ))}
      </ul>
    </nav>
  );
};

export default SidebarMenu;
