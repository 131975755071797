import { ReactNode } from "react";
import Button from "../Button";
import GenericCallback from "../../../types/GenericCallback";
import i18next from "i18next";
interface propsType {
  className?: string;
  children: ReactNode;
  clickHandler?: GenericCallback;
  showReportHandler?: GenericCallback;
  copyHandler?: GenericCallback;
  deleteHandler?: GenericCallback;
  buttonType?: string; // "edit" or "report" or "info&report"
}
const ListItem = ({
  className,
  children,
  clickHandler,
  copyHandler,
  deleteHandler,
  showReportHandler,
  buttonType = "edit",
}: propsType) => {
  return (
    <>
      <div
        className={`
          flex flex-col lg:flex-row relative justify-between items-center rounded
          mb-6 text-sm bg-white dark:bg-darkModeLighterBlue shadow-sm text-primaryDarkGrey
          ${className ? className : " pb-6"}
        `}
      >
        {/* Campagne|Template|Target Component */}
        {children}
        <div
          className="flex items-center self-stretch mb-3 lg:mb-0 lg:self-auto text-black dark:text-white
            text-base justify-between lg:justify-normal"
        >
          {copyHandler && (
            <div
              className="order-1 text-2xl lg:text-base"
              onClick={copyHandler}
            >
              <i
                className="fa fa-files-o p-2 cursor-pointer ease-out duration-300
                  hover:scale-110"
                aria-hidden="true"
                title={i18next.t("Clone")}
              ></i>
            </div>
          )}
          {deleteHandler && (
            <div
              className="mr-6 order-2 md:order-1 text-2xl lg:text-base"
              onClick={deleteHandler}
            >
              <i
                className="fa fa-trash-o p-2 cursor-pointer ease-out duration-300
                  hover:scale-110"
                aria-hidden="true"
                title={i18next.t("Remove")}
              ></i>
            </div>
          )}
          {clickHandler && buttonType === "edit" ? (
            <Button
              className={`min-w-max order-1 text-xl lg:text-base`}
              onClick={clickHandler}
            >
              <i className="fa fa-pencil pr-2" aria-hidden="true"></i>{" "}
              {i18next.t("Edit")}
            </Button>
          ) : (
            <div className="order-1 flex flex-col">
              {buttonType === "info" ? (
                <Button
                  className={`${"bg-primaryLightBlue"} w-full mb-2 text-xl lg:text-base`}
                  onClick={clickHandler}
                >
                  <i className="fa fa-info-circle pr-2" aria-hidden="true"></i>{" "}
                  {i18next.t("View Info")}
                </Button>
              ) : null}

              {buttonType === "report&info" ? (
                <>
                  <Button
                    className={`${"bg-primaryLightBlue"} mb-2 text-xl lg:text-base`}
                    onClick={clickHandler}
                  >
                    <i
                      className="fa fa-info-circle pr-2"
                      aria-hidden="true"
                    ></i>{" "}
                    {i18next.t("View Info")}
                  </Button>
                  <Button
                    className={`${"bg-primaryLightBlue"} w-full text-xl lg:text-base`}
                    onClick={showReportHandler}
                  >
                    <i className="fa fa-bar-chart pr-2" aria-hidden="true"></i>
                    {i18next.t("Report")}
                  </Button>
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ListItem;
