import i18next from "i18next";
import FormErrorResponse from "../../types/FormErrorResponse";
import Campaign from "../../types/models/Campaign";
import File from "./File";
import { useEffect, useState } from "react";
import Domain from "../../types/models/Domain";
import { useSelector } from "react-redux";
import ReducerState from "../../types/ReducerState";
import axios from "axios";
import { toast } from "react-hot-toast";

interface propsType {
  isCreate: boolean;
  currentState?: Campaign;
  errors: FormErrorResponse | null;
  onInputChangeHandler(field: string, value: string | boolean): void;
}
const StepSettingsForFileCampaign = ({
  isCreate,
  currentState,
  errors,
  onInputChangeHandler,
}: propsType) => {
  const [fishingDomains, setFishingDomains] = useState<Domain[]>();
  const currentTenant = useSelector(
    (state: ReducerState) => state.currentTenant
  );

  const fetchFishingDomains = () => {
    if (!currentTenant?.id) return;

    var url = "/tenants/" + currentTenant?.id + "/domains/?supports-phishing=1";
    axios
      .get<{
        data: Domain[];
      }>(url)
      .then((response) => {
        setFishingDomains(response.data.data);
      })
      .catch(() => {
        toast(i18next.t("Error"));
      });
  };
  const onPhishingDomainSelectHandler = (e: any) => {
    onInputChangeHandler(e.target.id, e.target.value);
  };
  useEffect(() => {
    fetchFishingDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        className="bg-white dark:bg-darkModeLighterBlue
        text-black dark:text-primaryLightGrey rounded-md overflow-hidden"
      >
        <div
          className="bg-primaryBlue dark:bg-darkModeBlue py-4 px-8 text-white
          text-sm"
        >
          <h1 className="text-lg m-0">{i18next.t("Settings")}</h1>
        </div>
        <div
          className={`flex ml-auto text-right text-primaryDarkGrey p-6 mb-2`}
        >
          <label
            htmlFor="phishing_domain_id"
            className="text-black dark:text-primaryLightGrey"
          >
            {i18next.t("Phishing domain")}
            <sup>*</sup>
          </label>
          <select
            name="phishing_domain_id"
            id="phishing_domain_id"
            onChange={onPhishingDomainSelectHandler}
            value={
              currentState?.phishing_domain_id
                ? currentState.phishing_domain_id
                : "null"
            }
            className="custom-select text-right outline-none dark:bg-darkModeLighterBlue
              dark:text-primaryLightGrey"
          >
            <option disabled value="null">
              -- {i18next.t("select")} --
            </option>
            {fishingDomains?.length &&
              fishingDomains.map((domain, index) => {
                return (
                  <option
                    key={domain.id ? domain.id.concat("_phishing") : index}
                    value={domain.id}
                  >
                    {domain.domain_name}
                  </option>
                );
              })}
          </select>
        </div>

        {currentState?.template?.attachments &&
          currentState?.template?.attachments.map((item) => (
            <File key={item.id} attachment={item} />
          ))}
      </div>
    </>
  );
};
export default StepSettingsForFileCampaign;
