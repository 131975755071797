import LocalStorageState from "../types/LocalStorageState";

export const loadStateFromLocalStorage = (): LocalStorageState | undefined => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveStateToLocalStorage = (state: LocalStorageState): boolean => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    return false;
  }

  return true;
};
